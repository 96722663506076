import { Button } from './Button'
import { useEffect, useState } from 'react'
import { useTranslation } from 'i18nano'
import { useUserStore } from '../../stores/userStore'
import server from '../../logic/server'
import { TopButtons } from './Leaderboard/TopButtons'
import { sTelegramUrl, storageUrl } from '../../logic/game/music'
import { DEFAULT_USER_REWARD, FREN_WHO_JOINED_POOL_REWARD } from '../../logic/game/referrals'
import { LeaderboardCard } from './Leaderboard/LeaderboardCard'
import { getUserBoost } from '../../logic/game/users'
import { getWebAppPoolLink, shareInviteLink } from '../../logic/utils/telegram'
import { toastError, toastSuccess } from '../../logic/toast'

export function InviteFriends() {
  const user = useUserStore((state) => state.user)
  const friends = useUserStore((state) => state.friends)
  let [isLoading, setIsLoading] = useState(false)
  let [totalInvestedFriends, setTotalInvestedFriends] = useState(0)
  const t = useTranslation()
  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true)
      server.users.getFriends().then(() => {
        setIsLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (!user) return

    const total =
      parseInt(user.referral_default || '0') +
      parseInt(user.referral_premium || '0') +
      parseInt(user.new_referral_default || '0') +
      parseInt(user.new_referral_premium || '0')

    setTotalInvestedFriends(total)
  }, [user])

  return (
    <>
      <TopButtons />
      <img src={`${storageUrl}/img/friends-1.png`} className='w-20 h-20 object-contain rounded-2xl' />
      <h1 className='text-grey-800 text-2xl font-semibold mt-1 mb-1'>
        {t('invite-frens-title')} {`{?}`}
      </h1>
      <h2 className='text-white text-lg font-semibold mb-3'>{t('you-and-your-fren')}</h2>
      <InviteFriendCard
        title={t('just-fren-1')}
        dropsAmount={DEFAULT_USER_REWARD}
        img={`${storageUrl}/img/friends-2.png`}
      />
      <InviteFriendCard
        title={t('fren-who-joined')}
        dropsAmount={FREN_WHO_JOINED_POOL_REWARD}
        img={`${storageUrl}/img/friends-3.png`}
      />
      <div className='flex flex-col w-full p-3 rounded-2xl bg-purple-bg bg-opacity-10 items-center mt-2 gap-2'>
        <p className='text-white text-md font-semibold w-full'>
          {t('invited-frens')} {isLoading ? '' : `(${totalInvestedFriends || 0})`}
        </p>
        {isLoading && <span className='loader' />}
        {friends && friends.length === 0 ? <p className='text-white font-semibold text-md'>No frens yet</p> : null}
        {friends?.map((friend, index) => (
          <LeaderboardCard
            key={index}
            title={friend.name}
            type='mined_count'
            img={`${sTelegramUrl}/users/${friend.id}.jpg`}
            value={friend.mined_count}
            boostLvl={getUserBoost(friend.invested).id + 1}
            place={-1}
            noFuture={true}
          />
        ))}
      </div>
      <div
        className='w-full shrink-0'
        style={{
          height: '100px',
        }}
      ></div>
    </>
  )
}

function InviteFriendCard({ title, dropsAmount, img }: { title: string; dropsAmount: number; img: string }) {
  const t = useTranslation()

  return (
    <div
      className='flex flex-row w-full h-16 rounded-2xl px-4 py-0  items-center shadow-lg mb-2.5 gap-2 shrink-0'
      style={{
        background: 'linear-gradient(360deg, #FFFFFF00 0%, #FFFFFF4D 100%), #FFFFFF66',
      }}
    >
      <img src={img} alt='box-icon' className='w-8 h-8 object-contain' />
      <div className='flex flex-col w-full h-full justify-center'>
        <p className='font-semibold text-md'>{title}</p>
        <div className='flex flex-row items-center'>
          <p className='text-grey-800 font-semibold text-md'>{dropsAmount}</p>
          <img
            src={`/img/drop-icon.png`}
            alt='box-icon'
            className='w-4 h-4 object-contain'
            style={{
              imageRendering: 'pixelated',
            }}
          />
          <p className='text-[#74728C] font-medium text-sm ml-1'> {t('for-you-and-your-fren')}</p>
        </div>
      </div>
    </div>
  )
}

export function InviteFriendsBottomInfo() {
  const user = useUserStore((state) => state.user)
  const t = useTranslation()

  return (
    <div
      className='absolute bottom-0 left-1/2 w-full h-max bg-[#8787AC] flex flex-col rounded-t-3xl p-3 max-w-sm transform -translate-x-1/2 pb-6 pt-4'
      style={{
        zIndex: 21,
      }}
    >
      <div className='flex flex-row w-full gap-2'>
        <Button
          text={t('invite-frens')}
          onClick={() => {
            shareInviteLink(user!.id, user?.locale || "en")
          }}
        />
        <button
          className='h-12 w-14 bg-grey-800 flex justify-center items-center rounded-xl'
          onClick={() => {
            const link = getWebAppPoolLink(user!.id, 0)
            navigator.clipboard
              .writeText(link)
              .then(() => {
                toastSuccess('Link copied')
              })
              .catch(() => {
                toastError('Failed to copy link')
              })
          }}
        >
          <img src='/img/content_copy.svg' className='h-5 w-5 object-contain' />
        </button>
      </div>
    </div>
  )
}
