import { useTranslation } from "i18nano";
import { useEffect, useState } from "react";
import { useUserStore } from "../../stores/userStore";
import { usePageStore } from "../../stores/pageStore";

export function TopPanel() {
  const user = useUserStore((state) => state.user);
  const setPage = usePageStore((state) => state.setPage);
  const ping = useUserStore((state) => state.ping);
  const t = useTranslation();

  const [time, setTime] = useState("");

  const [fontSize, setFontSize] = useState(16);
  useEffect(() => {
    const func = () => {
      const now = new Date();
      let minutes = 59 - now.getMinutes();
      let seconds = 59 - now.getSeconds();

      setTime(
        `${minutes < 10 ? "0" : ""}${minutes}:${
          seconds < 10 ? "0" : ""
        }${seconds}`
      );
    };

    const interval = setInterval(() => {
      func();
    }, 1000);

    func();

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!user) return;

    let dropsLength = user.drops_amount.length;

    if (dropsLength < 5) {
      setFontSize(16);
      return;
    }
    if (dropsLength < 6) {
      setFontSize(14);
      return;
    }

    setFontSize(12);
  }, [user]);

  return (
    <>
      <div className="absolute w-full min-w-36 max-w-64 top-0.5 rounded-full flex justify-center flex right-2 z-10">
        {ping && (
          <p className="text-white w-full font-medium text-xs text-end opacity-10">
            {ping}ms
          </p>
        )}
      </div>
      <div className="absolute w-full top-2 left-1/2 transform -translate-x-1/2 flex items-start justify-center z-10 gap-2">
        <button
          className="flex max-w-64 w-32 h-8 bg-white bg-opacity-80 rounded-3xl items-center justify-center px-3 gap-1 relative"
          style={{
            boxShadow:
              "inset 0 0 10px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)",
          }}
          onClick={() => setPage("daily-claim")}
        >
          <img
            src="/img/gift.svg"
            alt="right-arrow"
            className="w-5 h-5 object-contain"
            style={{ marginLeft: -2 }}
          />
          <p className="text-grey-600 font-semibold text-md">{t("claim-2")}</p>
          {/* <div className='bg-black bg-opacity-10 rounded-full px-2 py-1 text-xs text-white font-black'>
            {user.t_amount}/2
          </div> */}
        </button>
        <div
          className={`flex flex-col h-16 rounded-2xl`}
          style={{
            backgroundColor: user?.pool_id
              ? "rgba(255, 255, 255, 0.3)"
              : "rgba(255, 255, 255, 0)",
          }}
        >
          <div
            className="flex max-w-64 min-w-24 h-8 bg-white bg-opacity-25 rounded-3xl items-center justify-center px-3 gap-2 relative"
            style={{
              boxShadow:
                "inset 0 0 10px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)",
            }}
          >
            <img
              src="/img/box-icon.png"
              alt="box-icon"
              className="w-5 h-5 object-fit"
            />
            <p className="text-white font-bold text-md">{user?.boxes_amount}</p>
          </div>
          {user?.pool_id && (
            <div className="w-full h-8 flex justify-center items-center">
              <p className="text-white font-bold text-md opacity-80">{time}</p>
            </div>
          )}
        </div>
        <div className="flex flex-col w-32 gap-2">
          <div
            className="flex h-8 w-full bg-white bg-opacity-80 rounded-3xl items-center justify-between px-2 gap-0"
            style={{
              boxShadow:
                "inset 0 0 10px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => setPage("buy-drops")}
          >
            <img
              src="/img/drop-icon.png"
              alt="box-icon"
              className="w-5 h-5 object-fit"
              style={{
                imageRendering: "pixelated",
              }}
            />
            <p
              className="text-grey-600 font-bold"
              style={{
                fontSize: 16,
              }}
            >
              {user?.drops_amount}
            </p>
            <img
              src="/img/plus-icon2.png"
              alt="box-icon"
              className="w-6 h-6 object-fit cursor-pointer"
              style={{ marginTop: 3, marginRight: -4 }}
            />
          </div>
          <div className="bg-white font-semibold text-grey-600 bg-opacity-20 rounded-3xl w-full h-14 flex justify-center items-center" onClick={() => {
            window.open("https://ton.org/en/open-league", "_blank")
          }}>
            <img
              src="/img/air.png"
              alt="box-icon"
              className="w-6 h-6 object-fit cursor-pointer ml-2"
              // style={{ marginTop: 3, marginRight: -4 }}
            />
            <p className="text-center">Join TOL Airdrop</p>
          </div>
        </div>
      </div>
    </>
  );
}
