import { useContext, useEffect, useRef, useState } from 'react'
import { useIsConnectionRestored, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'
import { useUserStore } from '../../stores/userStore'
import server from '../../logic/server'

export function useBackendAuth() {
  const isConnectionRestored = useIsConnectionRestored()
  const wallet = useTonWallet()
  const [tonConnectUI] = useTonConnectUI()

  const shouldDisconnectWallet = useUserStore((state) => state.shouldDisconnectWallet)
  const setShouldDisconnectWallet = useUserStore((state) => state.setShouldDisconnectWallet)
  const address = useUserStore((state) => state.address)
  const setAddress = useUserStore((state) => state.setAddress)
  const setUser = useUserStore((state) => state.setUser)

  const isFromTelegram = useUserStore((state) => state.isFromTelegram)
  const setPayWallet = useUserStore((state) => state.setPayWallet)

  useEffect(() => {
    if (shouldDisconnectWallet && wallet) {
      if (server.auth.IMPORTANT_JUST_CONNECTED) return
      tonConnectUI.disconnect()
    }
  }, [wallet, shouldDisconnectWallet])

  useEffect(() => {
    if (!isConnectionRestored) {
      return
    }

    if (!wallet) {
      // if was initialized
      if (address) server.auth.walletDisconnected()

      const refreshPayload = async () => {
        tonConnectUI.setConnectRequestParameters({ state: 'loading' })

        const value = await server.auth.generatePayload()
        console.log('value', value)
        if (!value) {
          tonConnectUI.setConnectRequestParameters(null)
        } else {
          tonConnectUI.setConnectRequestParameters({ state: 'ready', value })
        }
      }

      refreshPayload()
      return
    }

    const walletAddress = wallet?.account?.address
    setAddress(walletAddress)
    setPayWallet({ wallet: 'TONCONNECT', address: walletAddress })

    if (wallet?.connectItems?.tonProof && !('error' in wallet?.connectItems?.tonProof)) {
      console.log(wallet.connectItems.tonProof.proof, wallet.account)
      if (!isFromTelegram) {
        server.auth.tonAuth(wallet)
      } else {
        server.auth.mergeWithTG(wallet)
      }
    } else {
      console.log('no proof')
    }
  }, [wallet, isConnectionRestored])
}
