import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { usePageStore } from '../../../stores/pageStore';
import { useUserStore } from '../../../stores/userStore';
import { usePoolsStore } from '../../../stores/poolsStore';
import { shareTelegramPool } from '../../../logic/utils/telegram';
import server from '../../../logic/server';

export function TopButtons(props: { isPool?: boolean; isJoined?: boolean; isOwner?: boolean }) {
  const { isPool, isJoined, isOwner } = props

  const navigate = useNavigate();

  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const setPage = usePageStore((state) => state.setPage)
  const user = useUserStore((state) => state.user)
  const selected = usePoolsStore((state) => state.selectedPool)

  return (
    <>
      <div className='absolute left-4 top-4 flex flex-row gap-3.5'>
        <TopButton
          icon='/img/arrow_back.svg'
          onClick={() => {
            navigate(-1);
          }}
        />
      </div>
      {isPool && (
        <div className='absolute right-4 top-4 flex flex-row gap-3.5'>
          <TopButton
            icon='/img/share.svg'
            onClick={() => {
              shareTelegramPool(user!.id, selected!.id, user?.locale || "en")
            }}
          />
          {isJoined && (
            <TopButton
              icon='/img/logout.svg'
              isLoading={isLoggingOut}
              onClick={() => {
                setIsLoggingOut(true)
                server.pools.leavePool().then(() => {
                  setIsLoggingOut(false)
                })
              }}
            />
          )}
        </div>
      )}
    </>
  )
}

function TopButton(props: { opacity?: number; icon: string; onClick: () => void; isLoading?: boolean }) {
  const { isLoading, opacity } = props
  return (
    <button
      className={`w-10 h-10 flex items-center justify-center bg-white bg-opacity-${opacity || 20} rounded-xl p-2 shadow-md`}
      onClick={props.onClick}
    >
      {isLoading ? <span className='loader' /> : <img src={props.icon} alt='back' className='w-5 h-5 object-contain' />}
    </button>
  )
}
