import { useTranslation } from 'i18nano'
import { useEffect, useState } from 'react'
import { usePageStore } from '../../../../stores/pageStore'
import { Panel } from '../../Panel'
import { Button } from '../../Button'

export function PoolNeedAdmin() {
  const setPage = usePageStore((state) => state.setPage)
  const t = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Panel titleColor='grey-800' title={null} level={30} onClose={() => setPage(undefined)}>
      <img
        src='/textures/texture-question.png'
        alt='texture-A'
        className='w-16 h-16 object-contain rounded-md'
        style={{
          imageRendering: 'pixelated',
        }}
      />
      <p className='text-grey-800 text-lg text-center font-semibold w-full mt-4'>{t('pool-a-d')}</p>
      <p className='text-grey-800 text-md text-center font-medium w-full'>{t('pool-a-1')}</p>
      <p className='text-grey-800 text-md text-center font-medium w-full mb-2'>{t('pool-a-2')}</p>
      <Button
        isLoading={isLoading}
        text={'Got it!'}
        styles='mt-2'
        onClick={() => {
          setPage(undefined)
        }}
      />
    </Panel>
  )
}
