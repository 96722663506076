import { PoolTimestamp } from "../../../../stores/poolsStore";

export function PoolTimestampCard({ timestamp }: { timestamp: PoolTimestamp }) {
  return (
    <div
      className={`flex flex-row w-full justify-between items-center h-14 bg-white bg-opacity-30 rounded-2xl px-3 shrink-0`}
    >
      <div className="flex flex-row items-center gap-2.5">
        <p className="text-grey-800 font-semibold text-lg whitespace-nowrap overflow-hidden overflow-ellipsis">
          {timestamp.mined_questions}
        </p>
        <img
          src="/textures/texture-question.png"
          className="w-5 h-5 object-contain rounded-sm"
          style={{
            imageRendering: "pixelated",
          }}
        />
      </div>

      <p className="text-grey-800 font-semibold text-md whitespace-nowrap overflow-hidden overflow-ellipsis">
        {/* GET FROM UTC TIME*/}
        {new Date(parseInt(timestamp.timestamp)).toLocaleString("ru", {
          timeZone: "UTC",
          hour: "numeric",
          minute: "numeric",
        })}{" "}
        UTC
      </p>
    </div>
  );
}
