let backgroundAudio: HTMLAudioElement
let musicAudio: HTMLAudioElement

let isBackgroundPlaying = false
let isMusicPlaying = false

let totalParts = 112
let currentPart = Math.floor(Math.random() * totalParts)

const storageLink = 'https://cubes.fra1.cdn.digitaloceanspaces.com/music-parts/' // 0.mp3
const noise =
  'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV'

const music = 'https://cubes.fra1.cdn.digitaloceanspaces.com/music/water.mp3'
export const audioStatus = {
  isMuted: wasMuted(),
}

export function wasMuted() {
  if (typeof window === 'undefined') return false

  return localStorage.getItem('audioMuted') ? true : false
}

export function muteAudio() {
  localStorage.setItem('audioMuted', 'true')
  backgroundAudio.pause()
  musicAudio.pause()

  audioStatus.isMuted = true
}

export function unmuteAudio() {
  localStorage.removeItem('audioMuted')
  backgroundAudio.play()
  musicAudio.play()

  audioStatus.isMuted = false
}

export function initializeAudio() {
  if (backgroundAudio) return

  backgroundAudio = new Audio()
  backgroundAudio.autoplay = true
  backgroundAudio.src = noise

  musicAudio = new Audio()
  musicAudio.autoplay = true
  musicAudio.src = noise

  playBackgroundAudio()
  playMusicAudio()
}

export function playBackgroundAudio() {
  if (isBackgroundPlaying) return
  isBackgroundPlaying = true

  backgroundAudio.src = `${storageLink}${currentPart}.mp3`
  backgroundAudio.volume = 0.6
  backgroundAudio.loop = true

  // on end set next part
  backgroundAudio.onended = () => {
    currentPart = (currentPart + 1) % totalParts
    backgroundAudio.src = `${storageLink}${currentPart}.mp3`
    backgroundAudio.play()
  }

  if (localStorage.getItem('audioMuted')) {
    muteAudio()
  }
}

export function playMusicAudio() {
  if (isMusicPlaying) return
  isMusicPlaying = true

  // get current time in minutes
  const date = new Date()
  const musicLength = 420 // minutes
  const currentTime = date.getHours() * 60 + date.getMinutes()

  const startMinute = currentTime % musicLength

  musicAudio.src = music
  musicAudio.volume = 0.6
  musicAudio.loop = true

  // set start time
  musicAudio.currentTime = startMinute * 60

  musicAudio.play()

  if (localStorage.getItem('audioMuted')) {
    muteAudio()
  }
}
