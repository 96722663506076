import { englishQuotes, russianQuotes } from '../../logic/game/quotes'
import { blocksState } from '../canvas/Blocks'
import { useTranslation, useTranslationChange } from 'i18nano'
import { boxShadowDefault } from './utils'
import server from '../../logic/server'

interface Props {
  setPage: (page: string) => void
}

const englishQuote = englishQuotes[Math.floor(Math.random() * englishQuotes.length)]
const russianQuote = russianQuotes[Math.floor(Math.random() * russianQuotes.length)]
export function StartScreen(props: Props) {
  const { setPage } = props
  const t = useTranslation()
  const translation = useTranslationChange()

  return (
    <>
      <div className='absolute top-8 left-1/2 transform -translate-x-1/2 z-10'>
        <img src='/img/logo.png' alt='logo' className='w-32 h-32 object-contain' />
      </div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-white flex flex-col font-bold text-lg text-center items-center px-4 w-full max-w-lg'>
        <p className=''>{translation.lang === 'ru' ? russianQuote : englishQuote}</p>
        <button
          className='w-32 h-12 bg-white bg-opacity-30 rounded-full text-white text-xl font-bold mt-4'
          style={{ boxShadow: boxShadowDefault, backdropFilter: 'blur(10px)' }}
          onClick={() => {
            setPage('game')
            blocksState.isReadyToMine = true
            server.game.initializeCubes()
          }}
        >
          {t('start')}
        </button>
      </div>
    </>
  )
}
