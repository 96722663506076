import { Circle } from './Circle'
import { useEffect } from 'react'
import { useTranslation } from 'i18nano'
import { cubes } from '../../../logic/game/cubes'
import { useBlocksStore } from '../../../stores/blocksStore'

export function FirstOnboarding() {
  const setBlocks = useBlocksStore((state) => state.setBlocks)
  const t = useTranslation()

  useEffect(() => {
    setBlocks([cubes[0], cubes[1], cubes[2], cubes[3], cubes[4], cubes[4], cubes[4]])
  }, [])
  return (
    <>
      <div className='absolute top-4 left-1/2 transform -translate-x-1/2 z-10 flex flex-col items-center'>
        <h1 className='text-white font-bold' style={{ fontSize: 50 }}>
          ???
        </h1>
        <h1 className='text-white font-bold text-2xl text-center'>{t('hold-to-mine-block')}</h1>
      </div>
      <div
        className='absolute left-1/2 transform -translate-x-1/2 z-10 -translate-y-1/2 pointer-events-none'
        style={{
          top: 'calc(50% - 50px)',
        }}
      >
        <Circle />
      </div>
    </>
  )
}
