import { useTexture } from "@react-three/drei";
import * as THREE from "three";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { useSpring, animated } from "@react-spring/three";
import { blockSize, blocksState, miningBlockPosition } from "./Blocks";
import { gameState } from "./View";
import { soundBuffers, soundUrls } from "../../logic/game/music";
import { useUserStore } from "../../stores/userStore";
import { audioStatus } from "../../helpers/audio";

interface Props {
  active: boolean;
  blockId: number | null;
}

const imgSize = 16;

let maxY = 0.1;
let wasPlayed = false;

let currentHit = 0;

let isSoundLoaded = false;

let maxXChange = 6;
let maxYChange = 6;

let l1 = [50965, 54, "50965", "54"];

const lastPointerUpdate = {
  x: 0,
  y: 0,
};
let needToLoad = Object.keys(soundUrls).length;

export default function Axe(props: Props) {
  const { active, blockId } = props;
  const mesh = useRef<any>(null);
  const user = useUserStore((state) => state.user);

  const pickaxeRef = useRef<any>(null);

  const pickaxe = useMemo(() => {
    return new THREE.Matrix4();
  }, []);

  const [springs, api] = useSpring(
    () => ({
      scale: 1,
      position: [100, 0, 0],
      rotation: [0, 0, 0],
      color: "#ff6d6d",
      config: (key) => {
        switch (key) {
          case "scale":
            return {};
          case "position":
            return { mass: 0.1, friction: 6.5 };
          default:
            return {};
        }
      },
    }),
    []
  );

  useEffect(() => {
    if (gameState.isPaused) return;
    // initialize the instanced mesh
    // load /textures/pickaxe-1.png and get all pixels and their positions
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;
    const img = new Image();
    img.src = "/textures/pickaxe-2.png";

    img.onload = () => {
      if (!pickaxeRef.current) return;

      canvas.width = imgSize;
      canvas.height = imgSize;
      ctx.drawImage(img, 0, 0);

      // get all pixels
      const data = ctx.getImageData(0, 0, imgSize, imgSize).data;

      const color = new THREE.Color();
      for (let i = 0; i < imgSize; i++) {
        for (let j = 0; j < imgSize; j++) {
          const index = (i * imgSize + j) * 4;
          const r = data[index];
          const g = data[index + 1];
          const b = data[index + 2];
          const a = data[index + 3];

          if (a > 0) {
            pickaxe.setPosition(i - 4, j - 4, 0);
            pickaxeRef.current.setMatrixAt(i * imgSize + j, pickaxe);
            pickaxeRef.current.setColorAt(
              i * imgSize + j,
              color.setHex((r << 16) + (g << 8) + b)
            );
          } else {
            pickaxe.setPosition(0, -1000, 0);
            pickaxeRef.current.setMatrixAt(i * imgSize + j, pickaxe);
          }
        }
      }

      pickaxeRef.current.instanceMatrix.needsUpdate = true;
    };
  }, []);

  useEffect(() => {
    if (active) {
      api.start({ scale: 1, position: [-15, 27, 65], rotation: [0, 0, 0] });
    } else {
      api.start({ scale: 1, position: [30, -10, 65], rotation: [0, 0, 0] });
    }
  }, [active]);

  const listener = useMemo(() => new THREE.AudioListener(), []);
  const sound1 = useMemo(() => new THREE.Audio(listener), []);
  const sound2 = useMemo(() => new THREE.Audio(listener), []);
  const sound3 = useMemo(() => new THREE.Audio(listener), []);
  const sound4 = useMemo(() => new THREE.Audio(listener), []);
  const sound5 = useMemo(() => new THREE.Audio(listener), []);
  const audioLoader = useMemo(() => new THREE.AudioLoader(), []);

  const [isSoundsLoaded, setIsSoundsLoaded] = useState(false);

  useEffect(() => {
    if (needToLoad === 0) {
      console.log("Sounds loaded");
      return;
    }

    Object.keys(soundUrls).forEach((key) => {
      if (soundBuffers[key]) return;

      audioLoader.load(soundUrls[key], (buffer) => {
        soundBuffers[key] = buffer;
        needToLoad--;

        if (needToLoad === 0) {
          setIsSoundsLoaded(true);
        }
      });
    });
  }, [isSoundsLoaded]);

  useEffect(() => {
    const onPointerMove = (event: PointerEvent) => {
      lastPointerUpdate.x = event.clientX;
      lastPointerUpdate.y = event.clientY;
    };

    window.addEventListener("pointermove", onPointerMove);

    return () => {
      window.removeEventListener("pointermove", onPointerMove);
    };
  }, []);

  useEffect(() => {
    const buffer = soundBuffers[blockId + "-hit"];
    if (!buffer) return;

    let volume = 0.1;
    sound1.setBuffer(buffer);
    sound1.setLoop(false);
    sound1.setVolume(volume);
    sound2.setBuffer(buffer);
    sound2.setLoop(false);
    sound2.setVolume(volume);
    sound3.setBuffer(buffer);
    sound3.setLoop(false);
    sound3.setVolume(volume);
    sound4.setBuffer(buffer);
    sound4.setLoop(false);
    sound4.setVolume(volume);
    sound5.setBuffer(buffer);
    sound5.setLoop(false);
    sound5.setVolume(volume);
  }, [blockId]);

  useFrame((state, delta) => {
    if (active) {
      mesh.current.rotation.y =
        Math.cos(blocksState.duration * 16) * (Math.PI / 18);
      mesh.current.rotation.x =
        -Math.cos(blocksState.duration * 16) * (Math.PI / 8);

      if (lastPointerUpdate.x !== 0) {
        pickaxeRef.current.position.x =
          20 + maxXChange * (lastPointerUpdate.x / window.innerWidth - 0.5);
        pickaxeRef.current.position.y =
          -maxYChange * (lastPointerUpdate.y / window.innerHeight - 0.5);
      }

      if (mesh.current.rotation.y > maxY && wasPlayed === false) {
        wasPlayed = true;
        // play sound and if already playing, stop it
        currentHit = (currentHit + 1) % 5;
        if (!audioStatus.isMuted) {
          if (currentHit === 0) {
            sound1.stop();
            sound1.play();
          } else if (currentHit === 1) {
            sound2.stop();
            sound2.play();
          } else if (currentHit === 2) {
            sound3.stop();
            sound3.play();
          } else if (currentHit === 3) {
            sound4.stop();
            sound4.play();
          } else if (currentHit === 4) {
            sound5.stop();
            sound5.play();
          }
        }
      } else if (mesh.current.rotation.y < maxY && wasPlayed === true) {
        wasPlayed = false;
      }
    }
  });

  return (
    <>
      <group dispose={null} {...props} visible={true}>
        <animated.group
          position={springs.position.to((x, y, z) => [x, y, z]) as any}
          ref={mesh}
        >
          {/* <mesh geometry={(nodes.Mesh_1001_1 as any).geometry} material={materials.material_2} />
          <mesh geometry={(nodes.Mesh_1001_2 as any).geometry} material={materials.material_3} /> */}
          <instancedMesh
            dispose={null}
            position={[20, 0, 0]}
            scale={1.5}
            rotation={[-Math.PI / 5, Math.PI / 1.7, 0]}
            ref={pickaxeRef}
            args={[undefined, undefined, imgSize * imgSize]}
          >
            <boxGeometry args={[1, 1, 1]} />
            <meshPhongMaterial
              color={"#ffffff"}
              depthWrite={true}
              transparent={false}
            />
          </instancedMesh>
        </animated.group>
      </group>
    </>
  );
}
