import { useEffect, useState } from "react";
import { LeaderboardCard } from "../LeaderboardCard";
import { LeaderboardListContainer, SplitLine } from "../Leaderboard";
import { PoolJoined } from "./PoolJoined";
import { PoolOther } from "./PoolOther";
import { TopButtons } from "../TopButtons";
import { PoolTimestampCard } from "./PoolTimestampCard";
import { useTranslation } from "i18nano";
import { useUserStore } from "../../../../stores/userStore";
import { usePoolsStore } from "../../../../stores/poolsStore";
import server from "../../../../logic/server";
import { getLeague, getPoolBoost } from "../../../../logic/game/pools";
import { poolPreviewUrl, sTelegramUrl, storageUrl } from "../../../../logic/game/music";
import { inputShadow } from "../../utils";

export function PoolInfo(props: {
  poolId: string;
  tab: string;
  setTab: (tab: string) => void;
}) {
  const { poolId, tab, setTab } = props;

  const user = useUserStore((state) => state.user);
  const selected = usePoolsStore((state) => state.selectedPool);

  const t = useTranslation();
  useEffect(() => {
    server.pools.getSelectedPool(parseInt(poolId));
  }, []);

  const [boost, setBoost] = useState<any>();
  const [league, setLeague] = useState<any>();

  useEffect(() => {
    if (!selected) return;

    setBoost(getPoolBoost(selected.invested));
  }, [selected]);

  useEffect(() => {
    if (!selected) return;

    setLeague(getLeague(selected.totalMinedQuestions));
  }, [selected]);

  useEffect(() => {
    if (selected?.stats || tab !== "stats") return;

    server.pools.getStats(poolId);
  }, [tab]);

  return (
    <>
      <TopButtons
        isOwner={
          selected?.ownerId && selected.ownerId + "" === user!.id + ""
            ? true
            : false
        }
        isPool={true}
        isJoined={
          user?.pool_id && parseInt(user.pool_id) === selected?.id
            ? true
            : false
        }
      />
      <img
        src={`${storageUrl}${
          server.server.isDev ? "/staging" : ""
        }/telegram/channels/${poolId}.jpg`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = poolPreviewUrl;
        }}
        alt="flag"
        className="w-20 h-20 object-contain rounded-xl"
      />
      {selected && boost && league && user ? (
        <>
          <h1
            className="cursor-pointer underline text-2xl text-white font-bold mt-2 mb-2 w-full px-2 text-center whitespace-nowrap overflow-hidden overflow-ellipsis shrink-0"
            onClick={() => {
              let username = selected.username;
              if (username.startsWith("@")) {
                username = username.substring(1);
              }

              const tgLink = `https://t.me/${username}`;
              window.open(tgLink, "_blank");
            }}
          >
            {selected.name}
          </h1>
          <div className="flex flex-row w-full justify-center gap-4 mb-4">
            <IconText
              icon={`${league.texture}`}
              textColor="black"
              text={`#${selected.rank + 1}`}
              textSize="md"
              pixelated={true}
            />
            <IconText
              icon="/img/group.svg"
              textColor="black"
              text={selected.joinedAmount + ""}
              textSize="md"
            />
            <p
              className="text-black font-semibold text-md"
              style={{ marginLeft: -0 }}
            >
              ⚡️ lvl {boost.id + 1}
            </p>
            <p
              className="text-black font-semibold text-md"
              style={{ marginLeft: -0 }}
            >
              📈 {(boost.mul * 100 - 100).toFixed(2)}%
            </p>
          </div>

          {user.pool_id && parseInt(user.pool_id) === selected.id ? (
            tab === "miners" ? (
              <PoolJoined />
            ) : (
              <PoolOther joined={true} />
            )
          ) : (
            <PoolOther />
          )}

          <div
            className="flex flex-row w-full items-center bg-purple-bg bg-opacity-10 p-1.5 mb-2 rounded-2xl"
            style={{ boxShadow: inputShadow }}
          >
            <PoolButton
              text={t("miners")}
              onClick={() => setTab("miners")}
              selected={tab === "miners"}
            />
            <PoolButton
              onClick={() => setTab("stats")}
              text={t("pool-stats")}
              selected={tab === "stats"}
            />
          </div>
          {tab === "miners" && (
            <>
              {selected?.me && (
                <>
                  <LeaderboardCard
                    noFuture={true}
                    place={selected.me.rank!}
                    title={selected.me.name}
                    poolInvested={selected.me.poolInvested}
                    img={`${sTelegramUrl}/users/${selected.me.id}.jpg`}
                    type="cubes"
                    value={selected.me.minedQuestions}
                    highlight={true}
                  />
                  <SplitLine />
                </>
              )}

              <LeaderboardListContainer>
                {selected.users?.map((user, i) => {
                  return (
                    <LeaderboardCard
                      noFuture={true}
                      key={i}
                      place={i}
                      poolInvested={user.poolInvested}
                      title={user.name}
                      img={`${sTelegramUrl}/users/${user.id}.jpg`}
                      type="cubes"
                      value={user.minedQuestions}
                    />
                  );
                })}
                {!selected.users?.length && (
                  <div className="flex w-full justify-center items-center mt-2 gap-2 font-bold text-[#eee]">
                    No miners in this pool
                  </div>
                )}
                {selected.isMinersLoading && <DropsLoader mt={10} />}
              </LeaderboardListContainer>
            </>
          )}

          {tab === "stats" &&
            (!selected.stats ? (
              <span className="loader mt-4" />
            ) : (
              <div className={`flex w-full h-max flex-col gap-1.5 pb-10`}>
                {selected.stats.map((timestamp, i) => {
                  return <PoolTimestampCard key={i} timestamp={timestamp} />;
                })}
              </div>
            ))}
        </>
      ) : (
        <DropsLoader mt={48} />
      )}
    </>
  );
}

function DropsLoader(props: { mt?: number }) {
  const { mt } = props;

  return (
    <div
      className={`flex flex-row items-center justify-center w-full mt-${
        mt ? mt : 10
      } gap-2`}
    >
      <h1 className="text-xl text-white font-bold">Loading</h1>
      <span className="loader" />
    </div>
  );
}

function PoolButton(props: {
  text: string;
  selected?: boolean;
  onClick?: () => void;
}) {
  const { text, selected, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={`flex w-full h-8 items-center justify-center bg-${
        selected ? "white" : "none"
      } text-${
        selected ? "grey-800" : "white"
      } font-semibold text-lg rounded-xl ${selected && "shadow-md"}`}
    >
      {text}
    </button>
  );
}

export function IconText(props: {
  icon: string;
  text: string;
  textColor?: string;
  textWeight?: string;
  iconSize?: number;
  textSize?: string;
  pixelated?: boolean;
}) {
  const { icon, text, iconSize, textSize, pixelated, textColor, textWeight } =
    props;

  return (
    <div className="flex flex-row items-center">
      <img
        src={icon}
        alt="box-icon"
        className={`w-${iconSize || 4} h-${
          iconSize || 4
        } object-contain rounded-sm`}
        style={{
          imageRendering: pixelated ? "pixelated" : "auto",
        }}
      />
      <p
        className={`ml-1 text-${textColor || "white"} font-${
          textWeight ? textWeight : "semibold"
        } text-${textSize || "sm"}`}
      >
        {text}
      </p>
    </div>
  );
}
