import { useTranslation } from "i18nano";
import { usePoolsStore } from "../../../stores/poolsStore";
import { useUserStore } from "../../../stores/userStore";
import { LeaderboardCard } from "./LeaderboardCard";
import { usePageStore } from "../../../stores/pageStore";
import { Link } from "react-router-dom";
import { sTelegramUrl } from "../../../logic/game/music";
import { getLeague, getPoolBoost } from "../../../logic/game/pools";
import { getLeagueUser, getUserBoost } from "../../../logic/game/users";
import { Button } from "../Button";
import server from "../../../logic/server";

export function BottomInfo(props: { isUsers?: boolean }) {
  const { isUsers } = props;

  const myPool = usePoolsStore((state) => state.myPool);
  const me = useUserStore((state) => state.me);
  const setPage = usePageStore((state) => state.setPage);
  const claim = usePoolsStore((state) => state.claim);
  const isClaimLoading = usePoolsStore((state) => state.isClaimLoading);

  const t = useTranslation();

  return (!isUsers && myPool) || (isUsers && me) ? (
    <div
      className="absolute bottom-0 left-1/2 w-full h-max bg-[#8787AC] flex flex-col rounded-t-3xl p-3 max-w-sm transform -translate-x-1/2"
      style={{
        zIndex: 21,
      }}
    >
      {!isUsers && (
        <h1 className="text-white font-semibold text-md mb-1.5">
          {isUsers ? "Me" : t("my-pool")}
        </h1>
      )}
      {!isUsers && (
        <Link to={`/pool/${myPool?.id}`}>
          <LeaderboardCard
            place={myPool!.rank}
            title={myPool!.name}
            img={`${sTelegramUrl}/channels/${myPool?.id}.jpg`}
            type="cubes"
            value={myPool!.totalMinedQuestions}
            leagueTexture={getLeague(myPool!.totalMinedQuestions).texture}
            boostLvl={getPoolBoost(myPool!.invested).id + 1}
            hashrate={myPool!.hashrate}
            highlight
          />
        </Link>
      )}
      {isUsers && (
        <LeaderboardCard
          place={me!.rank || 0}
          title={me!.name}
          img={`${sTelegramUrl}/users/${me!.id}.jpg`}
          type="mined_count"
          value={me!.mined_count}
          leagueTexture={getLeagueUser(me!.mined_count).texture}
          boostLvl={getUserBoost(me!.invested).id + 1}
          highlight
          noFuture={true}
        />
      )}
      <div className="flex flex-row w-full gap-2">
        <Button
          isLoading={isClaimLoading}
          text={`${t("claim")} ${claim?.toFixed(3)}`}
          styles={"mt-2"}
          icon="/textures/texture-question.png"
          bgColor={isUsers ? "white" : undefined}
          color={isUsers ? "black" : undefined}
          onClick={() => {
            server.pools.claim();
          }}
        />
        {isUsers && (
          <Button
            isLoading={isClaimLoading}
            text={`${t("boost")}`}
            styles={"mt-2"}
            icon="/img/drop-icon.png"
            onClick={() => {
              setPage("boost");
            }}
          />
        )}
      </div>
    </div>
  ) : null;
}
