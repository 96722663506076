import React, { useRef, useMemo, useEffect } from 'react'
import { extend, useThree, useFrame, useLoader, Object3DNode } from '@react-three/fiber'
import * as THREE from 'three'
import { Water } from 'three/examples/jsm/objects/Water.js'
// import { Sky } from 'three/examples/jsm/objects/Sky.js'
import { PMREMGenerator } from 'three'
import { Stats } from '@react-three/drei'
import { Sky } from './Sky'
import { gameState } from './View'
import { useUserStore } from '../../stores/userStore'
import { storageUrl } from '../../logic/game/music'

extend({ Water })

declare global {
  namespace JSX {
    interface IntrinsicElements {
      water: Object3DNode<Water, typeof Water>
    }
  }
}

function Ocean() {
  const ref = useRef<any>(null)
  const scene = useThree((state) => state.scene)

  const gl = useThree((state) => state.gl)

  let night = {
    elevation: -2.24,
    waterColor: 0x003e5f,
  }
  let day = {
    elevation: 0.8,
    waterColor: 0x001e0f,
  }
  let current = day

  if (new Date().getHours() >= 22 || new Date().getHours() < 6) {
    current = night
  }

  const parameters = useMemo(
    () => ({
      elevation: current.elevation,
      azimuth: 170,
    }),
    [],
  )

  const pmremGenerator = useMemo(() => new THREE.PMREMGenerator(gl), [gl])

  const setGameLoaded = useUserStore((state) => state.setGameLoaded)

  // Water setup
  const geom = useMemo(() => new THREE.PlaneGeometry(1000, 500), [])
  const config = useMemo(
    () => ({
      textureWidth: 512,
      textureHeight: 512,
      waterNormals: new THREE.TextureLoader().load(`${storageUrl}/textures/waternormals.jpg`, function (texture) {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping
        setGameLoaded(true)
      }),
      waterColor: 0x001e0f,
      sunDirection: new THREE.Vector3(),
      sunColor: 0xffffff,
      distortionScale: 4.7,
      fog: false,
    }),
    [],
  )

  useFrame((state, delta) => {
    if (gameState.isPaused || !ref.current) return
    ref.current.material.uniforms.time.value += delta
  })

  return (
    <>
      <Sky />
      <water ref={ref} args={[geom, config]} rotation-x={-Math.PI / 2} position={[0, 0, -200]} />
    </>
  )
}

export default Ocean
