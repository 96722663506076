import { useEffect, useState } from 'react'
import { ProgressBar } from '../Pool/PoolJoined'
import { IconText } from '../Pool/PoolInfo'
import { useUserStore } from '../../../../stores/userStore'
import { usePoolsStore } from '../../../../stores/poolsStore'
import { usePageStore } from '../../../../stores/pageStore'
import { getUserBoost, USER_BOOSTS } from '../../../../logic/game/users'
import { getPoolBoost, POOL_BOOSTS } from '../../../../logic/game/pools'
import { Panel } from '../../Panel'
import { MyDrops } from '../../RestorePanel'
import { getReadableNumber } from '../../../../logic/utils/parsing'
import { Button } from '../../Button'
import { toastError } from '../../../../logic/toast'
import server from '../../../../logic/server'

export function Boost() {
  const user = useUserStore((state) => state.user)
  const me = useUserStore((state) => state.me)
  const selected = usePoolsStore((state) => state.myPool)
  const [value, setValue] = useState('1000')
  const [number, setNumber] = useState(1000)
  const setPage = usePageStore((state) => state.setPage)

  const [isLoading, setIsLoading] = useState(false)

  const [userBoost, setUserBoost] = useState<any>(null)
  const [poolBoost, setPoolBoost] = useState<any>(null)

  useEffect(() => {
    if (me) {
      setUserBoost(USER_BOOSTS[getUserBoost(me.invested).id + 1])
    }
  }, [me])

  useEffect(() => {
    if (selected) {
      setPoolBoost(POOL_BOOSTS[getPoolBoost(selected.invested).id + 1])
    }
  }, [selected])

  useEffect(() => {
    if (!isNaN(parseInt(value)) && parseInt(value) >= 0) {
      setNumber(parseInt(value))
    }
  }, [value])

  return (
    <>
      <Panel title='Boost' level={30} titleColor='grey-800' onClose={() => setPage(undefined)}>
        <MyDrops />
        {/* <div className='flex flex-col w-full items-center gap-4'> */}
        {/* <h1 className='text-grey-800 font-bold text-lg'>Boost Pool</h1> */}

        {userBoost && (
          <>
            <div className='w-full flex flex-row justify-between'>
              <p className='text-black font-medium text-sm'>
                My boost 📈+{((USER_BOOSTS[userBoost.id - 1].mul - 1) * 100).toFixed(2)}%
              </p>
              <IconText
                text={`${getReadableNumber(me!.invested)}/${getReadableNumber(userBoost.investments)}`}
                icon='/img/drop-icon.png'
                pixelated={true}
                textColor='white'
              />
            </div>
            <ProgressBar currentLvl={userBoost.id} max={userBoost.investments} invested={me!.invested} />
          </>
        )}
        {poolBoost && (
          <>
            <div className='w-full flex flex-row justify-between'>
              <p className='text-black font-medium text-sm'>
                My pool 📈+{((POOL_BOOSTS[poolBoost.id - 1].mul - 1) * 100).toFixed(2)}%
              </p>
              <IconText
                text={`${getReadableNumber(selected!.invested)}/${getReadableNumber(poolBoost.investments)}`}
                icon='/img/drop-icon.png'
                pixelated={true}
                textColor='white'
              />
            </div>
            <ProgressBar currentLvl={poolBoost.id} max={poolBoost.investments} invested={selected!.invested} />
          </>
        )}
        <div className='w-full h-14 relative mt-2'>
          <input
            value={value}
            className='w-full h-14 bg-white p-2 rounded-xl text-grey-800 font-semibold text-xl text-center'
            placeholder='Enter amount'
            onChange={(e) => setValue(e.target.value)}
            style={{
              outline: 'none',
            }}
          />
          <div className='absolute right-2 top-1/2 transform -translate-y-1/2'>
            <img
              src='/img/drop-icon.png'
              alt='drop-icon'
              className='w-8 h-8 object-contain'
              style={{ imageRendering: 'pixelated' }}
            />
          </div>
        </div>
        <p className='text-black font-medium text-sm w-full text-center mt-2'>
          When you boost your pool, you also boost your mining perfomance
        </p>
        <Button
          text={`Boost ${getReadableNumber(number)}`}
          icon='/img/drop-icon.png'
          isLoading={isLoading}
          styles='mt-1'
          onClick={() => {
            if (parseInt(user!.drops_amount) < number) {
              toastError('You do not have enough drops')
              return
            }
            setIsLoading(true)
            server.pools.boost(number).then(() => {
              setIsLoading(false)
            })
          }}
        />
        {/* </div> */}
      </Panel>
    </>
  )
}

export function CloseButton(props: { onClick?: () => void }) {
  return (
    <button className='absolute right-5 top-5' onClick={props?.onClick}>
      <img
        src='/img/close.svg'
        alt='close'
        className='w-4 h-4 object-contain'
        style={{
          filter: 'invert(0.8)',
        }}
      />
    </button>
  )
}
