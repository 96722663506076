import { useMemo } from "react";
import * as THREE from "three";
import { BlockPixel, blocksState, imgSize as cubeTextureSize } from "../Blocks";

export function createObjectFromTexture(
  matrix: THREE.Matrix4,
  mesh: THREE.InstancedMesh,
  imgSize: number,
  src: string,
  setIsReady?: (value: boolean) => void
) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.src = src;
  if (!ctx) return;
  img.onload = () => {
    console.log("LOADED");
    canvas.width = imgSize;
    canvas.height = imgSize;
    ctx.drawImage(img, 0, 0);

    // get all pixels
    const data = ctx.getImageData(0, 0, imgSize, imgSize).data;

    const color = new THREE.Color();
    for (let i = 0; i < imgSize; i++) {
      for (let j = 0; j < imgSize; j++) {
        const index = (i * imgSize + j) * 4;
        const r = data[index];
        const g = data[index + 1];
        const b = data[index + 2];
        const a = data[index + 3];

        if (a > 0) {
          matrix.setPosition(i - 4, j - 4, 0);
          mesh.setMatrixAt(i * imgSize + j, matrix);
          mesh.setColorAt(
            i * imgSize + j,
            color.setHex((r << 16) + (g << 8) + b)
          );
        } else {
          matrix.setPosition(-1000, -1000, -1000);
          mesh.setMatrixAt(i * imgSize + j, matrix);
        }
      }
    }

    mesh.instanceMatrix.needsUpdate = true;
    setIsReady?.(true);
  };
}

export function createCubeFromTexture(
  matrix: THREE.Matrix4,
  mesh: THREE.InstancedMesh,
  image: ImageData,
  color: THREE.Color
) {
  if (!image) return;

  const imgSize = cubeTextureSize;

  for (let i = 0; i < imgSize; i++) {
    for (let k = 0; k < imgSize; k++) {
      for (let j = 0; j < imgSize; j++) {
        let index = (k * imgSize + j) * 4;
        const r = image.data[index];
        const g = image.data[index + 1];
        const b = image.data[index + 2];
        const a = image.data[index + 3];

        if (a > 0) {
          matrix.setPosition(i - imgSize / 2, j - imgSize / 2, k - imgSize / 2);
          mesh.setMatrixAt(k * imgSize * imgSize + i * imgSize + j, matrix);
          mesh.setColorAt(
            k * imgSize * imgSize + i * imgSize + j,
            color.setHex((r << 16) + (g << 8) + b)
          );
        } else {
          matrix.setPosition(-1000, -1000, -1000);
          mesh.setMatrixAt(k * imgSize * imgSize + i * imgSize + j, matrix);
        }
      }
    }
  }

  // make front
  for (let i = 0; i < imgSize; i++) {
    for (let j = 0; j < imgSize; j++) {
      let k = imgSize - 1;

      const index = (i * imgSize + j) * 4;
      const r = image.data[index];
      const g = image.data[index + 1];
      const b = image.data[index + 2];
      const a = image.data[index + 3];

      if (a > 0) {
        matrix.setPosition(i - imgSize / 2, j - imgSize / 2, k - imgSize / 2);
        mesh.setMatrixAt(k * imgSize * imgSize + i * imgSize + j, matrix);
        mesh.setColorAt(
          k * imgSize * imgSize + i * imgSize + j,
          color.setHex((r << 16) + (g << 8) + b)
        );
      } else {
        matrix.setPosition(-1000, -1000, -1000);
        mesh.setMatrixAt(k * imgSize * imgSize + i * imgSize + j, matrix);
      }
    }
  }

  mesh.instanceMatrix.needsUpdate = true;
}

export function createOptimizedCubeFromTexture(
  matrix: THREE.Matrix4,
  mesh: THREE.InstancedMesh,
  image: ImageData,
  color: THREE.Color
) {
  if (!image) return;

  // make top
  let count = 0;
  for (let k = 0; k < cubeTextureSize; k++) {
    for (let j = 0; j < cubeTextureSize; j++) {
      let i = 0;

      const index = (k * cubeTextureSize + j) * 4;
      const r = image.data[index];
      const g = image.data[index + 1];
      const b = image.data[index + 2];
      const a = image.data[index + 3];

      if (a > 0) {
        matrix.setPosition(
          i - cubeTextureSize / 2,
          j - cubeTextureSize / 2,
          k - cubeTextureSize / 2
        );
        mesh.setMatrixAt(count, matrix);
        mesh.setColorAt(count, color.setHex((r << 16) + (g << 8) + b));
        count++;
      }
    }
  }

  // make bottom
  for (let k = 0; k < cubeTextureSize; k++) {
    for (let j = 0; j < cubeTextureSize; j++) {
      let i = cubeTextureSize - 1;

      const index = (k * cubeTextureSize + j) * 4;
      const r = image.data[index];
      const g = image.data[index + 1];
      const b = image.data[index + 2];
      const a = image.data[index + 3];

      if (a > 0) {
        matrix.setPosition(
          i - cubeTextureSize / 2,
          j - cubeTextureSize / 2,
          k - cubeTextureSize / 2
        );
        mesh.setMatrixAt(count, matrix);
        mesh.setColorAt(count, color.setHex((r << 16) + (g << 8) + b));
        count++;
      }
    }
  }

  // make front
  for (let i = 0; i < cubeTextureSize; i++) {
    for (let j = 0; j < cubeTextureSize; j++) {
      let k = cubeTextureSize - 1;

      const index = (i * cubeTextureSize + j) * 4;
      const r = image.data[index];
      const g = image.data[index + 1];
      const b = image.data[index + 2];
      const a = image.data[index + 3];

      if (a > 0) {
        matrix.setPosition(
          i - cubeTextureSize / 2,
          j - cubeTextureSize / 2,
          k - cubeTextureSize / 2
        );
        mesh.setMatrixAt(count, matrix);
        mesh.setColorAt(count, color.setHex((r << 16) + (g << 8) + b));
        count++;
      }
    }
  }
}

export function updateCubeStatus(
  mesh: THREE.InstancedMesh,
  progress: number,
  matrix: THREE.Matrix4
) {
  const imgSize = cubeTextureSize;
  // progress is 0 - 100
  // remove blocks in the percentage (set -1000 position)

  let count = 0;
  let neededToRemove = Math.floor(
    (progress / 100) * imgSize * imgSize * imgSize
  );

  for (let i = 0; i < imgSize; i++) {
    for (let j = 0; j < imgSize; j++) {
      for (let k = 0; k < imgSize; k++) {
        if (count < neededToRemove) {
          count++;

          const id = (imgSize - k - 1) * imgSize * imgSize + i * imgSize + j;
          const obj: any = blocksState.pixels1;

          if (obj[id]) {
            continue;
          } else {
            const pixel: BlockPixel = {
              direction: [
                Math.random() * 4 + 2,
                Math.random() * 4 - 2,
                Math.random() * 4,
              ],
              position: [i - imgSize / 2, j - imgSize / 2, k - imgSize / 2],
              time: 0,
              id: id,
            };

            obj[id] = pixel;

            matrix.setPosition(
              pixel.position[0],
              pixel.position[1],
              pixel.position[2]
            );
            mesh.setMatrixAt(id, matrix);
          }
        }
      }
    }
  }

  mesh.instanceMatrix.needsUpdate = true;
}

export function updatePixels(pixels: any, mat: THREE.Matrix4, delta: number) {
  const mesh = pixels["block"];
  if (!mesh) return;

  Object.keys(pixels).forEach((key) => {
    if (key === "block") return;

    const pixel = pixels[key];
    pixel.position[0] += pixel.direction[0] * delta * 10;
    pixel.position[1] += pixel.direction[1] * delta * 10;
    pixel.position[2] += pixel.direction[2] * delta * 10;
    pixel.time += delta;

    mat.setPosition(pixel.position[0], pixel.position[1], pixel.position[2]);
    mesh.setMatrixAt(pixel.id, mat);
  });

  mesh.instanceMatrix.needsUpdate = true;
}
