import { Button } from "./Button";
import { Panel } from "./Panel";
import { useState } from "react";
import { useTranslation } from "i18nano";
import { usePageStore } from "../../stores/pageStore";
import { dropsProposals } from "../../logic/game/market";
import server from "../../logic/server";

export function BuyDropsPanel() {
  const setPage = usePageStore((state) => state.setPage);
  const setBottomWalletPay = usePageStore((state) => state.setBottomWalletPay);

  const [selectedId, setSelectedId] = useState(
    dropsProposals[Object.keys(dropsProposals)[0] as any].id
  );
  const t = useTranslation();
  const [fromTelegram, setFromTelegram] = useState(
    typeof (window as any).TelegramWebviewProxy !== "undefined"
  );

  return (
    <Panel title={t("buy-drops")} onClose={() => setPage(undefined)} level={30}>
      <div className="grid grid-cols-2 gap-4 w-full">
        {Object.values(dropsProposals).map(
          (el, index) =>
            ((fromTelegram && el.type === "stars") ||
              (!fromTelegram && el.type === "market")) && (
              <div
                key={index}
                className="w-full bg-white bg-opacity-30 flex flex-col items-center justify-center gap-2 rounded-2xl p-2"
                style={{
                  boxShadow:
                    "inset 0 0 20px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)",
                  background:
                    selectedId === el.id
                      ? "rgba(255, 255, 255, 0.6)"
                      : "rgba(255, 255, 255, 0.3)",
                }}
                onClick={() => setSelectedId(el.id)}
              >
                <div className="w-20 h-20 relative flex justify-center items-center">
                  <img
                    src="/img/drop-icon.png"
                    alt="drop-icon"
                    className="w-24 h-24 object-contain"
                    style={{ imageRendering: "pixelated" }}
                  />
                  <div
                    className="absolute min-w-28 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center gap-1 rounded-full"
                    style={{
                      background: "rgba(255, 255, 255, 0.2)",
                      backdropFilter: "blur(5px)",
                      WebkitBackdropFilter: "blur(10px)",
                      boxShadow:
                        "inset 0 0 20px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <p className="text-white font-bold text-lg">
                      {el.dropsString}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row items-center text-xs font-semibold text-[#484848] gap-0.5 mt-1 mb-1">
                  {!fromTelegram ? (
                    <>
                      {el.tonPrice}
                      <img
                        src="/img/ton-icon.svg"
                        alt="ton-icon"
                        className="w-4 h-4 object-contain"
                      />
                      {"/ " + el.punkPrice}
                      <img
                        src="/img/punk-icon.svg"
                        alt="ton-icon"
                        className="w-4 h-4 object-contain"
                      />
                    </>
                  ) : (
                    <>
                      {splitNumber(el.starsPrice || 0)}
                      <img
                        src="/img/star.png"
                        alt="ton-icon"
                        className="w-4 h-4 object-contain"
                      />
                    </>
                  )}
                </div>
                <button
                  onClick={() => setSelectedId(el.id)}
                  className="h-8 w-full bg-white bg-opacity-80 flex flex-row items-center justify-center gap-1 rounded-full shadow-md"
                  style={{
                    color: selectedId === el.id ? "#484848" : "#fff",
                    background:
                      selectedId === el.id
                        ? "#fff"
                        : "rgba(255, 255, 255, 0.2)",
                  }}
                >
                  <p className="font-semibold">
                    {selectedId === el.id ? t("selected") : t("select")}
                  </p>
                </button>
              </div>
            )
        )}
      </div>
      {fromTelegram && (
        <Button
          text={t("pay-in") + " Stars"}
          icon="/img/star.png"
          styles="mt-2"
          onClick={
            () => {
              server.wallet.createStarsInvoice(selectedId);
            }
            // setBottomWalletPay({ proposalId: selectedId, type: "STARS" })
          }
        />
      )}
      {!fromTelegram && (
        <>
          <Button
            text={t("pay-in") + " $PUNK"}
            icon="/img/punk-icon.svg"
            styles="mt-2"
            onClick={() =>
              setBottomWalletPay({ proposalId: selectedId, type: "PUNK" })
            }
          />
          <Button
            text={t("pay-in") + " TON"}
            icon="/img/ton-icon.svg"
            onClick={() =>
              setBottomWalletPay({ proposalId: selectedId, type: "TON" })
            }
          />
        </>
      )}
    </Panel>
  );
}

function splitNumber(n: number) {
  // split to format 123 123 123
  const str = n.toString();
  const arr = str.split("");
  const result = [];
  let counter = 0;
  for (let i = arr.length - 1; i >= 0; i--) {
    if (counter === 3) {
      result.push(" ");
      counter = 0;
    }
    result.push(arr[i]);
    counter++;
  }

  return result.reverse().join("");
}
