import { BlurBackground } from './BlurBackround'
import { boxShadowDefault } from './utils'

interface Props {
  title: string | null
  children: React.ReactNode
  onClose?: () => void
  level?: number
  titleColor?: string
}
export function Panel({ titleColor, level, title, children, onClose }: Props) {
  return (
    <>
      <BlurBackground onClose={onClose} level={level} />
      <div className='absolute w-full max-w-sm p-4 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30'>
        <div
          className='flex flex-col bg-white bg-opacity-25 rounded-3xl items-center justify-center p-4 gap-2 relative'
          style={{
            boxShadow: boxShadowDefault,
          }}
        >
          {title && <h2 className={`text-${titleColor || 'white'} font-bold text-xl mb-2`}>{title}</h2>}

          <button className='absolute right-5 top-5' onClick={onClose}>
            <img
              src='/img/close.svg'
              alt='close'
              className='w-4 h-4 object-contain'
              style={{
                filter: titleColor ? 'invert(0.8)' : 'invert(0)',
              }}
            />
          </button>
          {children}
        </div>
      </div>
    </>
  )
}
