import { useTranslation } from 'i18nano'
import { useEffect, useState } from 'react'
import { usePageStore } from '../../../../stores/pageStore'
import { usePoolsStore } from '../../../../stores/poolsStore'
import { Panel } from '../../Panel'
import { poolPreviewUrl, sTelegramUrl } from '../../../../logic/game/music'
import { Button } from '../../Button'

export function NeedToSubscribe() {
  const setPage = usePageStore((state) => state.setPage)
  const selected = usePoolsStore((state) => state.selectedPool)
  const t = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Panel titleColor='grey-800' title={null} level={30} onClose={() => setPage(undefined)}>
      <img
        src={`${sTelegramUrl}/channels/${selected?.id}.jpg`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = poolPreviewUrl
        }}
        alt='texture-A'
        className='w-16 h-16 object-contain rounded-md'
        style={{
          imageRendering: 'pixelated',
        }}
      />
      <div className='flex flex-row w-full justify-center items-center gap-4 mt-2'>
        <p className='text-grey-800 text-lg text-center font-semibold w-full max-w-64' style={{ marginTop: -4 }}>
          {t('subscribe-to')} {selected!.name}, {t('to-join')}
        </p>
      </div>
      <Button
        isLoading={isLoading}
        text={t('go-to-channel')}
        styles='mt-2'
        onClick={() => {
          let username = selected!.username
          if (username.startsWith('@')) {
            username = username.substring(1)
          }

          window.open(`https://t.me/${username}`, '_blank')
        }}
      />
    </Panel>
  )
}
