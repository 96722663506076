interface Props {
  level?: number
  onClose?: () => void
}
export function BlurBackground(props: Props) {
  const { level, onClose } = props

  return (
    <div
      className={'absolute top-0 left-0 flex h-full w-full ' + (!level && ' z-20 ') + (level === 30 && ' z-30 ')}
      style={{
        // background: 'rgba(0, 0, 0, 0.9)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
      }}
      onClick={onClose}
    />
  )
}
