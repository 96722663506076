import { IconText } from './PoolInfo'
import { useEffect, useState } from 'react'
import { useTranslation } from 'i18nano'
import { usePoolsStore } from '../../../../stores/poolsStore'
import { usePageStore } from '../../../../stores/pageStore'
import { getPoolBoost, POOL_BOOSTS } from '../../../../logic/game/pools'
import { useUserStore } from '../../../../stores/userStore'
import { boxShadowDefault } from '../../utils'
import { getReadableNumber } from '../../../../logic/utils/parsing'
import { Button, GradientButton } from '../../Button'
import server from '../../../../logic/server'
import { shareTelegramPool } from '../../../../logic/utils/telegram'

export function PoolJoined() {
  const selected = usePoolsStore((state) => state.selectedPool)
  const claim = usePoolsStore((state) => state.claim)
  const isClaimLoading = usePoolsStore((state) => state.isClaimLoading)
  const setPage = usePageStore((state) => state.setPage)
  const user = useUserStore((state) => state.user)
  const t = useTranslation()
  const [boost, setBoost] = useState<any>()
  useEffect(() => {
    if (!selected) return

    setBoost(getPoolBoost(selected.invested))
  }, [selected])
  return (
    <div
      className='bg-white bg-opacity-30 w-full p-4 rounded-2xl text-lg font-semibold flex flex-col gap-3 mb-4 shadow-lg'
      style={{
        boxShadow: boxShadowDefault,
      }}
    >
      <div className='flex flex-row w-full justify-between items-center'>
        <IconText
          text={`${getReadableNumber(selected!.invested)} / ${getReadableNumber(POOL_BOOSTS[parseInt(boost?.id) + 1]?.investments)}`}
          textColor='grey-800'
          textSize='[15px]'
          iconSize={4}
          textWeight='font-semibold'
          icon='/img/drop-icon.png'
          pixelated={true}
        />

        {!selected?.ownerId && (
          <button
            className='w-max px-3 h-6 bg-white bg-opacity-80 rounded-full text-grey-800 text-sm font-semibold shadow-md'
            onClick={() => setPage('pool-need-verify')}
          >
            {t('you-owner')}
          </button>
        )}
      </div>

      <ProgressBar
        currentLvl={boost?.id + 1}
        invested={selected!.invested}
        max={POOL_BOOSTS[parseInt(boost?.id) + 1]?.investments}
      />

      <div className='flex flex-row w-full gap-2' style={{ height: 42 }}>
        <Button
          textSize='15px'
          h={10}
          isLoading={isClaimLoading}
          bgColor='white'
          color='grey-800'
          text={`${t('claim')} ${claim?.toFixed(3)}`}
          onClick={() => {
            server.pools.claim()
          }}
          icon='/textures/texture-question.png'
        />
        <Button
          h={10}
          textSize='15px'
          onClick={() => {
            setPage('boost')
          }}
          text={t('boost')}
          icon='/img/drop-icon.png'
          bgColor='grey-800'
        />
      </div>
      {selected?.ownerId + '' === user?.id + '' ? (
        selected?.promotionsLeft ? (
          <>
            <p className='text-lg font-semibold text-white text-center'>
              Promotion is active {`(${selected.promotionsLeft} users left)`}
            </p>
            <Button
              h={10}
              text={`Cancel & Get ${selected.promotionsLeft * selected.costPerUser!}`}
              icon='/img/drop-icon.png'
              onClick={() => {
                server.pools.cancelPromotion()
              }}
            />
          </>
        ) : (
          <GradientButton
            onClick={() => {
              setPage('promote-pool')
            }}
            text={'✨ Promote the pool ✨'}
          />
        )
      ) : (
        <Button
          h={10}
          textSize='15px'
          onClick={() => {
            shareTelegramPool(user!.id, selected!.id, 'Join me on CubesOnTheWater!')
          }}
          text={t('invite-frens')}
          color='grey-800'
          // icon='/img/drop-icon.png'
          bgColor='white'
        />
      )}
    </div>
  )
}

export function ProgressBar(props: { invested: number; max: number; currentLvl: number }) {
  const { invested, max, currentLvl } = props
  return (
    <div className='w-full h-5 bg-[#4B4D7D] bg-opacity-10 relative rounded-full'>
      <div
        className='absolute top-0 left-0 h-5 rounded-full bg-gradient-to-r from-[#F79BFF] to-[#665BFF]'
        style={{
          width: `${(invested / max) * 100}%`,
          zIndex: 0,
        }}
      />
      <div className='absolute left-0 flex flex-row w-full justify-between h-5 px-2 items-center font-bold'>
        <p className='text-xs text-white'>⚡️lvl {currentLvl}</p>
        <p className='text-xs text-grey-800'>⚡️lvl {currentLvl + 1}</p>
      </div>
    </div>
  )
}
