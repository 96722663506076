import { useTranslationChange } from 'i18nano'
import { useEffect } from 'react'
import { useUserStore } from '../../stores/userStore'

export function Translation() {
  const user = useUserStore((state) => state.user)
  const translation = useTranslationChange()

  useEffect(() => {
    if (typeof window === 'undefined') return

    let locale = user?.locale === 'ru' ? 'ru' : 'en'

    if (translation.lang !== locale) {
      translation.change(locale)
    }
  }, [user])

  return null
}
