interface RestProposal {
  id: number;
  dropsPrice: number;
  energy: number;
}
const defaultPrice = 50;

export const DROPS_FOR_SUBSCRIBE = 150;

export const MAX_CLAIM = 2;

export const restProposals: {
  [key: number]: RestProposal;
} = {
  1: {
    id: 1,
    dropsPrice: (defaultPrice * 100) / 100,
    energy: 100,
  },
  2: {
    id: 2,
    dropsPrice: (defaultPrice * 250) / 100,
    energy: 250,
  },
  3: {
    id: 3,
    dropsPrice: (defaultPrice * 500) / 100,
    energy: 500,
  },
  4: {
    id: 4,
    dropsPrice: (defaultPrice * 1000) / 100,
    energy: 1000,
  },
};

interface DropsProposal {
  id: number;
  drops: number;
  tonPrice?: number | null;
  dropsString: string;
  starsPrice?: number;
  punkPrice?: number;
  type: string;
}

export const dropsProposals: {
  [key: number]: DropsProposal;
} = {
  2: {
    id: 2,
    drops: 10000,
    dropsString: "10 000",
    tonPrice: 1.49,
    punkPrice: 3.49,
    starsPrice: 499,
    type: "market",
  },
  3: {
    id: 3,
    drops: 150000,
    dropsString: "150 000",
    tonPrice: 14.99,
    punkPrice: 34.99,
    starsPrice: 4990,
    type: "market",
  },
  4: {
    id: 4,
    drops: 2000000,
    dropsString: "2 000 000",
    tonPrice: 149.9,
    punkPrice: 349.9,
    starsPrice: 49900,
    type: "market",
  },
  6: {
    id: 6,
    drops: 15000000,
    dropsString: "15 000 000",
    tonPrice: 749.9,
    punkPrice: 1799.9,
    starsPrice: 249900,
    type: "market",
  },
  5: {
    id: 5,
    drops: 2500,
    dropsString: "2 500",
    tonPrice: 0.15,
    type: "daily-claim",
  },
  7: {
    id: 7,
    drops: 5000,
    dropsString: "5 000",
    tonPrice: 0.3,
    type: "daily-claim",
  },

  8: {
    id: 8,
    drops: 10000,
    dropsString: "10 000",
    starsPrice: 499,
    type: "stars",
  },
  9: {
    id: 9,
    drops: 150000,
    dropsString: "150 000",
    starsPrice: 4990,
    type: "stars",
  },
  10: {
    id: 10,
    drops: 350000,
    dropsString: "350 000",
    starsPrice: 9990,
    type: "stars",
  },
  11: {
    id:11,
    drops: 1500000,
    dropsString: "1 500 000",
    starsPrice: 34990,
    type: "stars",
  },
};
