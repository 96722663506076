import { Panel } from './Panel'
import { Button } from './Button'
import { useState } from 'react'
import { useTranslation } from 'i18nano'
import { usePageStore } from '../../stores/pageStore'
import { useUserStore } from '../../stores/userStore'
import { DROPS_FOR_SUBSCRIBE } from '../../logic/game/market'
import server from '../../logic/server'

export function News() {
  const setPage = usePageStore((state) => state.setPage)
  const user = useUserStore((state) => state.user)
  const t = useTranslation()

  const [verifying, setVerifying] = useState(false)

  const startVerifying = () => {
    setTimeout(() => {
      setVerifying(true)
    }, 500)
  }

  return (
    <Panel title='' onClose={() => setPage(undefined)}>
      <div className='flex flex-row gap-2 items-center' style={{ marginTop: -10 }}>
        <img src='/img/twitter.svg' alt='telegram' className='w-12 h-12 object-contain' />
        <img
          src='/img/drop-icon.png'
          alt='telegram'
          className='w-20 h-20 object-contain'
          style={{ imageRendering: 'pixelated' }}
        />
        <img src='/img/telegram.svg' alt='telegram' className='w-12 h-12 object-contain' />
      </div>
      <h1 className='text-2xl text-white font-bold mt-2'>
        {DROPS_FOR_SUBSCRIBE} {t('drops-2')}
      </h1>
      <h1 className='text-xl text-white font-bold text-center px-2'>{t('subscribe-to-ton-punks')}</h1>
      <h1 className='text-lg text-white font-semibold '>
        {t('you-claimed')} {(user?.t_twitter_claim ? 1 : 0) + (user?.t_telegram_claim ? 1 : 0)}/2
      </h1>

      <div className='flex flex-row gap-2 items-center mt-2 w-full'>
        <Button
          text={'Go to X ' + (user?.t_twitter_claim ? '✅' : '')}
          onClick={() => {
            if (!user?.t_twitter_claim) {
              startVerifying()
              setTimeout(() => {
                // toastSuccess('Subscribed')
                server.auth.subscribeToSocial('twitter')

                setVerifying(false)
              }, 4000)
            }
            let url = 'https://twitter.com/TonPunks'
            window.open(url, '_blank')
          }}
        />
        <Button
          text={'Go to Telegram ' + (user?.t_telegram_claim ? '✅' : '')}
          onClick={() => {
            if (!user?.t_telegram_claim) {
              startVerifying()
              setTimeout(() => {
                // toastSuccess('Subscribed')
                server.auth.subscribeToSocial('telegram')

                setVerifying(false)
              }, 5000)
            }

            let url = 'https://t.me/tonpunks'
            window.open(url, '_blank')
          }}
        />
      </div>
      {verifying && (
        <div className='flex flex-row w-full h-full justify-center text-white font-semibold'>
          Verifying...
          <span className='loader' />
        </div>
      )}
    </Panel>
  )
}
