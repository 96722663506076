import { useState } from "react";
import { useTranslation } from "i18nano";
import { inputShadow } from "../utils";
import server from "../../../logic/server";
import { usePageStore } from "../../../stores/pageStore";
import { useNavigate } from "react-router-dom";

export function FindOrCreateInput() {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const t = useTranslation();

  return (
    <>
      {error.length ? (
        <div className="text-[#dd0000] text-sm mb-2">{error}</div>
      ) : null}
      <div
        className="w-full h-14 bg-purple-bg bg-opacity-10 rounded-3xl flex items-center justify-between p-4 mb-3"
        style={{
          boxShadow: inputShadow,
        }}
      >
        <input
          type="text"
          placeholder="@channel_name"
          value={username}
          onChange={(e) => {
            setError("");
            setUsername(e.target.value);
          }}
          className="w-full h-full focus:outline-none placeholder-[#ddd] text-lg font-bold text-white"
          style={{
            background: "none",
          }}
        />
        <button
          className="h-8 w-full bg-white rounded-full flex items-center justify-center font-medium shadow-md text-md text-grey-800 bg-opacity-80"
          onClick={() => {
            if (!username) {
              setError("Username is required");
              return;
            }
            setLoading(true);
            server.pools.getOrCreatePool(username).then((pool) => {
              setLoading(false);

              if (!pool) {
                usePageStore.getState().setPage("pool-need-admin");
                return;
              }

              navigate(`/pool/${pool.id}`);
            });
          }}
        >
          {loading ? (
            <span
              className="loader"
              style={{
                height: 14,
                width: 14,
                background: "#ccc",
              }}
            />
          ) : (
            t("find-or-create")
          )}
        </button>
      </div>
    </>
  );
}
