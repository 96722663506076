import { BlurBackground } from "./BlurBackround";
import { Button } from "./Button";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useEffect, useState } from "react";

import { useTranslation } from "i18nano";
import { usePageStore } from "../../stores/pageStore";
import { useUserStore } from "../../stores/userStore";
import server from "../../logic/server";
import { boxShadowDefault } from "./utils";
import { dropsProposals } from "../../logic/game/market";
import { toastError } from "../../logic/toast";
import {
  preparePunkMessage,
  prepareTonMessage,
} from "../../logic/utils/prepareTonMessage";
import {
  DESTINATION_ADDRESS,
  DESTINATION_CLAIM_ADDRESS,
} from "../../logic/tonApi";

export function BottomWallet() {
  const setBottomWalletPay = usePageStore((state) => state.setBottomWalletPay);
  const bottonWalletPay = usePageStore((state) => state.bottomWalletPay);
  const payWallet = useUserStore((state) => state.payWallet);
  const setPayWallet = useUserStore((state) => state.setPayWallet);
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const t = useTranslation();

  return (
    <>
      <BlurBackground
        level={30}
        onClose={() => setBottomWalletPay(undefined)}
      />
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-md rounded-t-3xl p-4 flex flex-col items-center z-30 bg-white bg-opacity-30">
        <button
          className="absolute right-5 top-5"
          onClick={() => setBottomWalletPay(undefined)}
        >
          <img
            src="/img/close.svg"
            alt="close"
            className="w-4 h-4 object-contain"
          />
        </button>
        <h1 className="text-white font-bold text-xl">{t("buy-drops")}</h1>
        {!payWallet && bottonWalletPay?.type !== "STARS" ? (
          <>
            <WalletButton
              type="tonconnect"
              styles="mt-3"
              onClick={() => {
                if (wallet?.account?.address) {
                  setPayWallet({
                    wallet: "TONCONNECT",
                    address: wallet.account.address,
                  });
                } else {
                  if (tonConnectUI?.wallet?.account) tonConnectUI.disconnect();

                  server.auth.IMPORTANT_JUST_CONNECTED = true;
                  tonConnectUI.openModal();
                }
              }}
            />
            <WalletButton
              type="punkcity"
              styles="mb-5"
              onClick={() => {
                localStorage.setItem("PUNKCITY", "true");
                setPayWallet({ wallet: "PUNKCITY" });
              }}
            />
          </>
        ) : (
          <Wallet />
        )}
      </div>
    </>
  );
}

function Wallet() {
  const [isPending, setIsPending] = useState(false);
  const payWallet = useUserStore((state) => state.payWallet);
  const bottomWalletPay = usePageStore((state) => state.bottomWalletPay);
  const [tonC] = useTonConnectUI();
  const wallet = useTonWallet();
  const user = useUserStore((state) => state.user);
  const t = useTranslation();

  useEffect(() => {
    console.log("server.getPayWalletData()");
    if (bottomWalletPay?.type !== "STARS") {
      server.wallet.getPayWalletData();
    }
  }, []);

  return (bottomWalletPay?.type === "TON" &&
    payWallet?.tonBalance !== undefined) ||
    (bottomWalletPay?.type === "PUNK" &&
      payWallet?.punkBalance !== undefined) ||
    bottomWalletPay?.type === "STARS" ? (
    <div className="flex flex-col w-full p-4 items-center gap-2 bg-white bg-opacity-20 rounded-2xl mt-4">
      {bottomWalletPay.type !== "STARS" && (
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-row items-center gap-1">
            <img
              src="/img/wallet-icon.svg"
              alt="ton-icon"
              className="w-5 h-5 object-contain"
            />
            <p className="text-grey-600 font-bold">
              {getReadableAddress(payWallet?.address || "")}
            </p>
          </div>
          <button
            className=" bg-white bg-opacity-30 flex items-center justify-center rounded-full px-4 py-1"
            style={{
              boxShadow: boxShadowDefault,
            }}
            onClick={() => {
              server.auth.IMPORTANT_JUST_CONNECTED = false;
              if (tonC.wallet) tonC.disconnect();
              server.auth.disconnectAllWallets();
            }}
          >
            <p className="text-grey-600 font-bold">{t("disconnect")}</p>
          </button>
        </div>
      )}
      {bottomWalletPay.type !== "STARS" && (
        <div
          className="flex flex-col w-full bg-white bg-opacity-30 rounded-2xl py-3 px-4 mt-1"
          style={{
            boxShadow: boxShadowDefault,
          }}
        >
          <div className="flex flex-row items-center gap-1">
            <p className="text-grey-600 font-bold text-2xl">
              {bottomWalletPay.type === "PUNK"
                ? fixedNoRounding(payWallet?.punkBalance! / 1e9, true)
                : fixedNoRounding(payWallet?.tonBalance! / 1e9, true)}
            </p>
            <img
              src={`/img/${
                bottomWalletPay.type === "PUNK" ? "punk" : "ton"
              }-icon.svg`}
              className="w-6 h-6 object-contain"
            />
          </div>
          <p className="text-grey-600 font-semibold text-sm">{t("balance")}</p>
        </div>
      )}
      {bottomWalletPay.type === "TON" && payWallet?.wallet === "PUNKCITY" ? (
        <p className="text-white font-bold text-center">
          {"Punk City Wallet Is Only For $PUNK Payments"}
        </p>
      ) : (
        <>
          {isPending && (
            <p className="text-white font-bold text-center">
              {"Wait ≈1-5 minutes"}
            </p>
          )}
          {!isPending && (
            <Button
              isLoading={isPending || payWallet?.isPunkCityPaying}
              text={`${t("pay")} ${
                bottomWalletPay.type === "PUNK"
                  ? dropsProposals[bottomWalletPay.proposalId].punkPrice
                  : dropsProposals[bottomWalletPay.proposalId].tonPrice
              } `}
              icon={`/img/${
                bottomWalletPay.type === "PUNK" ? "punk" : "ton"
              }-icon.svg`}
              styles="mt-1"
              onClick={() => {
                const proposal = dropsProposals[bottomWalletPay.proposalId];

                if (payWallet?.isPunkCityPaying || isPending) return;

                if (payWallet?.wallet === "PUNKCITY") {
                  server.wallet.pay();
                } else {
                  const payload = user?.id + "";

                  const proposal = dropsProposals[bottomWalletPay.proposalId];

                  if (bottomWalletPay.type === "PUNK") {
                    try {
                      if (!payWallet?.jettonAddress) {
                        toastError("Jetton address not found");
                      }

                      tonC
                        .sendTransaction({
                          validUntil:
                            Math.floor(Date.now() / 1000) + 60 * 60 * 1,
                          messages: [
                            preparePunkMessage({
                              destinationAddress:
                                proposal.type === "daily-claim"
                                  ? DESTINATION_CLAIM_ADDRESS
                                  : DESTINATION_ADDRESS,
                              ownerAddress: wallet!.account.address,
                              jettonWalletAddress: payWallet?.jettonAddress!,
                              jettonAmount: proposal.punkPrice!,
                              payload,
                            }),
                          ],
                        })
                        .then(() => {
                          setIsPending(true);
                        })
                        .catch((e) => {
                          // server.sendError(e.toString())
                        });
                    } catch (e) {
                      toastError("Transaction failed");
                    }
                  } else {
                    // server.sendError(JSON.stringify(wallet))
                    tonC
                      .sendTransaction({
                        validUntil:
                          Math.floor(Date.now() / 1000) + 60 * 60 * 24,
                        messages: [
                          prepareTonMessage({
                            destinationAddress:
                              proposal.type === "daily-claim"
                                ? DESTINATION_CLAIM_ADDRESS
                                : DESTINATION_ADDRESS,
                            amount:
                              dropsProposals[bottomWalletPay.proposalId]
                                .tonPrice || 0,
                            payload,
                          }),
                        ],
                      })
                      .then(() => {
                        setIsPending(true);
                      })
                      .catch((e) => {
                        console.log(e);
                        // server.sendError(e.toString())
                        toastError("Transaction failed");
                      });
                  }
                }
              }}
            />
          )}
          {((bottomWalletPay.type === "TON" &&
            payWallet?.tonBalance! <
              (dropsProposals[bottomWalletPay.proposalId].tonPrice || 0) *
                1e9) ||
            (bottomWalletPay.type === "PUNK" &&
              payWallet?.punkBalance! <
                dropsProposals[bottomWalletPay.proposalId].punkPrice! *
                  1e9)) && (
            <p className="text-red font-semibold text-center">
              {t("insufficient-funds")}
            </p>
          )}
        </>
      )}
    </div>
  ) : (
    <div className="flex min-h-32 justify-center items-center">
      <span className="loader"></span>
      <p className="text-white font-bold ml-2">{t("loading")}...</p>
    </div>
  );
}

interface WalletProps {
  type: "punkcity" | "tonconnect";
  styles?: string;
  onClick?: () => void;
}

const punkCityText = "Connect Punk City Wallet";
const tonConnectText = "TON Connect";

function WalletButton(props: WalletProps) {
  const { styles } = props;
  return (
    <button
      className={`w-full h-14 bg-white bg-opacity-10 flex flex-row items-center justify-between gap-1 rounded-2xl mt-2 px-2 ${styles}`}
      style={{
        boxShadow: boxShadowDefault,
      }}
      onClick={props.onClick}
    >
      <div className="flex flex-row items-center gap-1">
        <img
          src={
            props.type === "punkcity"
              ? "/img/punkcity.png"
              : "/img/ton-connect.png"
          }
          alt="ton-icon"
          className="w-10 h-10 object-contain"
        />
        <p className="text-white font-bold ml-1">
          {props.type === "punkcity" ? punkCityText : tonConnectText}
        </p>
      </div>
      <img
        src={"/img/right-arrow.svg"}
        alt="ton-icon"
        className="w-5 h-5 object-contain"
      />
    </button>
  );
}

function getReadableAddress(address: string) {
  if (!address) return "";
  return address.slice(0, 7) + "..." + address.slice(-5);
}

export function fixedNoRounding(num: number, notAddZeroes?: boolean) {
  if (!num) return "0.00";
  let test = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)![0];

  // also add .00 if there is no decimal
  if (test.indexOf(".") == -1 && notAddZeroes) {
    test += ".00";
  }

  return test;
}
