import { create } from 'zustand'
import { Cube } from '../logic/game/cubes'

interface BlocksStore {
  blocks: Cube[]
  dropsAmount?: number
  isPressed: boolean
  isLowEnergy: boolean
  setIsLowEnergy: (n: boolean) => void
  addBlock: (n: Cube) => void
  removeFirst: () => void
  setBlocks: (n: Cube[]) => void
  setDropsAmount: (n: number) => void
  setIsPressed: (n: boolean) => void
}

export const useBlocksStore = create<BlocksStore>((set) => ({
  blocks: [],
  isPressed: false,
  isLowEnergy: false,
  setIsLowEnergy: (n) => set({ isLowEnergy: n }),
  setBlocks: (n) => set({ blocks: n }),
  addBlock: (n) => set((state) => ({ blocks: [...state.blocks, n] })),
  removeFirst: () => set((state) => ({ blocks: [...state.blocks.slice(1)] })),
  setDropsAmount: (n) => set({ dropsAmount: n }),
  setIsPressed: (n) => set({ isPressed: n }),
}))
