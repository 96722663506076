"use client";

import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import {
  OrbitControls,
  PerspectiveCamera,
  Stars,
  Stats,
  View as ViewImpl,
} from "@react-three/drei";
import Ocean from "./Ocean";
import { Blocks, blocksState } from "./Blocks";
import { Three } from "../../helpers/components/Three";
import { useBlocksStore } from "../../stores/blocksStore";
import { User } from "../../stores/userStore";
// import Objects from "./buildings/Objects";
// import Market from "./buildings/Market";
export const gameState = {
  isPaused: false,
};

interface CommonProps {
  user?: User;
}
export const Common = (props: CommonProps) => (
  <>
    {/* <Stats /> */}
    <ambientLight intensity={1.5} />
    <pointLight position={[10, 10, 10]} />
    <PerspectiveCamera
      makeDefault
      args={[55, window.innerWidth / window.innerHeight, 1, 20000]}
      position={[0, 40, 90]}
      rotation={[-Math.PI / 12, 0, 0]}
    />
    {/* <Market/> */}
    <Ocean />
    <Blocks />
  </>
);

const View = forwardRef(({ children, orbit, ...props }: any, ref) => {
  const localRef = useRef<any>(null);

  const isPressed = useBlocksStore((state) => state.isPressed);
  const setIsPressed = useBlocksStore((state) => state.setIsPressed);

  useImperativeHandle(ref, () => localRef.current);

  return (
    <>
      <div id="click" ref={localRef} {...props} />
      <Three>
        <ViewImpl track={localRef}>
          {children}
          <Stars radius={200} factor={10} count={500} fade={false} />
          {/* <OrbitControls /> */}
          {/* {process.e  nv.NODE_ENV === 'development' && <Stats />} */}
        </ViewImpl>
      </Three>
    </>
  );
});
View.displayName = "View";

export { View };
