import { unstable_batchedUpdates } from "react-dom";
import Server from "../server";
import { useUserStore } from "../../../stores/userStore";
import { toastError, toastSuccess } from "../../toast";
import { usePageStore } from "../../../stores/pageStore";
import tonApi from "../../tonApi";
import { dropsProposals } from "../../game/market";

class WalletService {
  server: Server;

  lastTonConnectRequest?: Date;

  constructor(server: Server) {
    this.server = server;
  }

  async getPayWalletData() {
    const payWallet = useUserStore.getState().payWallet;

    if (!payWallet) return;

    if (
      this.lastTonConnectRequest &&
      new Date().getTime() - this.lastTonConnectRequest.getTime() < 1000
    ) {
      return;
    }

    this.lastTonConnectRequest = new Date();

    if (payWallet.wallet === "PUNKCITY") {
      const data = await this.server.get(
        `/wallet?wallet=${payWallet.wallet}&address=${payWallet.address}`
      );

      if (!data) {
        toastError("Error Fetching Wallet");
        return;
      }

      unstable_batchedUpdates(() => {
        useUserStore.getState().setPayWallet({ ...payWallet, ...data });
      });
    } else {
      const bottomWalletPay = usePageStore.getState().bottomWalletPay;
      if (bottomWalletPay?.type === "PUNK") {
        if (!payWallet.address) return;
        const data = await tonApi.getPunkBalance(payWallet.address);
        let balance = 0;
        let address: string | null = null;
        if (data?.jetton_wallets?.length) {
          const wallet = data.jetton_wallets[0];
          balance = wallet.balance;
          address = wallet.address;
        }

        unstable_batchedUpdates(() => {
          useUserStore.getState().setPayWallet({
            ...payWallet,
            punkBalance: balance,
            jettonAddress: address || "",
          });
        });
      } else {
        if (!payWallet.address) return;
        const data = await tonApi.getTonBalance(payWallet.address);

        let balance = 0;
        if (data?.balance) {
          balance = data.balance;
        }

        unstable_batchedUpdates(() => {
          useUserStore
            .getState()
            .setPayWallet({ ...payWallet, tonBalance: balance });
        });
      }
    }
  }

  async createStarsInvoice(proposal_id: number) {
    const data = await this.server.post(`/wallet/pay`, {
      proposal_id,
      type: "STARS",
    });

    if (!data?.link) {
      toastError("Error Creating Invoice");
      return;
    }

    console.log("data", data);

    (window as any).Telegram?.WebApp?.openInvoice(data.link, (status: any) => {
      if (status === "paid") {
        toastSuccess("Payment Success");
        unstable_batchedUpdates(() => {
          const user = useUserStore.getState().user;
          if (!user) return;

          useUserStore.getState().setUser({
            ...user,
            drops_amount:
              parseInt(user?.drops_amount) +
              dropsProposals[proposal_id].drops +
              "",
          });
        });
      } else {
        toastError("Payment Failed");
      }
    });
    return data;
  }

  async pay() {
    const payWallet = useUserStore.getState().payWallet;
    const bottomWalletPay = usePageStore.getState().bottomWalletPay;
    if (!payWallet || !bottomWalletPay) return;

    unstable_batchedUpdates(() => {
      useUserStore
        .getState()
        .setPayWallet({ ...payWallet, isPunkCityPaying: true });
    });
    if (payWallet.wallet === "PUNKCITY") {
      const data = await this.server.post(`/wallet/pay`, {
        proposal_id: bottomWalletPay.proposalId,
        type: bottomWalletPay.type,
      });

      if (!data) {
        toastError("Paying Error");
        return;
      }

      toastSuccess("Paying Success");

      unstable_batchedUpdates(() => {
        const user = useUserStore.getState().user;
        if (user)
          useUserStore.getState().setUser({
            ...user,
            drops_amount: data.drops_amount,
          });
        useUserStore.getState().setPayWallet({
          ...payWallet,
          punkBalance: data.punkBalance,
          tonBalance: data.tonBalance,
          isPunkCityPaying: false,
        });
        // usePageStore.getState().setBottomWalletPay(undefined)
      });
    }
  }
}

export default WalletService;
