import { useMemo } from 'react'
import * as THREE from 'three'

const vs = `
varying vec3 vw;

void main() {
  vw = position;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

}
`

const fs = `
varying vec3 vw;
uniform vec3 topColor;
uniform vec3 bottomColor;
void main() {
    float h = normalize(vw).y;
    h = smoothstep(-0.2, 0.3, h);
    vec3 color = mix(bottomColor, topColor, max(h, 0.0));
    
    gl_FragColor = vec4(color, 1.0);
}
`

const moonVs = `
varying vec3 vw;
void main() {
  vw = position;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`

const moonFs = `
varying vec3 vw;
uniform vec3 topColor;
uniform float r;

void main() {
     float distanceToCenter = length(vw.xy);
  float glow = smoothstep(r * 0.9, r, distanceToCenter);
  vec3 color = mix(topColor, vec3(1.0, 1.0, 1.0), glow);
  gl_FragColor = vec4(color, 0.7 - glow * 0.7);
}
`

const nightBottomColor = new THREE.Color(0, 0, 0.1)
const nightColor = new THREE.Color(0.1, 0.2, 0.5)
const dayBottomColor = new THREE.Color('#f2d8ba')
const dayColor = new THREE.Color('#bcd5dd')
const isNight = new Date().getHours() >= 20 || new Date().getHours() < 6

export function Sky() {
  const sphereGeometry = useMemo(() => {
    return new THREE.SphereGeometry(1000, 32, 32)
  }, [])

  const moonGeometry = useMemo(() => {
    // make path for half moon
    const moonShape = new THREE.Shape()
    moonShape.moveTo(0, 0)
    // also make a hole
    moonShape.absarc(0, 0, 30, 0, Math.PI * 2, true)
    // create a geometry from the path
    const moonGeometry = new THREE.ShapeGeometry(moonShape)

    return moonGeometry
  }, [])

  const moonMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        topColor: { value: new THREE.Color(0xffffff) },
        r: { value: 6 },
      },
      vertexShader: moonVs,
      fragmentShader: moonFs,
      transparent: true,
    })
  }, [])

  const sphereMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        //vec3( 0.1, 0.5, 1.0 )
        topColor: { value: isNight ? nightColor : dayColor },
        bottomColor: { value: isNight ? nightBottomColor : dayBottomColor },
      },
      vertexShader: vs,
      fragmentShader: fs,
      side: THREE.BackSide,
    })
  }, [])

  return (
    <group>
      <mesh
        geometry={moonGeometry}
        material={moonMaterial}
        scale={1}
        rotation={[0, 0, -Math.PI / 4]}
        position={[80, 50, -400]}
      />
      <mesh geometry={sphereGeometry} material={sphereMaterial} />
    </group>
  )
}
