
import { useTranslation } from 'i18nano'
import { storageUrl } from '../../../logic/game/music';

export function PoolsOnboarding(props: { step: number; setStep: (step: number) => void }) {
  const { step, setStep } = props

  return (
    <>
      {step === 0 && <FirstOnboarding setStep={setStep} />}
      {step === 1 && <SecondOnboarding setStep={setStep} />}
    </>
  )
}

export function FirstOnboarding(props: { setStep: (step: number) => void }) {
  const t = useTranslation()

  return (
    <div className='flex flex-col w-full items-center gap-4 text-center'>
      <h1 className='text-2xl text-white font-bold mt-2'>{t('pool-welcome')}</h1>
      <h1 className='text-2xl text-white font-bold mt-2'>{'/(^o^)/'}</h1>
      <p className='text-white font-medium text-lg'>{t('pool-new-way')}</p>
      <div className='h-48 w-full bg-white bg-opacity-60 rounded-3xl p-5 shadow-lg'>
        <img
          src={`${storageUrl}/img/onboarding-1.png`}
          alt='onboarding-1'
          className='w-full h-full object-contain rounded-xl'
        />
      </div>
      <p className='text-white font-medium text-lg'>{t('pool-allow')}</p>
      <button
        className='w-48 mb-10 h-12 rounded-full font-bold text-xl bg-white text-white bg-opacity-30 mt-10'
        onClick={() => props.setStep(1)}
      >
        {t('next')}
      </button>
    </div>
  )
}

export function SecondOnboarding(props: { setStep: (step: number) => void }) {
  const t = useTranslation()

  return (
    <div className='flex flex-col w-full items-center gap-4 text-center'>
      <p className='text-white font-medium text-lg mt-2'>{t('pool-can')}</p>
      <div
        className='h-48 w-full p-2'
        style={{
          marginTop: -25,
          marginBottom: -25,
        }}
      >
        <img
          src={`${storageUrl}/img/onboarding-2.png`}
          alt='onboarding-1'
          className='w-full h-full object-contain rounded-xl'
        />
      </div>
      <p className='text-white font-medium text-lg'>{t('pool-each')}</p>
      <div className='w-full bg-white bg-opacity-60 rounded-3xl p-3 py-5 shadow-lg flex flex-col gap-2'>
        <SchemeRow left='15%' right={t('pool-s-1')} />
        <SchemeRow left='2.5%' right={t('pool-s-2')} />
        <SchemeRow left='82.5%' right={t('pool-s-3')} />
      </div>
      <button
        className='w-48 mb-10 h-12 rounded-full font-bold text-xl bg-white text-white bg-opacity-30 mt-2'
        onClick={() => props.setStep(2)}
      >
        {t('pool-got-it')}
      </button>
    </div>
  )
}
function SchemeRow(props: { left: string; right: string }) {
  return (
    <div className='flex flex-row items-center w-full gap-2'>
      <p className='text-grey-800 font-semibold text-xl w-20 text-center'>{props.left}</p>
      <p className='text-[#74728C] font-medium text-md w-full text-start'>{props.right}</p>
    </div>
  )
}
