import { toastError } from '../toast'

export function shareTelegramPool(userId: number, poolId: number, locale: string) {
  if (typeof window === 'undefined') return

  const refLink = getWebAppPoolLink(userId, poolId)

  let text = enPoolText
  if (locale === 'ru') {
    text = ruPoolText
  }

  const link = `https://t.me/share/url?url=${encodeURIComponent(refLink)}&text=${encodeURIComponent(text)}`

  ;(window as any).Telegram?.WebApp?.openTelegramLink(link)
}

function getRefLink(userId: number) {
  // make tg start=base64(userId)
  return `https://t.me/cubesonthewater_bot?start=${Buffer.from(userId.toString()).toString('base64')}`
}

export function getWebAppPoolLink(userId: number, poolId: number) {
  const url = 'https://t.me/cubesonthewater_bot/Cubes'

  const startParam = `${poolId}-from-${userId}`
  return `${url}?startapp=${encodeURIComponent(startParam)}&startApp=${encodeURIComponent(startParam)}`
}

async function getPoolIdAndUserFromStartParam() {
  const startParam = await (window as any).Telegram?.WebApp?.tgWebAppStartParam()

  if (!startParam) return

  const [poolId, userId] = startParam.split('-from-')

  return { poolId, userId }
}

const ruPoolText = `
❓Прив?ств?ю, Te?egram Серфер❗️

Ты м?жешь пр?соедин?ться к п?ти майнинга кубов, а мож?шь пройти м?мо этой сс?лки. Правд?, ты так и не узн?ешь что н?ход?тся вн?три Кубов❓
`

const enPoolText = `
❓Gre??tngs, Te?egram surfer❗️

You can jo?n to Cubes❓ m?n?ng jo?urney or you can sk?p ?t. But then you n?v?r g?t whats ins?de a Cube❓
`

const ruInviteText = `
❓Прис?един?йся к М?йнингу от TON Punks❗️
Узн?й что вн?три ?Куба???

Получить 200💧 бесплатно ↓
`

const enInviteText = `
❓Join Cubes? M?ning from TON Punks❗️
F?nd out what's ?ns?de a Cube???

Claim 200💧 for FREE ↓
`

export function shareInviteLink(userId: number, locale: string) {
  if (typeof window === 'undefined') return

  const refLink = getWebAppPoolLink(userId, 0)

  let text = enInviteText
  if (locale === 'ru') {
    text = ruInviteText
  }

  const link = `https://t.me/share/url?url=${encodeURIComponent(refLink)}&text=${encodeURIComponent(text)}`

  ;(window as any).Telegram?.WebApp?.openTelegramLink(link)
}
