import { TonConnectButton, useIsConnectionRestored, useTonConnectUI } from '@tonconnect/ui-react'
import { useBackendAuth } from './Auth'
import { useEffect } from 'react'
import server from '../../logic/server'

type ConnectRequest = {
  manifestUrl: string
  items: ConnectItem[] // data items to share with the app
}

// In the future we may add other personal items.
// Or, instead of the wallet address we may ask for per-service ID.
type ConnectItem = TonAddressItem | TonProofItem

type TonAddressItem = {
  name: 'ton_addr'
}
type TonProofItem = {
  name: 'ton_proof'
  payload: string // arbitrary payload, e.g. nonce + expiration timestamp.
}

interface Props {
  page: string
}
export function TonAuth(props: Props) {
  const { page } = props

  useBackendAuth()

  const isConnectionRestored = useIsConnectionRestored()
  const [tonConnectUI] = useTonConnectUI()

  useEffect(() => {
    if (!isConnectionRestored) return

    console.log('page', page, 'isConnectionRestored', isConnectionRestored, tonConnectUI.modalState.status)
    if (page !== 'auth') return

    if (tonConnectUI.modalState.status === 'closed' && !tonConnectUI.account?.address) {
      tonConnectUI.openModal()
      server.auth.IMPORTANT_JUST_CONNECTED = true
      return
    }
  }, [page, isConnectionRestored])

  return (
    page === 'auth' ? (
      <div className='absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center z-10'>
        <div className='p-10 rounded-3xl'>
          {/* <h1 className='text-2xl font-bold text-center text-white'>Sign in with TON</h1> */}
          <div className='flex items-center justify-center mt-4'>
            <TonConnectButton />
          </div>
        </div>
      </div>
    ) : null
  )
}
