import toast from 'react-hot-toast'

export function toastError(message: string) {
  toast.error(message, {
    duration: 2000,
    style: {
      background: '#333',
      borderRadius: '100px',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      boxShadow: 'inset 0 0 10px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(10px)',
    },
  })
}

export function toastSuccess(message: string) {
  toast.success(message, {
    duration: 2000,
    style: {
      background: '#333',
      borderRadius: '100px',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      boxShadow: 'inset 0 0 10px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(10px)',
    },
  })
}
