import { create } from 'zustand'

interface BottomWalletPay {
  proposalId: number
  type: 'PUNK' | 'TON' | 'STARS'
}

interface PageStore {
  page: string
  onboardingPage?: number
  bottomRestPay?: number
  isBottomRestLoading: boolean
  bottomWalletPay?: BottomWalletPay
  setBottomWalletPay: (n?: BottomWalletPay) => void
  setIsBottomRestLoading: (n: boolean) => void
  setBottomRestPay: (n?: number) => void
  setPage: (n?: string) => void
  setOnboardingPage: (n?: number) => void
}

export const usePageStore = create<PageStore>((set) => ({
  page: 'home',
  onboardingPage: undefined,
  bottomRestPay: undefined,
  isBottomRestLoading: false,
  bottomWalletPay: undefined,
  setBottomWalletPay: (n) => set({ bottomWalletPay: n }),
  setIsBottomRestLoading: (n) => set({ isBottomRestLoading: n }),
  setBottomRestPay: (n) => set({ bottomRestPay: n }),
  setPage: (n) => set({ page: n }),
  setOnboardingPage: (n) => set({ onboardingPage: n }),
}))
