import Server from './server'
import { Auth } from './services/Auth'
import Game from './services/Game'
import Pools from './services/Pools'
import Users from './services/Users'
import WalletService from './services/WalletService'

class ServerController {
  server: Server

  auth: Auth
  wallet: WalletService
  game: Game
  pools: Pools
  users: Users

  constructor() {
    this.server = new Server()

    this.auth = new Auth(this.server)
    this.wallet = new WalletService(this.server)
    this.game = new Game(this.server)
    this.pools = new Pools(this.server)
    this.users = new Users(this.server)
  }

  init() {
    this.auth.auth()
    this.game.init()
  }
}

export default new ServerController()
