export const PUNK_JETTON_ADDRESS = 'EQCdpz6QhJtDtm2s9-krV2ygl45Pwl-KJJCV1-XrP-Xuuxoq'
export const DESTINATION_ADDRESS = 'UQCtEy4bAxYrDZLetcP9wyAKL3MkgMxDoe_1BxLeo-1B9ABF'
export const DESTINATION_CLAIM_ADDRESS = 'UQB7isY1M7reJmnqW6bhXcwfBlyXb5TWVa6gRE_l1CQE_tzB'
class TonAPI {
  private endpointUrl = 'https://toncenter.com/api/v3'

  private async get(url: string) {
    return fetch(this.endpointUrl + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch(() => {
        console.log('Error')
      })
  }

  async getPunkBalance(address: string) {
    return await this.get(`/jetton/wallets?owner_address=${address}&jetton_address=${PUNK_JETTON_ADDRESS}`)
  }

  async getTonBalance(address: string) {
    return await this.get(`/wallet?address=${address}`)
  }
}

export default new TonAPI()
