export const USER_LEADERBOARD_LIMIT = 30

export const USER_LEAGUES: any = {
  0: {
    id: 0,
    name: 'Antimatter',
    texture: '/textures/texture-A.png',
    fromMined: 0,
  },
  1: {
    id: 1,
    name: 'Chlorophyll',
    fromMined: 500,
    texture: '/textures/texture-B.png',
  },
  2: {
    id: 2,
    name: 'Cyber Metal',
    fromMined: 5000,
    texture: '/textures/texture-C.png',
  },
  3: {
    id: 3,
    name: 'Lava',
    fromMined: 15000,
    texture: '/textures/texture-G.png',
  },
  4: {
    id: 4,
    name: 'Meteorite',
    fromMined: 50000,
    texture: '/textures/texture-E.png',
  },
  5: {
    id: 5,
    name: 'Moon Stone',
    fromMined: 500000,
    texture: '/textures/texture-D.png',
  },
}

export const getLeagueUser = (mined: number) => {
  let league = 0
  Object.keys(USER_LEAGUES).forEach((key) => {
    if (mined >= USER_LEAGUES[key].fromMined) {
      if (parseInt(key) > league) {
        league = parseInt(key)
      }
    }
  })

  return USER_LEAGUES[league]
}

export const USER_BOOSTS: any = {
  '-1': {
    id: -1,
    mul: 1.0,
    investments: 0,
  },
  '0': {
    id: 0,
    mul: 1.0125,
    investments: 2 * 1e4,
  },
  '1': {
    id: 1,
    mul: 1.025,
    investments: 5 * 1e4,
  },
  '2': {
    id: 2,
    mul: 1.05,
    investments: 25 * 1e4,
  },
  '3': {
    id: 3,
    mul: 1.15,
    investments: 5 * 1e5,
  },
  '4': {
    id: 4,
    mul: 1.3,
    investments: 1 * 1e6,
  },
  '5': {
    id: 5,
    mul: 1.7,
    investments: 2.5 * 1e6,
  },
  '6': {
    id: 6,
    mul: 2.0,
    investments: 1 * 1e7,
  },
  '7': {
    id: 7,
    mul: 2.5,
    investments: 2.5 * 1e7,
  },
  '8': {
    id: 8,
    mul: 3,
    investments: 1 * 1e8,
  },
  '9': {
    id: 9,
    mul: 4.0,
    investments: 1 * 1e9,
  },
}

export function getUserBoost(invested: number) {
  let boost = -1

  Object.keys(USER_BOOSTS).forEach((key) => {
    if (invested >= USER_BOOSTS[key].investments) {
      if (boost < parseInt(key)) boost = parseInt(key)
    }
  })

  return USER_BOOSTS[boost]
}
