interface Props {
  icon?: string
  text?: string
  styles?: string
  onClick?: () => void
  color?: string
  bgColor?: string
  isLoading?: boolean
  noPixelate?: boolean
  h?: number
  textSize?: string
}
export function Button(props: Props) {
  const { icon, text, styles, onClick, color, isLoading, bgColor, noPixelate, h, textSize } = props

  return (
    <button
      className={`text-md h-${h ? h : '12'} w-full rounded-full ${color || 'text-white'} bg-grey-800  font-semibold flex flex-row items-center justify-center gap-1.5 ${styles} shadow-md ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      style={{
        fontSize: textSize,
        backgroundColor: bgColor,
      }}
      onClick={onClick}
    >
      {!isLoading ? (
        <>
          {text}
          {icon && (
            <img
              src={icon}
              alt='ton-icon'
              className='w-5 h-5 object-contain rounded-sm'
              style={{
                imageRendering: noPixelate ? 'auto' : 'pixelated',
              }}
            />
          )}
        </>
      ) : (
        <>
          <span className='loader'></span>
        </>
      )}
    </button>
  )
}

export function GradientButton({ onClick, text, icon }: { onClick: () => void; text: string; icon?: string }) {
  return (
    <button
      className='text-md h-10 w-full rounded-full text-white bg-gradient-to-r from-[#275DF6] via-[#8B83F7] to-[#CBA5F9] font-semibold flex flex-row items-center justify-center gap-0.5 shadow-md cursor-pointer'
      style={{
        fontSize: '15px',
      }}
      onClick={onClick}
    >
      {text}
      {icon && (
        <img
          src={icon}
          alt='ton-icon'
          className='w-5 h-5 object-contain rounded-sm'
          style={{
            imageRendering: 'pixelated',
          }}
        />
      )}
    </button>
  )
}
