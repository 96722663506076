"use client";
import { unstable_batchedUpdates } from "react-dom";
import Server from "../server";
import toast from "react-hot-toast";
import { blocksState } from "../../../components/canvas/Blocks";
import { User, useUserStore } from "../../../stores/userStore";
import {
  energyPerSecond,
  getCubeByHash,
  maxEnergy,
  miningCostPerSec,
} from "../../game/cubes";
import { useBlocksStore } from "../../../stores/blocksStore";
import { usePageStore } from "../../../stores/pageStore";
import { toastError, toastSuccess } from "../../toast";
import { restProposals } from "../../game/market";

const cubesOnScene = 8;

class Game {
  private server: Server;

  interval?: any;

  serverCubeDuration: number = 0;

  isLowEnergy = false;

  constructor(server: Server) {
    this.server = server;

    this.init();
  }

  async init() {
    (window as any).telegramAnalytics?.init({
      token:
        "eyJhcHBfbmFtZSI6ImN1YmVzX25ldyIsImFwcF91cmwiOiJodHRwczovL3QubWUvY3ViZXNvbnRoZXdhdGVyX2JvdCIsImFwcF9kb21haW4iOiJodHRwczovL3d3dy50aGVjdWJlcy54eXovIn0=!RWpvEUuc3GiM+y2haEh1gg4VmvKXvxz60v3MgKpCuNo=",
      appName: "cubes_new",
    });

    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (blocksState.active) return;
      this.updateEnergy();
    }, 1000);

    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    const from = urlParams.get("from");
    if (ref) {
      localStorage.setItem("ref", ref);
    }
    if (from) {
      localStorage.setItem("from", from);
    }

    let durationLocalStorage = localStorage.getItem("serverCubeDuration");

    if (durationLocalStorage) {
      this.serverCubeDuration = parseInt(durationLocalStorage);
      blocksState.duration = this.serverCubeDuration;
    }

    (window as any).Telegram?.WebApp?.expand();
  }

  async mined() {
    const user = useUserStore.getState().user;
    if (!user) return;
    const currentCube = getCubeByHash(
      user.hash,
      parseInt(user.mined_count),
      user.mystery_ids
    );
    let mined_count = parseInt(user.mined_count) + 1 + "";
    let drops_amonut = currentCube.drops + parseInt(user.drops_amount) + "";
    let boxes_amount =
      parseInt(user.boxes_amount) +
      (user.pool_id ? 0 : currentCube.id === 8 ? 1 : 0) +
      "";
    let energy = parseInt(user.energy) - currentCube.energyCost + "";
    // let energy = parseInt(this.lastTrueUserState.energy) - currentCube.energyCost + ''
    // console.log('energy cost', currentCube.energyCost, 'energy', energy)

    // this.updateEnergy(energy)

    unstable_batchedUpdates(() => {
      useUserStore
        .getState()
        .setUser({
          ...user,
          mined_count,
          drops_amount: drops_amonut,
          boxes_amount,
          energy: energy,
        });

      blocksState.isAddingJustOne = true;
      const newCube = getCubeByHash(
        user.hash,
        parseInt(mined_count) + cubesOnScene - 1,
        user.mystery_ids
      );
      useBlocksStore.getState().addBlock(newCube);
      useBlocksStore.getState().setDropsAmount(currentCube.drops);
    });

    const startDate = new Date();
    const data = await this.server.post(`/game/mined`, {});

    if (data) {
      const pingTimeInMs = new Date().getTime() - startDate.getTime();
      unstable_batchedUpdates(() => {
        useUserStore.getState().setPing(pingTimeInMs);
      });
    }
    if (!data) {
      toast.error("Bad Network Connection", {
        duration: 2000,
        style: {
          background: "#333",
          borderRadius: "100px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          boxShadow:
            "inset 0 0 10px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)",
          backdropFilter: "blur(10px)",
        },
      });
      this.initializeCubes(this.server.lastTrueUserState);

      unstable_batchedUpdates(() => {
        useUserStore.getState().setUser(this.server.lastTrueUserState);
        useUserStore
          .getState()
          .setEnergy(parseInt(this.server.lastTrueUserState?.energy || "0"));
      });
      return;
    }

    unstable_batchedUpdates(() => {
      const user = useUserStore.getState().user;
      this.server.lastTrueUserState = { ...user, ...data };
      this.server.lastTrueEnergyUpdate = data.last_energy_update;
      useUserStore.getState().setUser({ ...user, ...data });
      // useUserStore.getState().setEnergy(parseInt(this.lastTrueUserState.energy))
      // this.addNewCube({ ...user, ...data })
    });
  }

  async endOnboarding() {
    const user = useUserStore.getState().user;
    if (!user) return;

    this.initializeCubes(user);

    const data = await this.server.post(`/game/onboarding`, {});

    if (data?.drops_amount) {
      unstable_batchedUpdates(() => {
        useUserStore.getState().setUser({ ...user, ...data });
      });
    }
  }

  async initializeCubes(user?: User) {
    if (!user) user = useUserStore.getState().user;
    if (!user) return;

    blocksState.isAddingJustOne = false;
    const blocks: any = [];
    let mined_count = parseInt(user.mined_count);
    let hash = user.hash;

    for (let i = 0; i < cubesOnScene; i++) {
      const block = getCubeByHash(hash, mined_count, user.mystery_ids);
      mined_count++;

      blocks.push(block);
    }

    unstable_batchedUpdates(() => {
      useBlocksStore.getState().setBlocks(blocks);
    });
  }

  updateEnergy(_energy?: string) {
    const user = useUserStore.getState().user;
    if (!user) return;

    let miningCost = Math.min(
      Math.floor(blocksState.duration * miningCostPerSec),
      100
    );

    const currentEnergy = parseInt(useUserStore.getState().user?.energy || "0");
    if (!currentEnergy) return;
    const timeSinceLastEnergyUpdate = Math.abs(
      new Date().getTime() -
        new Date(this.server.lastTrueEnergyUpdate || "0").getTime()
    );
    const energyToAdd =
      Math.floor(timeSinceLastEnergyUpdate / 1000) * energyPerSecond;

    let energy = currentEnergy;
    if (energy >= maxEnergy) {
    } else {
      energy = Math.min(currentEnergy + energyToAdd, maxEnergy);
    }

    energy -= miningCost;

    if (energy < 10) {
      this.isLowEnergy = true;
      this.banForLowEnergy();
    } else {
      this.isLowEnergy = false;
    }

    if (energy >= maxEnergy - miningCost && this.server.isBannedForLowEnergy) {
      this.server.isBannedForLowEnergy = false;

      unstable_batchedUpdates(() => {
        const user = useUserStore.getState().user;
        if (!user) return;
        useUserStore
          .getState()
          .setUser({ ...user, banned_until_restore: "false" });
      }, []);
    }

    this.updateLocalStorageDuration(blocksState.duration);
    useUserStore.getState().setEnergy(energy);
  }

  async banForLowEnergy() {
    if (this.server.isBannedForLowEnergy) return;

    this.server.isBannedForLowEnergy = true;

    const user = useUserStore.getState().user;

    unstable_batchedUpdates(() => {
      if (!user) return;
      useUserStore
        .getState()
        .setUser({ ...user, banned_until_restore: "true" });
    });

    const data = await this.server.post(`/game/ban-for-low-energy`, {});
  }

  updateLocalStorageDuration(duration: number) {
    localStorage.setItem("serverCubeDuration", duration + "");
  }

  async addNewCube(user: User) {
    let mined_count = parseInt(user.mined_count);
    mined_count += cubesOnScene - 1;
    const cube = getCubeByHash(user.hash, mined_count, user.mystery_ids);

    unstable_batchedUpdates(() => {
      useBlocksStore.getState().addBlock(cube);
    });
  }

  async buyRestProposal(proposal_id: number) {
    unstable_batchedUpdates(() => {
      usePageStore.getState().setIsBottomRestLoading(true);
    });
    const data = await this.server.post(`/game/rest-proposal/buy`, {
      proposal_id,
    });

    unstable_batchedUpdates(() => {
      usePageStore.getState().setIsBottomRestLoading(false);
    });

    if (!data) return;
    this.server.lastTrueUserState = {
      ...useUserStore.getState().user,
      ...data,
    };

    this.updateEnergy(data.energy);
    unstable_batchedUpdates(() => {
      useUserStore
        .getState()
        .setUser({ ...useUserStore.getState().user, ...data });
    });

    const proposal = restProposals[proposal_id];

    toastSuccess(`You restored ${proposal.energy} energy`);
  }

  async getAirdrop() {
    const data: { total: number } = await this.server.get(`/game/claim`);

    if (!data?.total === undefined) {
      toastError("Error Fetching Airdrop");
      return;
    }

    unstable_batchedUpdates(() => {
      useUserStore.getState().setAirdropClaim(data.total);
    });
  }

  async claimAirdrop() {
    const data = await this.server.post(`/game/claim`, {});

    if (!data) {
      toastError("Error Claiming Airdrop");
      return;
    }

    toastSuccess("Airdrop Claimed");
    unstable_batchedUpdates(() => {
      useUserStore.getState().setAirdropClaim(0);
    });
  }

  async setPoolStoryWatched() {
    // pool-story-watched
    const user = useUserStore.getState().user;
    unstable_batchedUpdates(() => {
      if (!user) return;
      useUserStore.getState().setUser({ ...user, is_pool_watched: true });
    });

    const data = await this.server.post(`/game/pool-story-watched`, {});

    if (!data) {
      toastError("Error Setting Pool Story Watched");
      return;
    }
  }
}

export default Game;
