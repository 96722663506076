import { useEffect, useState } from 'react'
import { usePageStore } from '../../../../stores/pageStore'
import { usePoolsStore } from '../../../../stores/poolsStore'
import { Panel } from '../../Panel'
import { poolPreviewUrl, sTelegramUrl } from '../../../../logic/game/music'
import { Button } from '../../Button'
import server from '../../../../logic/server'

export function PoolSettings() {
  const setPage = usePageStore((state) => state.setPage)
  const selected = usePoolsStore((state) => state.selectedPool)
  const [cheked, setCheked] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!selected) return

    setCheked(selected.needSubscribe)
  }, [selected])

  return (
    <Panel titleColor='grey-800' title={null} level={30} onClose={() => setPage(undefined)}>
      <img
        src={`${sTelegramUrl}/channels/${selected?.id}.jpg`}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = poolPreviewUrl
        }}
        alt='texture-A'
        className='w-16 h-16 object-contain rounded-md'
        style={{
          imageRendering: 'pixelated',
        }}
      />
      <div
        className='flex flex-row w-full justify-center items-center gap-4 mt-2'
        onClick={() => {
          setCheked((prev) => !prev)
        }}
      >
        <input type='checkbox' className='w-4 h-4' checked={cheked} onChange={() => setCheked((prev) => !prev)} />
        <p className='text-grey-800 text-md text-start font-medium w-full max-w-64' style={{ marginTop: -4 }}>
          Make subscribing to the channel mandatory for joining the pool
        </p>
      </div>
      <Button
        isLoading={isLoading}
        text='Done'
        styles='mt-2'
        onClick={() => {
          setIsLoading(true)
          server.pools.updateSettings(selected!.id, cheked).then(() => {
            setIsLoading(false)
            setPage(undefined)
          })
        }}
      />
    </Panel>
  )
}
