import { useEffect, useRef } from "react";
import Scene from "../canvas/Scene";
import Main from "../game-ui/Main";
import { TranslationProvider } from "i18nano";
import { storageUrl } from "../../logic/game/music";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

const translations = {
  en: () => import("../../logic/translations/en.json"),
  ru: () => import("../../logic/translations/ru.json"),
};

export default function Layout({ children }: any) {
  const ref = useRef(null);
  useEffect(() => {}, []);

  return (
    <TranslationProvider translations={translations} language="en">
      <TonConnectUIProvider
        manifestUrl={`${storageUrl}/manifests/manifest.json`}
      >
        <div
          ref={ref}
          style={{
            position: "absolute",
            width: " 100%",
            height: "100%",
            overflow: "hidden",
            touchAction: "auto",
          }}
        >
          {children}
          <Scene
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              pointerEvents: "none",
            }}
            eventSource={ref}
            eventPrefix="client"
          />
        </div>
      </TonConnectUIProvider>
    </TranslationProvider>
  );
}
