"use client";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { useEffect, useMemo, useRef, useState } from "react";
import { Line, useCursor, MeshDistortMaterial } from "@react-three/drei";
import { useSpring, animated } from "@react-spring/three";
import { create, useStore } from "zustand";
import Axe from "./Axe";
import { Drops } from "./Drops";
import {
  createCubeFromTexture,
  createObjectFromTexture,
  createOptimizedCubeFromTexture,
  updateCubeStatus,
  updatePixels,
} from "./utils/createObjectFromTexture";
import { gameState } from "./View";
import { useBlocksStore } from "../../stores/blocksStore";
import { useUserStore } from "../../stores/userStore";
import { usePageStore } from "../../stores/pageStore";
import { audioStatus } from "../../helpers/audio";
import { soundBuffers } from "../../logic/game/music";
import { Cube } from "../../logic/game/cubes";
import server from "../../logic/server";

// Parameters
export const miningBlockPosition = new THREE.Vector3(0, 25, 0);
export const blockSize = 20 * 1.03;
export const hitTime = 390;

// Inside Component Parameters
let groupOffset = 0;

let waitingForReclick = false;

export const imgSize = 16;
const textureNames = ["A", "B", "C", "D", "E", "F", "G", "H", "question"];
const imagesList = textureNames.map((name) => `/textures/texture-${name}.png`);
const imagesLength = imagesList.length;
const imagesData: any = {};
let imagesLoadedGlobal = 0;

// State for rapid updates from other use frames
export const blocksState = {
  duration: 0,
  totalDuration: 0,
  previousUpdate: 0,
  currentTexture: null,
  active: false,
  cubeGlobalDuration: 0,

  pixels1: {},

  isAddingJustOne: false,
  isRemovingFirst: false,

  isReadyToMine: false,
};

export interface BlockPixel {
  direction: number[];
  position: number[];
  time: number;
  id: number;
}

export const Blocks = () => {
  const light = useRef(null); // Reference for the point light

  const active = useBlocksStore((state) => state.isPressed);
  const setIsPressed = useBlocksStore((state) => state.setIsPressed);

  const user = useUserStore((state) => state.user);

  const apiBlocks = useBlocksStore((state) => state.blocks);
  const apiRemoveFirst = useBlocksStore((state) => state.removeFirst);

  const onboardingPage = usePageStore((state) => state.onboardingPage);
  const setOnboardingPage = usePageStore((state) => state.setOnboardingPage);

  const [sceneCube, setSceneCube] = useState<THREE.InstancedMesh | null>(null);
  const [otherCubes, setOtherCubes] = useState<THREE.InstancedMesh[]>([]);

  const [isImagesLoaded, setIsImagesLoaded] = useState(false);

  const [currentCubeStatus, setCurrentCubeStatus] = useState(0);

  const [blockId, setBlockId] = useState<null | number>(null);

  const listener = useMemo(() => new THREE.AudioListener(), []);
  const sound1 = useMemo(() => new THREE.Audio(listener), []);

  const [groupSpring, api] = useSpring(
    () => ({
      position: [0, 0, 0],
      config: (key) => {
        switch (key) {
          case "position":
            return { mass: 0.01, friction: 10 };
          default:
            return {};
        }
      },
    }),
    []
  );

  const newBoxGeometry = useMemo(() => {
    return new THREE.BoxGeometry(1, 1, 1);
  }, []);
  const newMeshLambertMaterial = useMemo(() => {
    return new THREE.MeshLambertMaterial();
  }, []);
  const newInstancedMesh = useMemo(() => {
    return new THREE.InstancedMesh(
      newBoxGeometry,
      newMeshLambertMaterial,
      imgSize * imgSize * imgSize
    );
  }, []);
  const optimizedInstancedMesh = useMemo(() => {
    return new THREE.InstancedMesh(
      newBoxGeometry,
      newMeshLambertMaterial,
      imgSize * imgSize * 3
    );
  }, []);
  const mat1 = useMemo(() => {
    return new THREE.Matrix4();
  }, []);
  const col = useMemo(() => {
    return new THREE.Color();
  }, []);

  useEffect(() => {
    if (imagesLoadedGlobal === imagesLength && imagesData[textureNames[0]]) {
      setIsImagesLoaded(true);
    } else {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = imgSize;
      canvas.height = imgSize;

      if (!ctx) return;
      imagesList.forEach((image, index) => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
          const data = ctx.getImageData(0, 0, imgSize, imgSize);

          imagesData[textureNames[index]] = data;
          imagesLoadedGlobal++;
          if (imagesLoadedGlobal === imagesLength) {
            setIsImagesLoaded(true);
          }
        };
      });
    }
  }, []);

  const playSound = (_blockId: number) => {
    const buffer = soundBuffers[blockId + "-crash"];
    if (!buffer) return;

    if (audioStatus.isMuted) return;

    sound1.stop();
    sound1.setBuffer(buffer);
    sound1.setLoop(false);
    sound1.setVolume(0.1);
    sound1.play();
  };

  useEffect(() => {
    // get current cube
    if (!sceneCube) return;

    updateCubeStatus(sceneCube, currentCubeStatus, mat1);
  }, [currentCubeStatus]);

  useEffect(() => {
    if (!apiBlocks.length || !isImagesLoaded) return;

    // if (blocksState.isAddingJustOne) {
    //   const obj = newInstancedMesh.clone()
    //   const blockImageData = imagesData[textureNames[apiBlocks[apiBlocks.length - 1].id % imagesLength]]
    //   createCubeFromTexture(mat1, obj, blockImageData, col)
    //   setSceneCubes((prev) => [...prev, obj])
    //   blocksState.isAddingJustOne = false
    //   return
    // }

    let newSceneCube,
      otherSceneCubes: any[] = [];

    apiBlocks.forEach((block: Cube, index) => {
      if (index === 0) {
        const obj = newInstancedMesh.clone();

        const blockImageData =
          imagesData[textureNames[block.id % imagesLength]];

        createCubeFromTexture(mat1, obj, blockImageData, col);

        setBlockId(block.id);

        newSceneCube = obj;
      } else {
        const obj = optimizedInstancedMesh.clone();

        const blockImageData =
          imagesData[textureNames[block.id % imagesLength]];

        createOptimizedCubeFromTexture(mat1, obj, blockImageData, col);

        otherSceneCubes.push(obj);
      }
    });

    blocksState.pixels1 = {
      block: newSceneCube,
    };

    setSceneCube(newSceneCube || null);
    setOtherCubes(otherSceneCubes);
    setCurrentCubeStatus(
      Math.floor((blocksState.duration / apiBlocks[0].time) * 100)
    );
  }, [apiBlocks, isImagesLoaded]);

  const handleStart = () => {
    setIsPressed(true);
    blocksState.previousUpdate = -1;
    blocksState.active = true;
  };

  const handleEnd = () => {
    blocksState.active = false;
    setIsPressed(false);
  };

  useFrame((state, delta) => {
    if (gameState.isPaused) return;
    updatePixels(blocksState.pixels1, mat1, delta);

    if (blocksState.isReadyToMine) {
      const div: any = document.getElementById("click");
      // getComputedStyle(div)
      const style = getComputedStyle(div);
      if (!server.server.isBannedForLowEnergy) {
        if (style.getPropertyValue("cursor") == "default") {
          if (waitingForReclick) return;
          if (!active) handleStart();
        } else {
          waitingForReclick = false;
          if (blocksState.active) handleEnd();
        }
      } else {
        handleEnd();
      }
    }
    if (blocksState.active) {
      if (server.game.isLowEnergy) {
        handleEnd();
        return;
      }

      blocksState.duration += delta;

      if (
        Math.abs(blocksState.previousUpdate - blocksState.duration) >=
        hitTime / 1000
      ) {
        setCurrentCubeStatus(
          Math.floor((blocksState.duration / apiBlocks[0].time) * 100)
        );
        server.game.updateEnergy();
        blocksState.previousUpdate = blocksState.duration;
      }

      if (onboardingPage && onboardingPage > 2) return;
      if (blocksState.duration > apiBlocks[0].time) {
        blocksState.duration = 0;
        blocksState.previousUpdate = -1;
        groupOffset += blockSize;
        // blocksState.isRemovingFirst = true
        apiRemoveFirst();
        if (blockId) playSound(blockId);
        api.start({ position: [0, 0, groupOffset] });
        (window as any).Telegram?.WebApp?.HapticFeedback?.impactOccurred(
          "soft"
        );

        if (onboardingPage) {
          if (onboardingPage === 1) {
            server.game.endOnboarding();
            waitingForReclick = true;
            setOnboardingPage(undefined);
            handleEnd();
          }
          return;
        }
        server.game.mined();
      }
    }
  });

  return (
    <group visible={user ? true : false}>
      <pointLight
        ref={light}
        position={[0, 25, 35]}
        intensity={500}
        color="white"
      />
      <animated.group
        position={groupSpring.position.to((x, y, z) => [x, y, z])}
      >
        {sceneCube && (
          <primitive
            object={sceneCube}
            position={[0, 25, 0 - blockSize * 0 - groupOffset]}
            scale={[1.3, 1.3, 1.3]}
            rotation={[0, 0, -Math.PI / 2]}
          />
        )}
        {otherCubes.map((cube, index) => (
          <primitive
            key={index}
            object={cube}
            scale={[1.3, 1.3, 1.3]}
            rotation={[0, 0, -Math.PI / 2]}
            position={[0, 25, 0 - blockSize * (index + 1) - groupOffset]}
          />
        ))}
      </animated.group>
      <Axe active={active} blockId={blockId} />
      <Drops />
    </group>
  );
};
