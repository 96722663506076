import { restProposals } from "../../logic/game/market";
import server from "../../logic/server";
import { toastError } from "../../logic/toast";
import { usePageStore } from "../../stores/pageStore";
import { useUserStore } from "../../stores/userStore";
import { BlurBackground } from "./BlurBackround";
import { Button } from "./Button";
import { useTranslation } from "i18nano";

export function BottomRestPay() {
  const isConnected = false;

  const user = useUserStore((state) => state.user);
  const bottomRestPay = usePageStore((state) => state.bottomRestPay);
  const setBottomRestPay = usePageStore((state) => state.setBottomRestPay);
  const isBottomRestLoading = usePageStore(
    (state) => state.isBottomRestLoading
  );
  const t = useTranslation();
  const energy = useUserStore((state) => state.energy);

  return bottomRestPay === undefined ? null : (
    <>
      <BlurBackground
        level={30}
        onClose={() => {
          setBottomRestPay(undefined);
        }}
      />
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full max-w-md rounded-t-3xl p-4 flex flex-col items-center z-30 bg-white bg-opacity-30">
        <button
          className="absolute right-5 top-5"
          onClick={() => {
            setBottomRestPay(undefined);
          }}
        >
          <img
            src="img/close.svg"
            alt="close"
            className="w-4 h-4 object-contain"
          />
        </button>
        <h1 className="text-white font-bold text-xl">{t("restore-energy")}</h1>
        <div className="flex flex-col w-full p-4 items-center gap-2 bg-white bg-opacity-60 rounded-2xl mt-4">
          <div className="flex flex-row w-full justify-center gap-4">
            {[...Array(3)].map((_, index) => (
              <img
                src="/img/restore-lightning.png"
                alt="box-icon"
                className="w-20 h-20 object-contain"
                key={index}
              />
            ))}
          </div>
          <div className="flex flex-row text-xl font-semibold text-grey-600 items-center gap-2">
            <p>{t("you-get")}</p>
            <img
              src="/img/bolt-icon.png"
              alt="box-icon"
              className="w-6 h-6 object-contain"
              style={{
                filter: "invert(0.8)",
              }}
            />
            <p className="text-3xl font-bold">
              {restProposals[bottomRestPay].energy}
            </p>
          </div>
          <Button
            text={t("pay") + " " + restProposals[bottomRestPay].dropsPrice}
            icon="/img/drop-icon.png"
            styles="mt-2"
            isLoading={isBottomRestLoading}
            onClick={() => {
              if (parseInt(user?.energy || "0") >= 2000) {
                toastError("Max energy is 2000");
                return;
              }

              if (
                !isNaN(parseInt(user?.drops_amount || "0")) &&
                parseInt(user?.drops_amount || "0") >=
                  restProposals[bottomRestPay].dropsPrice
              ) {
                if (bottomRestPay === undefined) return;
                server.game.buyRestProposal(bottomRestPay);
              } else {
                toastError("Not enough balance");
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
