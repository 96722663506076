import { unstable_batchedUpdates } from "react-dom";
import Server from "../server";
import { Wallet } from "@tonconnect/ui-react";
import server from "..";
import { User, useUserStore } from "../../../stores/userStore";
import { getLeagueUser } from "../../game/users";
import { usePageStore } from "../../../stores/pageStore";
import { DROPS_FOR_SUBSCRIBE } from "../../game/market";

export class Auth {
  private server: Server;
  private telegramInitData?: string;

  IMPORTANT_JUST_CONNECTED = false;

  constructor(server: Server) {
    this.server = server;
  }

  async auth() {
    console.log("V 1.5.0");
    await this.setTelegramData();

    let user: User | null = null;

    this.server.token = localStorage.getItem("token") || undefined;
    if (this.server.token) {
      user = await this.server.post(`/auth/token`, {});
    }

    if (user?.id) {
      this.updateUser(user);
      return;
    } else {
      if (this.server.token) {
        unstable_batchedUpdates(() => {
          useUserStore.getState().setShouldDisconnectWallet(true);
        });
      }
    }

    if (!this.telegramInitData) return console.log("No telegram data");

    const refData = await this.getRefFromStartParam();

    user = await this.server.post(`/auth`, {
      initData: this.telegramInitData,
      newRefData: refData,
    });

    if (!user?.token || !user.id) {
      return;
    }
    this.updateUser(user);
  }

  updateUser(user: User, conf?: { shouldDisconnectWallet?: boolean }) {
    if (!user) return;
    unstable_batchedUpdates(() => {
      this.server.lastTrueUserState = user;
      this.server.lastTrueEnergyUpdate = user.last_energy_update;
      useUserStore.getState().setUser(user);
      useUserStore.getState().setEnergy(parseInt(user.energy));

      const league = getLeagueUser(parseInt(user.mined_count));
      useUserStore.getState().setLeague(league.id);

      if (user.banned_until_restore === "true") {
        this.server.isBannedForLowEnergy = true;
      }

      if (conf?.shouldDisconnectWallet === false) {
        useUserStore.getState().setShouldDisconnectWallet(false);
      }

      if (!user.onboarding_done) {
        usePageStore.getState().setOnboardingPage(1);
      }
    });

    if (user.onboarding_done) {
      // this.initializeCubes(user)
    }

    if (user.token) {
      this.server.token = user.token;
      if (user.pool_id)
        server.pools.getSelectedPool(parseInt(user.pool_id), true);
      server.users.getMe();
    }
  }

  async disconnectAllWallets() {
    unstable_batchedUpdates(() => {
      useUserStore.getState().setPayWallet(undefined);
    });
  }

  async walletDisconnected() {
    const isFromTelegram = useUserStore.getState().isFromTelegram;
    if (isFromTelegram) {
      unstable_batchedUpdates(() => {
        useUserStore.getState().setPayWallet(undefined);
      });

      return;
    }

    if (localStorage.getItem("token") === null) {
      return;
    }

    unstable_batchedUpdates(() => {
      useUserStore.getState().setShouldDisconnectWallet(true);
      useUserStore.getState().setUser(undefined);
    });

    localStorage.removeItem("token");

    if (typeof window !== "undefined") {
      window.location.reload();
    }
  }

  async mergeWithTG(wallet: Wallet) {
    const user = await this.server.post(`/auth/merge`, { walletInfo: wallet });

    if (!user) {
      console.log("merge error");
      return;
    }

    unstable_batchedUpdates(() => {
      const oldUser = useUserStore.getState().user;
      if (!oldUser) return;

      this.updateUser({
        ...oldUser,
        boxes_amount: user.boxes_amount,
        drops_amount: user.drops_amount,
      });
    });
  }

  async tonAuth(wallet: Wallet) {
    const isFromTelegram = useUserStore.getState().isFromTelegram;
    if (isFromTelegram) {
      return;
    }

    const ref = localStorage.getItem("ref");
    const from = localStorage.getItem("from");

    const user = await this.server.post(`/auth/ton`, {
      walletInfo: wallet,
      ref,
      from,
    });

    if (!user) return console.error("No user data");

    this.IMPORTANT_JUST_CONNECTED = true;
    if (user.token) {
      localStorage.setItem("token", user.token);
    }

    unstable_batchedUpdates(() => {
      this.updateUser(user, { shouldDisconnectWallet: false });
    });
  }

  async generatePayload() {
    const data = await this.server.get(`/auth/generate-payload`);

    if (!data?.tonProof) return;

    return data;
  }

  async setTelegramData() {
    if (typeof window !== "undefined") {
      let initData = await (window as any)?.Telegram?.WebApp?.initData;

      if (!initData) {
        const urlParams = new URLSearchParams(window.location.search);
        const test = urlParams.get("test");
        if (this.server.isDev) {
          if (!test) initData = "TEST_INIT_DATA";
        }
      }

      if (!initData) {
        unstable_batchedUpdates(() => {
          useUserStore.getState().setFromTelegram(false);
        });
      } else {
        unstable_batchedUpdates(() => {
          useUserStore.getState().setFromTelegram(true);
        });
      }

      this.telegramInitData = initData;
    }
  }

  async subscribeToSocial(type: "telegram" | "twitter") {
    const data = await this.server.post(`/auth/trustmebro`, { type });

    if (!data || !data.ok) return;

    unstable_batchedUpdates(() => {
      const user = useUserStore.getState().user;
      if (!user) return;

      let t_twitter_claim = user.t_twitter_claim;
      let t_telegram_claim = user.t_telegram_claim;
      if (type === "telegram") t_telegram_claim = true;
      if (type === "twitter") t_twitter_claim = true;

      useUserStore.getState().setUser({
        ...user,
        drops_amount: parseInt(user.drops_amount) + DROPS_FOR_SUBSCRIBE + "",
        t_twitter_claim,
        t_telegram_claim,
      });
    });
  }

  async getRefFromStartParam() {
    if (this.server.isDev)
      return {
        ref: 21,
        refPoolId: 51,
      };

    if (typeof window === "undefined") return;

    const initData = await (window as any).Telegram?.WebApp?.initDataUnsafe;

    const start_param = initData?.start_param;

    if (start_param) {
      // poolId-from-userId
      // split by "-from-"
      const [poolId, from] = start_param.split("-from-");

      let ref = parseInt(from);
      let refPoolId = parseInt(poolId);

      if (isNaN(ref)) return;

      return {
        ref,
        refPoolId,
      };
    }

    return null;
  }
}
