import { useTranslation } from 'i18nano'
import { useState } from 'react'
import { usePageStore } from '../../../../stores/pageStore'
import { Panel } from '../../Panel'
import { Button } from '../../Button'
import server from '../../../../logic/server'

export function NeedToVerify() {
  const setPage = usePageStore((state) => state.setPage)
  const [isLoading, setIsLoading] = useState(false)
  const t = useTranslation()
  return (
    <Panel titleColor='grey-800' title={null} level={30} onClose={() => setPage(undefined)}>
      <img
        src='/textures/texture-question.png'
        alt='texture-A'
        className='w-16 h-16 object-contain rounded-md'
        style={{
          imageRendering: 'pixelated',
        }}
      />
      <h1 className='text-grey-800 font-bold text-xl text-center mt-2'>{t('verify-now')}</h1>
      <p className='text-grey-800 text-md text-center text-center font-medium' style={{ marginTop: -4 }}>
        {t('verify-desc')}
      </p>
      <Button
        text={t('verify-now-button')}
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true)
          server.pools.verify().then(() => {
            setIsLoading(false)
          })
        }}
        styles='mt-2 mb-2'
      />
    </Panel>
  )
}
