import { LeaderboardUser, useUserStore } from '../../../stores/userStore'
import { USER_LEADERBOARD_LIMIT } from '../../game/users'
import Server from '../server'
import { unstable_batchedUpdates } from 'react-dom'

class Users {
  private server: Server

  constructor(server: Server) {
    this.server = server
  }

  async getMe() {
    const data: { user: LeaderboardUser } = await this.server.get('/users/me')

    if (!data?.user) return

    unstable_batchedUpdates(() => {
      useUserStore.getState().setMe(data.user)
    })
  }

  async getUserLeaderboard() {
    const league = useUserStore.getState().league
    let status = useUserStore.getState().userStatus

    if (!status)
      status = {
        isLoading: false,
        maxOffset: undefined,
        offset: 0,
      }

    if (status.isLoading) return

    if (status.maxOffset !== undefined && status.offset >= status.maxOffset) return

    status.isLoading = true

    unstable_batchedUpdates(() => {
      useUserStore.getState().setUsersStatus(status)
    })

    const usersLeaderbaord: { users: LeaderboardUser[] } = await this.server.get(
      `/users/leaderboard/?league=${league}&offset=${status.offset}`,
    )

    if (!usersLeaderbaord?.users) return

    const currentLeague = useUserStore.getState().league
    if (currentLeague !== league) return

    const newUsers = usersLeaderbaord.users.filter((p) => !useUserStore.getState().users?.find((sp) => sp.id === p.id))
    const users = useUserStore.getState().users || []

    if (!newUsers.length) {
      status.maxOffset = status.offset
    } else {
      status.offset += USER_LEADERBOARD_LIMIT
    }
    status.isLoading = false

    unstable_batchedUpdates(() => {
      useUserStore.getState().setUsers([...users, ...newUsers], league)
      useUserStore.getState().setUsersStatus(status)
    })
  }

  async getFriends() {
    const data: { friends: LeaderboardUser[] } = await this.server.get('/users/friends/')

    if (!data?.friends) return

    unstable_batchedUpdates(() => {
      useUserStore.getState().setFriends(data.friends)
    })
  }
}

export default Users
