import { useEffect, useState } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'
import { useUserStore } from '../stores/userStore';
import { Container } from '../components/game-ui/Leaderboard/Container';
import server from '../logic/server';
import { PoolInfo } from '../components/game-ui/Leaderboard/Pool/PoolInfo';

export const runtime = 'edge'

export default function Pool() {
  const match = useMatch("/pool/:poolId");
  const user = useUserStore((state) => state.user)
  const navigate = useNavigate();
  const [tab, setTab] = useState('miners')

  useEffect(() => {
    if (!user) {
      navigate('/')
      return
    }
  }, [])

  return (
    <>
      <Container
        onScroll={() => {
          if (tab === 'miners') {
            server.pools.getUsers()
          }
        }}
      >
        <PoolInfo poolId={match?.params.poolId as string} tab={tab} setTab={setTab} />
      </Container>
    </>
  )
}
