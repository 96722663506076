import crypto from 'crypto-js'

const average = [1, 2, 3, 4, 5, 6, 7, 8].reduce((acc, curr) => acc + curr, 0) / 8

export const probabiity = [1, 2, 3, 4, 5, 6, 7, 8].reduce((acc, curr) => acc + average / curr, 0)

export const energyPerSecond = 1
export const maxEnergy = 1000

export const miningCostPerSec = 10

export interface Cube {
  id: number
  energyCost: number
  time: number
  probabiity: number
}

const cubeDropsDivider = 9
export const cubes: any = {
  0: {
    id: 0,
    energyCost: 10,
    time: 1,
    probabiity: average / 1 / probabiity,
    drops: Math.floor((10 * 1) / cubeDropsDivider),
  },
  1: {
    id: 1,
    energyCost: 20,
    time: 2,
    probabiity: average / 2 / probabiity,
    drops: Math.floor((10 * 2) / cubeDropsDivider),
  },
  2: {
    id: 2,
    energyCost: 30,
    time: 3,
    probabiity: average / 3 / probabiity,
    drops: Math.floor((10 * 3) / cubeDropsDivider),
  },
  3: {
    id: 3,
    energyCost: 40,
    time: 4,
    probabiity: average / 4 / probabiity,
    drops: Math.floor((10 * 4) / cubeDropsDivider),
  },
  4: {
    id: 4,
    energyCost: 50,
    time: 5,
    probabiity: average / 5 / probabiity,
    drops: Math.floor((10 * 5) / cubeDropsDivider),
  },
  5: {
    id: 5,
    energyCost: 60,
    time: 6,
    probabiity: average / 6 / probabiity,
    drops: Math.floor((10 * 6) / cubeDropsDivider),
  },
  6: {
    id: 6,
    energyCost: 70,
    time: 7,
    probabiity: average / 7 / probabiity,
    drops: Math.floor((10 * 7) / cubeDropsDivider),
  },
  7: {
    id: 7,
    energyCost: 80,
    time: 8,
    probabiity: average / 8 / probabiity,
    drops: Math.floor((10 * 8) / cubeDropsDivider),
  },
  8: {
    id: 8,
    energyCost: 90,
    time: 10,
    drops: 0,
  },
}

export function getCubeByHash(_hash: string, mined_count: number, mystery_ids: number[]) {
  if (mystery_ids?.includes(mined_count)) {
    return cubes[8]
  }

  // update _hash by mined_count
  let hash = crypto.algo.SHA256.create()
    .update(_hash)
    .update(mined_count.toString())
    .finalize()
    .toString(crypto.enc.Hex)

  let prob = parseInt(hash.slice(0, 16), 16) / Math.pow(16, 16)

  const block = getCubeByProbability(prob)

  return block
}

function getCubeByProbability(prob: number) {
  let sum = 0
  for (let i = 0; i < 8; i++) {
    sum += cubes[i].probabiity
    if (sum >= prob) {
      return cubes[i]
    }
  }

  return cubes[7]
}
