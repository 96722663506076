import { restProposals } from '../../logic/game/market'
import { usePageStore } from '../../stores/pageStore'
import { useUserStore } from '../../stores/userStore'
import { Panel } from './Panel'
import { useTranslation } from 'i18nano'

export function RestorePanel() {
  const setPage = usePageStore((state) => state.setPage)
  const setBottomRestPay = usePageStore((state) => state.setBottomRestPay)
  const user = useUserStore((state) => state.user)
  const t = useTranslation()

  return (
    <Panel title={t('restore-energy')} onClose={() => setPage(undefined)}>
      <MyDrops />
      <div className='grid grid-cols-2 gap-4 w-full'>
        {Object.values(restProposals).map((el) => (
          <div
            key={el.energy}
            className='h-24 w-full bg-white bg-opacity-30 flex flex-col items-center justify-center gap-2 rounded-2xl p-4'
            style={{
              boxShadow: 'inset 0 0 20px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)',
            }}
          >
            <div className='flex flex-row items-center gap-1'>
              <img src='/img/bolt-icon.png' alt='box-icon' className='w-5 h-5 object-contain' />
              <p className='text-white font-bold text-xl'>{el.energy}</p>
            </div>
            <button
              className='h-8 w-full max-w-32 bg-white bg-opacity-80 flex flex-row items-center justify-center gap-1 rounded-full'
              onClick={() => {
                setBottomRestPay(el.id)
              }}
            >
              <p className='text-[#484848] font-bold'>{el.dropsPrice}</p>
              <img src='/img/drop-icon.png' alt='drop-icon' className='w-5 h-5 object-contain' />
            </button>
          </div>
        ))}
      </div>
    </Panel>
  )
}

export function MyDrops() {
  const setPage = usePageStore((state) => state.setPage)
  const user = useUserStore((state) => state.user)

  return (
    <button
      className='absolute right-0 flex flex-row px-3 py-1 bg-white bg-opacity-80 rounded-full text-white font-bold items-center cursor pointer'
      style={{
        top: -45,
      }}
      onClick={() => {
        setPage('buy-drops')
      }}
    >
      <img src='/img/drop-icon.png' className='w-5 h-5object-contain' style={{ imageRendering: 'pixelated' }} />
      <p className='text-xl text-grey-600 ml-1.5'>{user?.drops_amount}</p>
      <img src='/img/plus-icon2.png' className='w-6 h-6 ml-2 object-contain' style={{ imageRendering: 'pixelated' }} />
    </button>
  )
}
