import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../stores/userStore";
import {
  InviteFriends,
  InviteFriendsBottomInfo,
} from "../components/game-ui/InviteFriends";
import { Container } from "../components/game-ui/Leaderboard/Container";

export default function Frens() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("HERE");
    if (!user) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <>
      <InviteFriendsBottomInfo />
      <Container onScroll={() => {}}>
        <InviteFriends />
      </Container>
    </>
  );
}
