import { useEffect, useState } from "react";
import { useUserStore } from "../stores/userStore";
import { useNavigate } from "react-router-dom";
import { usePoolsStore } from "../stores/poolsStore";
import server from "../logic/server";
import { BottomInfo } from "../components/game-ui/Leaderboard/BottomInfo";
import { Container } from "../components/game-ui/Leaderboard/Container";
import { TopButtons } from "../components/game-ui/Leaderboard/TopButtons";
import { Leaderboard } from "../components/game-ui/Leaderboard/Leaderboard";
import { LEAGUES, TOTAL_LEAGUES } from "../logic/game/pools";
import { PoolsOnboarding } from "../components/game-ui/Leaderboard/Onboarding";

export default function PoolLeaderboard() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  let [onboarding, setOnboarding] = useState(-1);
  const league = usePoolsStore((state) => state.league);
  const setLeague = usePoolsStore((state) => state.setLeague);

  useEffect(() => {
    if (onboarding === 2) {
      setOnboarding(-1);
      server.game.setPoolStoryWatched();
    }
  }, [onboarding]);

  useEffect(() => {
    if (!user) {
      navigate("/");
      return;
    }

    if (!user.is_pool_watched) {
      setOnboarding(0);
    }
  }, []);

  return (
    <>
      {onboarding === -1 ? (
        <>
          <BottomInfo />
          <Container
            onScroll={() => {
              server.pools.getPoolsLeaderboard();
            }}
          >
            <TopButtons />
            <Leaderboard
              league={league}
              setLeague={setLeague}
              leagues={LEAGUES}
              totalLeagues={TOTAL_LEAGUES}
              getLeaderboard={() => {
                server.pools.getPoolsLeaderboard();
              }}
            />
          </Container>
        </>
      ) : (
        <Container>
          <PoolsOnboarding step={onboarding} setStep={setOnboarding} />
        </Container>
      )}
    </>
  );
}
