import { unstable_batchedUpdates } from "react-dom";
import Server from "../server";
import {
  Pool,
  PoolTimestamp,
  SelectedPool,
  usePoolsStore,
} from "../../../stores/poolsStore";
import { getLeague, POOL_LIMIT, USER_LIMIT } from "../../game/pools";
import { toastError, toastSuccess } from "../../toast";
import { usePageStore } from "../../../stores/pageStore";
import { useUserStore } from "../../../stores/userStore";

class Pools {
  private server: Server;

  constructor(server: Server) {
    this.server = server;
  }

  async getOrCreatePool(username: string) {
    const data = await this.server.get(`/pools/find-or-create/${username}`);

    if (!data?.pool) return;

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setSelectedPool(data.pool);
    });

    return data.pool;
  }

  async getSelectedPool(id: number, isMyPool = false) {
    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setSelectedPool(undefined);
    });

    const data: { pool: SelectedPool; claimed?: number } =
      await this.server.get(`/pools/${id}${isMyPool ? "/my" : ""}`);

    if (!data?.pool) return;

    unstable_batchedUpdates(() => {
      if (!isMyPool) {
        usePoolsStore.getState().setSelectedPool(data.pool);
      } else {
        usePoolsStore
          .getState()
          .setLeague(getLeague(data.pool.totalMinedQuestions).id);
        usePoolsStore.getState().setMyPool(data.pool);
      }
    });
  }

  async getPoolsLeaderboard() {
    const league = usePoolsStore.getState().league;
    let status = usePoolsStore.getState().poolsStatus;

    if (!status)
      status = {
        isLoading: false,
        maxOffset: undefined,
        offset: 0,
      };

    console.log("1", status);
    if (status.isLoading) return;
    if (status.maxOffset !== undefined && status.offset >= status.maxOffset)
      return;
    console.log("1");
    status.isLoading = true;

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setPoolsStatus(status);
    });

    const poolsLeaderboard: { pools: Pool[]; promotions: Pool[] } =
      await this.server.get(
        `/pools/leaderboard/?league=${league}&offset=${status.offset}`
      );

    if (!poolsLeaderboard?.pools) return;

    const currentLeague = usePoolsStore.getState().league;
    if (currentLeague !== league) return;

    const newPools = poolsLeaderboard.pools.filter(
      (p) => !usePoolsStore.getState().pools?.find((sp) => sp.id === p.id)
    );
    const pools = usePoolsStore.getState().pools || [];

    let previousOffset = status.offset;

    if (!newPools.length) {
      status.maxOffset = status.offset;
    } else {
      status.offset += POOL_LIMIT;
    }
    status.isLoading = false;

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setPools([...pools, ...newPools], league);
      usePoolsStore.getState().setPoolsStatus(status);
      if (previousOffset === 0) {
        usePoolsStore.getState().setPromotions(poolsLeaderboard.promotions);
      }
    });
  }

  async joinPool(id: number) {
    const data: {
      pool: SelectedPool;
      canLeave?: boolean;
      minsLeft?: number;
      drops_amount: string;
    } = await this.server.post(`/pools/${id}/join`, {});

    if (data?.canLeave === false) {
      toastError(`You can join a new pool in ${data.minsLeft} mins`);
      return;
    }

    const selected = usePoolsStore.getState().selectedPool;
    if (!data?.pool) {
      // if (selected.needSubscribe) {
      unstable_batchedUpdates(() => {
        usePageStore.getState().setPage("pool-need-subscribe");
      });
      // }
      return;
    }

    const user = useUserStore.getState().user;
    unstable_batchedUpdates(() => {
      if (!user) return;
      usePoolsStore.getState().setSelectedPool(data.pool);
      usePoolsStore.getState().setMyPool(data.pool);
      useUserStore.getState().setUser({
        ...user,
        pool_id: data.pool.id + "",
        drops_amount: data?.drops_amount
          ? data.drops_amount
          : user.drops_amount,
      });
    });
  }

  async getUsers() {
    const selected = usePoolsStore.getState().selectedPool;
    let status = selected?.usersStatus;
    if (!status) {
      status = {
        isLoading: false,
        maxOffset: undefined,
        offset: 0,
      };
    }

    if (status.isLoading || !selected) return;
    if (status.maxOffset !== undefined && status.offset >= status.maxOffset)
      return;

    status.isLoading = true;

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setSelectedPool({
        ...selected,
        usersStatus: status,
      });
    });

    const poolId = selected.id;

    const data: { users: any[] } = await this.server.get(
      `/pools/${poolId}/users?offset=${status.offset}`
    );

    if (!data?.users) return;

    const newUsers = data.users.filter(
      (u) => !selected.users?.find((su) => su.id === u.id)
    );
    const users = selected.users || [];

    if (!data.users?.length) {
      status.maxOffset = status.offset;
    } else {
      status.offset += USER_LIMIT;
    }
    status.isLoading = false;

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setSelectedPool({
        ...selected,
        users: [...users, ...newUsers],
        usersStatus: status,
      });
    });
  }

  async getClaimed() {
    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setIsClaimLoading(true);
    });
    const data: { claim: number } = await this.server.get(`/pools/claim`);

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setIsClaimLoading(false);
    });

    if (!data?.claim) return;

    usePoolsStore.getState().setClaim(data.claim);
  }

  async claim() {
    const claim = usePoolsStore.getState().claim;
    if (!claim || claim < 1) {
      toastError("Must be at least 1 to claim");
      return;
    }
    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setIsClaimLoading(true);
    });

    const data: { claim: number; boxesAmount: number } = await this.server.post(
      `/pools/claim`,
      {}
    );

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setIsClaimLoading(false);
    });
    if (!data?.claim) {
      toastError("Error Claiming");
      return;
    }

    toastSuccess("Claimed successfully");

    unstable_batchedUpdates(() => {
      usePoolsStore.getState().setClaim(data.claim);
      const user = useUserStore.getState().user;
      if (!user) return;
      useUserStore
        .getState()
        .setUser({ ...user, boxes_amount: data.boxesAmount + "" });
    });
  }

  async leavePool() {
    const data: { ok: boolean; canLeave?: boolean; minsLeft?: number } =
      await this.server.post(`/pools/leave`, {});

    if (data.canLeave === false) {
      toastError(`You can leave the pool in ${data.minsLeft} mins`);
      return;
    }

    if (!data?.ok) return;

    const user = useUserStore.getState().user;
    const selected = usePoolsStore.getState().selectedPool;

    unstable_batchedUpdates(() => {
      if (!user || !selected) return;

      useUserStore.getState().setUser({ ...user, pool_id: undefined });
      usePoolsStore.getState().setSelectedPool({
        ...selected,
        joinedAmount: selected.joinedAmount - 1,
        me: undefined,
        users: [
          ...selected.users?.filter((u) => u.id + "" !== selected.me?.id + ""),
        ],
      });
      usePoolsStore.getState().setMyPool(undefined);
    });
  }

  async boost(amount: number) {
    const data: {
      poolInvested: number;
      userInvested: number;
      userPoolInvested: number;
    } = await this.server.post(`/pools/boost`, { amount });

    if (!data?.userInvested) return;

    toastSuccess("Boosted successfully");
    const selected = usePoolsStore.getState().selectedPool;
    const myPool = usePoolsStore.getState().myPool;
    const user = useUserStore.getState().user;
    const me = useUserStore.getState().me;
    unstable_batchedUpdates(() => {
      if (!data.poolInvested) {
        if (!me) return;
        useUserStore().setMe({ ...me, invested: data.userInvested });
        return;
      }

      if (selected) {
        if (selected.me) {
          usePoolsStore.getState().setSelectedPool({
            ...selected,
            invested: data.poolInvested,
            me: { ...selected?.me, poolInvested: data.userPoolInvested },
          });
        }
        usePoolsStore
          .getState()
          .setMyPool({ ...selected, invested: data.poolInvested });
      } else if (myPool) {
        usePoolsStore
          .getState()
          .setMyPool({ ...myPool, invested: data.poolInvested });
      }

      if (me)
        useUserStore.getState().setMe({ ...me, invested: data.userInvested });

      if (!user) return;
      useUserStore.getState().setUser({
        ...user,
        drops_amount: (parseInt(user.drops_amount) - amount).toString(),
      });
    });
  }

  async verify() {
    const data: { ownerId: number } = await this.server.post(
      `/pools/verify`,
      {}
    );
    if (!data?.ownerId) {
      toastError("Not verified");
      return;
    }

    toastSuccess("Verified successfully");

    const selected = usePoolsStore.getState().selectedPool;

    unstable_batchedUpdates(() => {
      usePageStore.getState().setPage(undefined);
      if (!selected) return;
      usePoolsStore
        .getState()
        .setSelectedPool({ ...selected, ownerId: data.ownerId + "" });
    });
  }

  async claimOwner() {
    const ownerClaim = usePoolsStore.getState().selectedPool?.ownerClaim;
    if (!ownerClaim || parseFloat(ownerClaim) < 1) {
      toastError("Must be at least 1 to claim");
      return;
    }
    const data: { claim: number; boxesAmount: number } = await this.server.post(
      `/pools/claim-owner`,
      {}
    );

    if (!data?.claim) {
      toastError("Must be at least 1 to claim");
      return;
    }

    console.log(data);
    toastSuccess("Claimed successfully");

    const selected = usePoolsStore.getState().selectedPool;
    const user = useUserStore.getState().user;

    unstable_batchedUpdates(() => {
      if (user)
        useUserStore
          .getState()
          .setUser({ ...user, boxes_amount: data.boxesAmount + "" });
      if (selected)
        usePoolsStore
          .getState()
          .setSelectedPool({ ...selected, ownerClaim: data.claim + "" });
    });
  }

  async updateSettings(poolId: number, needSubscribe: boolean) {
    const data: { needSubscribe: boolean } = await this.server.post(
      `/pools/${poolId}/update-settings`,
      {
        needSubscribe,
      }
    );

    if (data?.needSubscribe === undefined) return;

    const selected = usePoolsStore.getState().selectedPool;

    toastSuccess("Updated successfully");
    unstable_batchedUpdates(() => {
      if (!selected) return;
      usePoolsStore
        .getState()
        .setSelectedPool({ ...selected, needSubscribe: data.needSubscribe });
    });
  }

  async getStats(poolId: string) {
    const data: { stats: PoolTimestamp[] } = await this.server.get(
      `/pools/${poolId}/stats`
    );
    if (!data?.stats) return;

    const selected = usePoolsStore.getState().selectedPool;

    unstable_batchedUpdates(() => {
      if (selected)
        usePoolsStore
          .getState()
          .setSelectedPool({ ...selected, stats: data.stats });
    });
  }

  async createPromotion(costPerUser: number, usersAmount: number) {
    const data: { dropsAmount?: number } = await this.server.post(
      `/pools/promote`,
      {
        costPerUser,
        usersAmount,
      }
    );

    if (data?.dropsAmount === undefined) {
      toastError("Error promoting");
      return;
    }

    toastSuccess("Promoted successfully");

    unstable_batchedUpdates(() => {
      const user = useUserStore.getState().user;
      if (user)
        useUserStore.getState().setUser({
          ...user,
          drops_amount: data.dropsAmount + "",
        });
      const selected = usePoolsStore.getState().selectedPool;
      if (selected) {
        usePoolsStore.getState().setSelectedPool({
          ...selected,
          promotionsLeft: usersAmount,
          costPerUser,
        });
      }
    });
  }

  async cancelPromotion() {
    // cancel-promotion
    const data: { drops_amount?: number } = await this.server.post(
      `/pools/cancel-promotion`,
      {}
    );

    if (!data?.drops_amount) {
      toastError("Error canceling promotion");
      return;
    }

    toastSuccess("Canceled successfully");

    unstable_batchedUpdates(() => {
      const user = useUserStore.getState().user;
      if (user)
        useUserStore.getState().setUser({
          ...user,
          drops_amount: data.drops_amount + "",
        });
      const selected = usePoolsStore.getState().selectedPool;
      if (selected) {
        usePoolsStore.getState().setSelectedPool({
          ...selected,
          promotionsLeft: undefined,
          costPerUser: undefined,
        });
      }
    });
  }
}

export default Pools;
