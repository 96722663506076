import { create } from 'zustand'
import { ScrollStatus } from './poolsStore'

export interface User {
  id: number

  address: null | string
  tg_id: null | string

  hash: string
  mined_count: string
  boxes_amount: string

  drops_amount: string
  energy: string
  last_energy_update: string

  pool_id?: string

  mystery_ids: number[]

  referral_premium: string
  referral_default: string
  new_referral_premium: string
  new_referral_default: string

  t_twitter_claim: boolean
  t_telegram_claim: boolean

  banned_until_restore: string

  locale: string | null
  username: string | null
  is_pool_watched?: boolean

  onboarding_done: boolean

  token: string

  t_amount: string
  t_last_update: string
}

interface PayWallet {
  wallet: 'PUNKCITY' | 'TONCONNECT'
  address?: string
  punkBalance?: number
  tonBalance?: number
  jettonAddress?: string
  isPunkCityPaying?: boolean
}

export interface LeaderboardUser {
  id: number
  name: string
  invested: number
  mined_count: number
  rank?: number
}

interface UserStore {
  user?: User
  energy?: number
  isFromTelegram?: boolean
  isGameLoaded: boolean
  address?: string
  shouldDisconnectWallet: boolean
  ping?: number
  payWallet?: PayWallet
  airdropClaim?: number

  league: number
  users?: LeaderboardUser[]
  userStatus?: ScrollStatus
  me?: LeaderboardUser
  friends?: LeaderboardUser[]
  setFriends: (friends: LeaderboardUser[]) => void
  setLeague: (league: number) => void
  setUsers: (users: LeaderboardUser[], league: number) => void
  setUsersStatus: (status?: ScrollStatus) => void
  setMe: (me: LeaderboardUser) => void

  setAirdropClaim: (n: number) => void
  setPayWallet: (n?: PayWallet) => void
  setPing: (n: number) => void
  setUser: (n?: User) => void
  setEnergy: (n: number) => void
  setFromTelegram: (n: boolean) => void
  setGameLoaded: (n: boolean) => void
  setAddress: (n: string) => void
  setShouldDisconnectWallet: (n: boolean) => void
}

export const useUserStore = create<UserStore>((set) => ({
  user: undefined,
  isFromTelegram: undefined,
  isGameLoaded: false,
  address: undefined,
  shouldDisconnectWallet: false,
  ping: undefined,
  payWallet: undefined,

  league: 0,
  setMe: (me: LeaderboardUser) => set({ me }),
  setUsersStatus: (status?: ScrollStatus) => set({ userStatus: status }),
  setLeague: (league: number) => set({ league, users: undefined, userStatus: undefined }),
  setUsers: (users: LeaderboardUser[], league: number) => set({ users, league }),

  setFriends: (friends: LeaderboardUser[]) => set({ friends }),

  setAirdropClaim: (n) => set({ airdropClaim: n }),
  setPayWallet: (n) => set({ payWallet: n }),
  setPing: (n) => set({ ping: n }),
  setUser: (n) => set({ user: n }),
  setFromTelegram: (n) => set({ isFromTelegram: n }),
  setGameLoaded: (n) => set({ isGameLoaded: n }),
  setAddress: (n) => set({ address: n }),
  setShouldDisconnectWallet: (n) => set({ shouldDisconnectWallet: n }),
  setEnergy: (n) => set({ energy: n }),
}))
