export function addCommasToNumber(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function getReadableNumber(number: number, isShort = false) {
  if (!number) return '0'
  if (number >= 1e12) {
    return `${(number / 1e12).toFixed(isShort ? 0 : 1)}T`
  }

  if (number >= 1e9) {
    return `${(number / 1e9).toFixed(isShort ? 0 : 1)}B`
  }

  if (number >= 1e6) {
    return `${(number / 1e6).toFixed(isShort ? 0 : 1)}M`
  }

  if (number >= 1e3) {
    return `${(number / 1e3).toFixed(isShort ? 0 : 1)}K`
  }

  return number.toString()
}
