export const russianQuotes = [
  'Нелепо думать, что кто-то, кроме тебя, сделает тебя счастливым или несчастным.',
  'Большая гордость приведёт к падению, а смирение — к победе.',
  'Если нужно что-то сделать, делай это от всего сердца.',
  '”Даже если все складывается не так, как вы ожидали, не падайте духом и не сдавайтесь. Тот, кто продолжает двигаться вперед — в конце концов победит.” — Дайсаку Икеда',
  '”Человек, который даёт другим надежду даже в худшие времена — настоящий лидер.” — Дайсаку Икеда',
  '“Вы не ограничены ни телом, ни умом, ни реальностью. Вы — океан Сознания, наделенный бесконечным потенциалом. Вы — само существование" — Джозеф Кауфман',
  '«Ваше время ограничено, не тратьте его, живя другой жизнью. Не попадайтесь на крючок вероучения, которое существует на мышлении других людей». — Стив Джобс',
  '“Всё, что можно вообразить — реально.” — Пабло Пикассо',
  '“Я не сумасшедший, просто моя реальность отличается от вашей!” — Льюис Кэрролл',
  '“Сделай из своей жизни мечту, а из мечты — реальность!” — Антуан де Сент-Юкзюпери',
  '“Будущее неизвестно” — Джо Страммер',
  '"Восприятие предшествует реальности" — Энди Уорхол',
  '”Камни всё помнят” — Нил Армстронг',
  '”Существует 7.8 млрд версий реальности” — Брайан Норгард',
  '”Невозможно решить проблему находясь на том же уровне сознания, на котором она возникла.” — Альберт Эйнштейн',
  '“Сознание формируется через боль” — Карл Юнг',
  '“Сознание — это тайна, которая сталкивается с тайной потенциала и трансформирует его в реальность. Мы делаем это с каждым своим выбором. Наш выбор определяет судьбу мира. Делая выбор, вы изменяете структуру реальности.” — Джордан Питерсон',
  '“Не позволяйте шуму чужих мнений заглушить ваш внутренний голос.” — Стив Джобс',
  '“Мои любимые вещи в жизни не стоят денег. Совершенно очевидно, что самый ценный ресурс, который у всех нас есть, - это время.” — Стив Джобс',
  '«Я верю, что жизнь разумна и что все это не случайно». — Стив Джобс',
  '“Лучше быть оптимистом и ошибиться, чем быть пессимистом и оказаться правым.” — Илон Маск',
  '"Чем реальнее ты становишься, тем нереальнее становится мир".',
  '“Всё, что тебе нужно, — это любовь.” — Джон Леннон',
  '“Время и память - настоящие художники: они подстраивают реальность, под сердце.” — Джон Дьюи',
  '...... чт0 тут происходит?',
  'Здравствуй. Как ты себя чувствуешь сегодня?',
]

export const englishQuotes = [
  '“It is ridiculous to think that somebody else or the material world can make you happy or unhappy.”',
  '”Haughtiness goes before destruction; humility precedes honor” ',
  'If anything is worth doing, do it with all your heart.',
  '“Even if things don`t unfold the way you expected, don`t be disheartened or give up. One who continues to advance will win in the end.” — Daisaku Ikeda',
  '“A person, who no matter how desperate the situation, gives others hope, is a true leader.” — Daisaku Ikeda',
  '“You are not limited to this body, to this mind, or to this reality—you are a limitless ocean of Consciousness, imbued with infinite potential. You are existence itself.” — Joseph P. Kauffman',
  `“Your time is limited, so don't waste it living someone else's life. Don't be trapped by dogma – which is living with the results of other people's thinking.” — Steve Jobs`,
  '“Everything you can imagine is real.” – Pablo Picasso',
  '“I’m not strange, weird, off, nor crazy, my reality is just different from yours.” — Lewis Carroll',
  '“Make your life a dream, and a dream a reality.” — Antoine de Saint-Exupéry',
  '“The future is unwritten.” — Joe Strummer',
  '“Perception precedes reality.” — Andy Warhol',
  '“Geologists have a saying – rocks remember.” — Neil Armstrong',
  '“There are 7.8 billion versions of reality.” — Brian Norgard',
  '“No problem can be solved from the same level of consciousness that created it.” — Albert Einstein',
  '“There is no birth of consciousness without pain.” — Carl Jung',
  '“Consciousness is a mystery that faces the mystery of potential and transforms it into actuality. We do that with every choice we make. Our choices determine the destiny of the world. By making a choice, you alter the structure of reality.” — Jordan B. Peterson',
  `“Don't let the noise of others' opinions drown out your own inner voice.” — Steve Jobs`,
  `“My favorite things in life don't cost any money. It's really clear that the most precious resource we all have is time.” — Steve Jobs`,
  `“I believe life is an intelligent thing: that things aren't random.” — Steve Jobs`,
  `“I'd rather be optimistic and wrong than pessimistic and right.” — Elon Musk`,
  `“The more real you get, the more unreal the world gets.” — John Lenon`,
  `“All you need is love.” — John Lennon`,
  `“Time and memory are true artists; they remould reality nearer to the heart's desire.” — John Dewey`,
  `......
what;s 
going on here?`,
  `Hey dear
How are you feeling today?`,
  `Inhale, hold, exhale, hold`,
  `Did you miss me?`,
  `I was waiting you here (^-^)`,
  `Make yourself at home`,
  `? or ¿`,
  `¿hola?`,
  `I do not have wings, but I can fly. I don’t have eyes, but I can cry! What am I?`,
  `Mary’s father has five daughters – ??, ¿¿, ?¿, ¿?. What is the fifth daughter’s name?`,
  `What has to be broken before you can use it?`,
  `Is anyone home?`,
  `Which weighs more, a pound of feathers or a pound of (*-*)?`,
  `Follow the river and you'll get to the sea?`,
  `gm? gn?`,
]
