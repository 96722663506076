'use client'

import { boxShadowDefault } from './utils'
import { useEffect, useState } from 'react'
import { useTranslation, useTranslationChange } from 'i18nano'
import { useUserStore } from '../../stores/userStore'
import { usePoolsStore } from '../../stores/poolsStore'
import { usePageStore } from '../../stores/pageStore'
import { audioStatus, muteAudio, unmuteAudio } from '../../helpers/audio'
import { maxEnergy } from '../../logic/game/cubes'
import { Link } from 'react-router-dom'
import { containerStatus } from './Leaderboard/Container'
import { poolPreviewUrl, sTelegramUrl } from '../../logic/game/music'
import { getLeagueUser, USER_LEAGUES } from '../../logic/game/users'

interface Props {
  isOnboarding?: boolean
}

export function BottomPanel(props: Props) {
  const { isOnboarding } = props

  const t = useTranslation()

  const translation = useTranslationChange()

  const energy = useUserStore((state) => state.energy)
  const myPool = usePoolsStore((state) => state.myPool)

  const user = useUserStore((state) => state.user)

  const isFromTelegram = useUserStore((state) => state.isFromTelegram)

  const setPage = usePageStore((state) => state.setPage)

  const [isMuted, setIsMuted] = useState(audioStatus.isMuted)

  const [size, setSize] = useState(window.innerWidth)

  const [percentage, setPercentage] = useState(100)

  useEffect(() => {
    const perc = Math.min(((!isOnboarding ? (energy || 0) : 30) / maxEnergy) * 100, 100)
    setPercentage(perc)
  }, [energy])

  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <div
        className='absolute h-32 w-full left-1/2 transform -translate-x-1/2  z-10 px-4 flex flex-col items-center justify-center gap-2'
        style={{ maxWidth: 400, width: 'full', bottom: 15 }}
      >
        <div className='flex flex-row w-full items-center justify-between gap-0 mb-2'>
          {/* <div className='flex flex-row items-center gap-1'>
            <img src='/img/bolt-icon.png' alt='box-icon' className='w-4 h-4 object-contain' />
            <p className='text-white font-bold text-lg'>{!isOnboarding ? `${energy}/${maxEnergy}` : '30/180'}</p>
          </div> */}
          <SoloLeague mined_count={parseInt(user?.mined_count || '0')} />
          <div className='flex flex-row gap-1'>
            <button
              className='bg-white bg-opacity-30 px-3 h-max py-1 rounded-full shrink-0'
              style={{ boxShadow: boxShadowDefault }}
              onClick={() => {
                if (audioStatus.isMuted) {
                  setIsMuted(false)
                  unmuteAudio()
                } else {
                  setIsMuted(true)
                  muteAudio()
                }
              }}
            >
              <img src={`/img/${!isMuted ? 'un' : ''}muted.svg`} alt='logo' className='w-5 h-5 object-contain' />
            </button>
            <ButtonWithText onClick={() => !isOnboarding && setPage('restore')} opacity={80}>
              <p className='text-grey-600 font-semibold text-sm'>{size < 12000 ? t('sm-restore-energy') : t('rest')}</p>
              <img
                src='/img/bolt-icon.png'
                alt='box-icon'
                className='w-3.5 h-3.5 object-contain'
                style={{ filter: 'invert(0.8)' }}
              />
            </ButtonWithText>
          </div>
        </div>
        <div className='w-full h-4 bg-[#484848] rounded-full dark:bg-gray-700 relative'>
          <div
            className={`absolute left-2 w-full text-xs font-bold text-${percentage < 50 ? 'white' : 'grey-800'} flex flex-row items-center gap-1`}
            style={{
              top: 0,
            }}
          >
            {user?.banned_until_restore !== 'true' ? (
              <>
                <img
                  src='/img/bolt-icon.png'
                  alt='box-icon'
                  className='w-3 h-3 object-contain'
                  style={{
                    filter: percentage < 50 ? 'invert(0)' : 'invert(0.8)',
                  }}
                />
                {!isOnboarding ? `${energy}/${maxEnergy}` : '30/180'}
              </>
            ) : (
              <>
                {t('remaining')} {Math.floor((maxEnergy - energy!) / 60)}m
                {((maxEnergy - energy!) % 60).toString().padStart(2, '0')}s{' '}
              </>
            )}
          </div>

          <div
            className='h-4 bg-blue-600 rounded-full'
            style={{
              width: Math.min(((!isOnboarding ? energy! : 30) / maxEnergy) * 100, 100) + '%',
              transition: 'width 0.5s',
              background:
                percentage < 50
                  ? 'linear-gradient(90deg, #DBDBDB 0%, #FFA2A2 100%)'
                  : 'linear-gradient(90deg, #fff 0%, #fff 100%)',
            }}
          />
        </div>
        <div
          className='flex  flex-row w-full h-16 items-center justify-center min-[400px]:gap-4 gap-2 bg-white bg-opacity-30 rounded-3xl px-5 mt-1 shrink-0'
          style={{
            boxShadow: 'inset 0 0 20px rgba(255, 255, 255, 0.25), 1px 2px 2px rgba(0, 0, 0, 0.2)',
          }}
        >
          <PanelButton onClick={() => setPage('maindrop')} icon='/textures/texture-question.png' text={'M?nt'} />
          <Link to='/frens'>
            <PanelButton onClick={() => {}} icon='/img/frens-icon.svg' text={t('frens')} />
          </Link>
          <PanelButton
            onClick={() => {
              let url = 'https://status.thecubes.xyz'
              if (translation.lang === 'ru') {
                url = 'https://status.thecubes.xyz/?lang=ru'
              }

              window.open(url, '_blank')
            }}
            icon='/img/hashrate.svg'
            text={'Hashrate'}
          />
          <Link to={user?.pool_id ? `/pool/` : '/pool'} onClick={() => (containerStatus.isFromButton = true)}>
            <PanelButton
              iconSize={user?.pool_id ? 6 : 6}
              onClick={() => {
                // containerStatus.isFromButton = true
              }}
              icon={user?.pool_id ? `${sTelegramUrl}/channels/${user.pool_id}.jpg` : '/img/pools.svg'}
              text={t('pools')}
            />
          </Link>
          <PanelButton onClick={() => setPage('news')} icon='/img/news.svg' iconSize={5} text={t('news')} />
        </div>
      </div>
    </>
  )
}

function PanelButton(props: { onClick: () => void; icon: string; text: string; iconSize?: number }) {
  const { iconSize } = props
  return (
    <button
      className={`flex flex-col items-center gap-${iconSize === 6 ? 0.5 : 1}`}
      onClick={props.onClick}
      style={{
        minWidth: 55,
      }}
    >
      <img
        src={props.icon}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          currentTarget.src = poolPreviewUrl
        }}
        className={`w-${iconSize || 5} h-${iconSize || 5} object-contain rounded-sm`}
        style={{
          marginBottom: iconSize === 6 ? -1 : 0,
        }}
      />
      <p className='text-white text-sm font-semibold'>{props.text}</p>
    </button>
  )
}

export function Button(props: {
  onClick: () => void
  isOnboarding?: boolean
  opacity: number
  children?: any
  w?: number
}) {
  const { onClick, isOnboarding, children, opacity, w } = props

  const t = useTranslation()

  return (
    <button
      className={`h-8 w-${w ? w : 14} bg-white bg-opacity-${opacity} flex flex-row items-center justify-center gap-1 rounded-full`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

function ButtonWithText(props: { children: any; onClick: () => void; opacity?: number }) {
  const { opacity } = props
  return (
    <button
      className={`px-3 py-1 rounded-full flex flex-row items-center bg-white bg-opacity-${opacity ? opacity : 30} px-3 py-1 gap-1 items-center`}
      onClick={props.onClick}
      style={{
        marginTop: -0,
        boxShadow: 'inset 0 0 10px rgba(255, 255, 255, 0.1), 1px 2px 2px rgba(0, 0, 0, 0.1)',
      }}
    >
      {props.children}
    </button>
  )
}

function SoloLeague(props: { mined_count: number }) {
  const { mined_count } = props
  const league = getLeagueUser(props.mined_count)
  const nextLeague = USER_LEAGUES[league.id + 1] || league

  useEffect(() => {
    const ref = document.getElementById('progress-border')
    if (!ref) return
    // update stroke-dasharray
    const min = 15
    const max = 140 - min
    const progress = Math.floor((props.mined_count / nextLeague.fromMined) * max) + min
    ref.style.strokeDashoffset = `${progress}`
  }, [props.mined_count])

  return (
    <Link to='/users'>
      <div className='w-max pl-14 bg-white bg-opacity-30 relative flex justify-end items-center p-1 pr-2 rounded-full gap-1 cursor-pointer'>
        <div
          className='absolute left-0 top-1/2 transform -translate-y-1/2 rounded-full bg-gradient-to-b from-[#E19BF9] to-[#665BFF] flex items-center justify-center rotate-90 shadow-lg -scale-x-100'
          style={{
            height: 46,
            width: 46,
          }}
        >
          <svg>
            <circle id='progress-border' cx='50%' cy='50%' r='20px' fill='none' stroke='#fff' strokeWidth='1' />
          </svg>
          <div className='absolute left-0 top-0 w-full h-full flex items-center justify-center'>
            <img
              src={league.texture}
              className='w-full h-full rounded-full rotate-90 -scale-x-100'
              style={{
                padding: 5,
                imageRendering: 'pixelated',
              }}
            />
          </div>
        </div>
        <p className='text-xs text-white font-semibold'>
          <span className='text-xs font-bold'>{mined_count}</span>/{nextLeague.fromMined}
        </p>
        <img
          src='/textures/mined_count.png'
          className='w-3 h-3 rounded-sm'
          style={{
            imageRendering: 'pixelated',
          }}
        />
        <img src='/img/right-arrow.svg' className='w-4 h-4' />
      </div>
    </Link>
  )
}
