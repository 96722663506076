import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Layout from "./components/dom/Layout";
import Main from "./components/game-ui/Main";
import Frens from "./pages/Frens";
import Pool from "./pages/Pool";
import PoolLeaderboard from "./pages/PoolLeaderboard";
import Users from "./pages/Users";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Main />}>
      <Route path="frens" element={<Frens />} />
      <Route path="pool" element={<PoolLeaderboard />} />
      <Route path="pool/:poolId" element={<Pool />} />
      <Route path="users" element={<Users />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Suspense>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
