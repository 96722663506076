import { Button } from './Button'
import { Panel } from './Panel'
import { useTranslation } from 'i18nano'
import { useEffect } from 'react'
import { useTonConnectUI, useTonWallet, Wallet } from '@tonconnect/ui-react'
import { usePageStore } from '../../stores/pageStore'

export function Airdrop() {
  const setPage = usePageStore((state) => state.setPage)
  const t = useTranslation()

  return (
    <Panel title={t('mint-t')} onClose={() => setPage(undefined)}>
      <div className='flex flex-col items-center gap-2'>
        <div className='flex flex-row w-full justify-center gap-4'>
          {[...Array(1)].map((_, index) => (
            <img
              src='/textures/texture-question.png'
              key={index}
              className='w-14 h-14 object-contain rounded-md'
              style={{
                imageRendering: 'pixelated',
              }}
            />
          ))}
        </div>
      </div>
      <p className='text-white font-semibold text-lg mt-2 text-center'>{t('mint-1')}</p>
      <p className='text-white font-semibold text-lg text-center'>{t('mint-2')}</p>
      <p className='text-white font-semibold text-lg text-center'>{t('mint-3')}</p>
      <p className='text-[#eee] font-semibold text-sm mt-4 text-center'>{t('mint-f-date')}</p>
      <Button
        text={`??? Mint ???`}
        onClick={() => {
          console.log('???????????????')
        }}
        styles='bg-opacity-60'
      />
    </Panel>
  )
}
