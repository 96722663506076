import { Panel } from './Panel'
import { Button } from './Button'
import { useState } from 'react'
import { useTranslation, useTranslationChange } from 'i18nano'
import { useUserStore } from '../../stores/userStore'
import { usePageStore } from '../../stores/pageStore'
import { storageUrl } from '../../logic/game/music'
import { boxShadowDefault } from './utils'
import { DEFAULT_USER_REWARD, OLD_DEFAULT_USER_REWARD, OLD_PREMIUM_USER_REWARD, PREMIUM_USER_REWARD } from '../../logic/game/referrals'
import { toastError } from '../../logic/toast'


export function InviteFriendsPanel() {
  const user = useUserStore((state) => state.user)
  const isFromTelegram = useUserStore((state) => state.isFromTelegram)

  const setPage = usePageStore((state) => state.setPage)
  const t = useTranslation()
  const tChange = useTranslationChange()

  const [copied, setCopied] = useState(false)
  return (
    <Panel title={t('invite-frens') + ' {?}'} onClose={() => setPage(undefined)}>
      <div className='flex flex-row w-full justify-center gap-4'>
        {[...Array(3)].map((_, index) => (
          <img
            src={`${storageUrl}/img/friends-${index + 1}.png`}
            alt='box-icon'
            className='w-20 h-20 object-contain'
            key={index}
          />
        ))}
      </div>

      <div
        className='flex flex-col w-full bg-white bg-opacity-30 rounded-2xl py-3 px-4 mt-1 justify-center text-white items-center gap-1'
        style={{
          boxShadow: boxShadowDefault,
        }}
      >
        <div className='flex flex-row items-center gap-1'>
          <p className='font-bold text-xl'>{DEFAULT_USER_REWARD}</p>
          <img
            src='img/drop-icon.png'
            alt='box-icon'
            className='w-7 h-7 object-contain'
            style={{ imageRendering: 'pixelated', marginLeft: -3 }}
          />
          <p className='text-lg font-semibold text-center'>{t('just-fren')}</p>
        </div>
        {isFromTelegram && (
          <div className='flex flex-row justify-center items-center gap-1'>
            <p className='font-bold text-xl'>{PREMIUM_USER_REWARD}</p>
            <img
              src='img/drop-icon.png'
              alt='box-icon'
              className='w-7 h-7 object-contain'
              style={{ imageRendering: 'pixelated', marginLeft: -3 }}
            />
            <p className='text-lg font-semibold text-center'>{t('premium-fren')}</p>
          </div>
        )}
      </div>

      <div
        className='flex flex-col w-full bg-white bg-opacity-30 rounded-2xl py-3 px-4 mt-1 justify-center text-white items-center'
        style={{
          boxShadow: boxShadowDefault,
        }}
      >
        <p className='font-medium'>{t('you-got')}:</p>
        <div className='flex flex-row items-center gap-1 text-xl font-semibold'>
          <p className=''>
            {parseInt(user!.referral_default || '0') * OLD_DEFAULT_USER_REWARD +
              parseInt(user!.new_referral_default || '0') * DEFAULT_USER_REWARD}
          </p>
          <img
            src='img/drop-icon.png'
            alt='box-icon'
            className='w-6 h-6 object-contain'
            style={{ imageRendering: 'pixelated', marginLeft: -3 }}
          />
          <p className=''>
            {t('for')} {parseInt(user!.referral_default || '0') + parseInt(user!.new_referral_default || '0')}{' '}
            {tChange.lang === 'ru'
              ? `${parseInt(user!.referral_default || '0') + parseInt(user!.new_referral_default || '0') === 1 ? 'друга' : 'друзей'}`
              : `${t('frens').toLowerCase()}`}
          </p>
        </div>
        {isFromTelegram && (
          <div className='flex flex-row items-center gap-x-1 text-xl font-semibold justify-center flex-wrap'>
            <p className=''>
              {parseInt(user!.referral_premium || '0') * OLD_PREMIUM_USER_REWARD +
                parseInt(user!.new_referral_premium || '0') * PREMIUM_USER_REWARD}
            </p>
            <img
              src='img/drop-icon.png'
              alt='box-icon'
              className='w-6 h-6 object-contain'
              style={{ imageRendering: 'pixelated', marginLeft: -3 }}
            />
            <p className='text-xl font-semibold'>
              {t('for')} {parseInt(user!.referral_premium || '0') + parseInt(user!.new_referral_premium || '0')}{' '}
              {tChange.lang === 'ru'
                ? `premium ${parseInt(user!.referral_premium || '0') + parseInt(user!.new_referral_premium || '0') === 1 ? 'друга' : 'друзей'}`
                : `${t('premium').toLowerCase()} ${t('frens').toLowerCase()}`}
            </p>
          </div>
        )}
      </div>
      <Button
        text={copied ? t('copied') : t('copy-link')}
        styles='mt-1'
        onClick={() => {
          let link: string = ""
          if (user?.tg_id) {
            const tg_id = user.tg_id
            const ref = Buffer.from(tg_id).toString('base64')
            link = 'https://t.me/cubesonthewater_bot?start=' + ref
          } else if (user?.id) {
            link = 'https://www.thecubes.xyz/?ref=' + user.id
          }

          navigator.clipboard
            .writeText(link)
            .then(() => {
              setCopied(true)
              setTimeout(() => {
                setCopied(false)
              }, 3000)
            })
            .catch((err) => {
              toastError('Failed to copy to clipboard')
            })
        }}
      />
    </Panel>
  )
}
