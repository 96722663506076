import { useEffect, useState } from 'react'
import { useUserStore } from '../stores/userStore'
import { useNavigate } from 'react-router-dom'
import { BottomInfo } from '../components/game-ui/Leaderboard/BottomInfo'
import { Container } from '../components/game-ui/Leaderboard/Container'
import server from '../logic/server'
import { TopButtons } from '../components/game-ui/Leaderboard/TopButtons'
import { Leaderboard } from '../components/game-ui/Leaderboard/Leaderboard'
import { USER_LEAGUES } from '../logic/game/users'
import { TOTAL_LEAGUES } from '../logic/game/pools'


export default function Users() {
  const user = useUserStore((state) => state.user)
  const navigate = useNavigate();

  const league = useUserStore((state) => state.league)
  const setLeague = useUserStore((state) => state.setLeague)

  useEffect(() => {
    if (!user) {
      navigate('/')
      return
    }
  }, [])

  return (
    <>
      <>
        <BottomInfo isUsers={true} />
        <Container
          onScroll={() => {
            server.users.getUserLeaderboard()
          }}
        >
          <TopButtons />
          <Leaderboard
            isUsers={true}
            league={league}
            setLeague={setLeague}
            leagues={USER_LEAGUES}
            totalLeagues={TOTAL_LEAGUES}
            getLeaderboard={() => {
              server.users.getUserLeaderboard()
            }}
          />
        </Container>
      </>
    </>
  )
}
