import * as THREE from "three";
import { useEffect, useMemo, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useBlocksStore } from "../../stores/blocksStore";

const imgSize = 16;

const destinationPoint = {
  x: 10,
  y: 60,
};
export function Drops() {
  // array of meshes
  const [drops, setDrops] = useState<any[]>([]);
  const dropsAmount = useBlocksStore((state) => state.dropsAmount);
  const setDropsAmount = useBlocksStore((state) => state.setDropsAmount);

  const pickaxe = useMemo(() => {
    return new THREE.Matrix4();
  }, []);

  const drop = useMemo(() => {
    return new THREE.InstancedMesh(
      new THREE.BoxGeometry(1, 1, 2),
      new THREE.MeshLambertMaterial(),
      imgSize * imgSize
    );
  }, []);

  const [isDropReady, setIsDropReady] = useState(false);

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const img = new Image();
    img.src = "/textures/drop.png";

    img.onload = () => {
      console.log("LOADED");
      canvas.width = imgSize;
      canvas.height = imgSize;
      ctx.drawImage(img, 0, 0);

      // get all pixels
      const data = ctx.getImageData(0, 0, imgSize, imgSize).data;

      const color = new THREE.Color();
      for (let i = 0; i < imgSize; i++) {
        for (let j = 0; j < imgSize; j++) {
          const index = (i * imgSize + j) * 4;
          const r = data[index];
          const g = data[index + 1];
          const b = data[index + 2];
          const a = data[index + 3];

          if (a > 0) {
            pickaxe.setPosition(i - 4, j - 4, 0);
            drop.setMatrixAt(i * imgSize + j, pickaxe);
            drop.setColorAt(
              i * imgSize + j,
              color.setHex((r << 16) + (g << 8) + b)
            );
          } else {
            pickaxe.setPosition(-1000, -1000, -1000);
            drop.setMatrixAt(i * imgSize + j, pickaxe);
          }
        }
      }

      drop.position.set(30, 30, 0);
      drop.rotation.set(0, 0, -Math.PI / 2);
      drop.scale.set(2, 2, 2);

      drop.instanceMatrix.needsUpdate = true;

      setIsDropReady(true);
    };
  }, []);

  useEffect(() => {
    if (!isDropReady || !dropsAmount) return;

    let newDrops: any = [];

    for (let i = 0; i < Math.min(dropsAmount, 10); i++) {
      const newDrop = drop.clone();
      newDrop.position.set(
        -10 + Math.random() * 20,
        20 + Math.random() * 20,
        10 + Math.random() * 10
      );
      newDrop.rotation.set(0, 0, -Math.PI / 2);
      newDrop.scale.set(0.3, 0.3, 0.3);
      newDrops.push(newDrop);
    }

    setDrops(newDrops);
    setDropsAmount(0);
  }, [isDropReady, dropsAmount]);

  useFrame((state, delta) => {
    if (!drops.length) return;
    // console.log(drops.length)
    // send the drops to the sky
    for (let i = 0; i < drops.length; i++) {
      const drop: any = drops[i];

      const direction = new THREE.Vector3(
        destinationPoint.x - drop.position.x,
        destinationPoint.y - drop.position.y,
        0
      );
      direction.normalize();
      drop.position.x += direction.x * delta * 60;
      drop.position.y += direction.y * delta * 60;
      drop.rotation.y += delta * 5;
    }

    let isAllDropsArrived = true;
    for (let i = 0; i < drops.length; i++) {
      // if the drop is more destionation point remove
      if (drops[i].position.y < destinationPoint.y - 10) {
        isAllDropsArrived = false;
      }
    }

    if (isAllDropsArrived) {
      setDrops([]);
    }
  });

  return (
    <>
      {drops.map((drop, index) => (
        <primitive key={index} object={drop} />
      ))}
    </>
  );
}
