import { useTranslation } from 'i18nano'
import { useEffect, useState } from 'react'
import { useUserStore } from '../../../../stores/userStore'
import { PROMOTION_MIN_COST_PER_USER, PROMOTION_MIN_USERS_AMOUNT } from '../../../../logic/game/pools'
import { getReadableNumber } from '../../../../logic/utils/parsing'
import { usePageStore } from '../../../../stores/pageStore'
import { Panel } from '../../Panel'
import { MyDrops } from '../../RestorePanel'
import { Button } from '../../Button'
import { toastError } from '../../../../logic/toast'
import server from '../../../../logic/server'
import { inputShadow } from '../../utils'

export function PromotePool() {
  const user = useUserStore((state) => state.user)
  const me = useUserStore((state) => state.me)
  const [value, setValue] = useState(PROMOTION_MIN_COST_PER_USER + '')
  const [number, setNumber] = useState(PROMOTION_MIN_COST_PER_USER)
  const [value2, setValue2] = useState(PROMOTION_MIN_USERS_AMOUNT + '')
  const [number2, setNumber2] = useState(PROMOTION_MIN_USERS_AMOUNT)
  const [err1, setErr1] = useState('')
  const [err2, setErr2] = useState('')
  const t = useTranslation()

  useEffect(() => {
    if (!isNaN(parseInt(value)) && parseInt(value) >= 0) {
      if (parseInt(value) < PROMOTION_MIN_COST_PER_USER) {
        setErr1(`Minimum cost per user is ${getReadableNumber(PROMOTION_MIN_COST_PER_USER)}`)
      } else setErr1('')

      setNumber(parseInt(value))
    }
  }, [value])

  useEffect(() => {
    if (!isNaN(parseInt(value2)) && parseInt(value2) >= 0) {
      if (parseInt(value2) < PROMOTION_MIN_USERS_AMOUNT) {
        setErr2(`Minimum users amount is ${PROMOTION_MIN_USERS_AMOUNT}`)
      } else setErr2('')

      setNumber2(parseInt(value2))
    }
  }, [value2])
  const [isLoading, setIsLoading] = useState(false)
  const setPage = usePageStore((state) => state.setPage)
  return (
    <>
      <Panel title={t('promote-pool')} level={30} titleColor='grey-800' onClose={() => setPage(undefined)}>
        <MyDrops />
        <p className='text-black font-medium text-md'>{t('reward-for-join')}</p>
        <DropsInput value={value} setValue={setValue} placeholder='Enter reward' />
        {err1 && <p className='text-[#cc0000] text-sm'>{err1}</p>}
        <p className='text-black font-medium text-md'>{t('number-of-users')}</p>
        <DropsInput otherIcon='/img/group.svg' value={value2} setValue={setValue2} placeholder='Enter max users' />
        {err2 && <p className='text-[#cc0000] text-sm'>{err2}</p>}
        <p className='text-black font-medium text-sm w-full text-center mt-2'>{t('promote-desc-1')}</p>
        <p className='text-black font-medium text-sm w-full text-center'>{t('promote-desc-2')}</p>
        <Button
          text={`Promote ${getReadableNumber(number * number2)}`}
          icon='/img/drop-icon.png'
          isLoading={isLoading}
          styles='mt-1'
          onClick={() => {
            if (parseInt(user?.drops_amount || "0") < number) {
              toastError('You do not have enough drops')
              return
            }
            setIsLoading(true)
            server.pools.createPromotion(number, number2).then(() => {
              setIsLoading(false)
              setPage(undefined)
            })
          }}
        />
        {/* </div> */}
      </Panel>
    </>
  )
}

export function CloseButton(props: { onClick?: () => void }) {
  return (
    <button className='absolute right-5 top-5' onClick={props?.onClick}>
      <img
        src='/img/close.svg'
        alt='close'
        className='w-4 h-4 object-contain'
        style={{
          filter: 'invert(0.8)',
        }}
      />
    </button>
  )
}

function DropsInput({
  value,
  setValue,
  placeholder,
  otherIcon,
}: {
  value: string
  setValue: (value: string) => void
  placeholder: string
  otherIcon?: string
}) {
  return (
    <div className='w-full h-14 relative '>
      <input
        value={value}
        className='w-full h-14 bg-white p-2 rounded-3xl text-grey-800 font-semibold text-xl text-center'
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        style={{
          outline: 'none',
          boxShadow: inputShadow,
        }}
      />
      <div className='absolute right-2 top-1/2 transform -translate-y-1/2'>
        <img
          src={otherIcon ? otherIcon : '/img/drop-icon.png'}
          alt='drop-icon'
          className='w-8 h-8 object-contain'
          style={{ imageRendering: 'pixelated', filter: otherIcon ? 'invert(0.3)' : '' }}
        />
      </div>
    </div>
  )
}
