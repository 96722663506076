import { dropsProposals, MAX_CLAIM } from '../../logic/game/market'
import { toastError } from '../../logic/toast'
import { usePageStore } from '../../stores/pageStore'
import { useUserStore } from '../../stores/userStore'
import { Button } from './Button'
import { Panel } from './Panel'
import { useTranslation } from 'i18nano'

export function DailyClaim() {
  const setPage = usePageStore((state) => state.setPage)
  const user = useUserStore((state) => state.user)
  const setBottomWalletPay = usePageStore((state) => state.setBottomWalletPay)
  const t = useTranslation()
  return (
    <Panel title={t('daily-claim')} onClose={() => setPage(undefined)}>
      <div className='flex flex-col items-center gap-2'>
        <img
          src='/img/drop-icon.png'
          className='w-24 h-24 object-contain'
          style={{
            imageRendering: 'pixelated',
          }}
        />
        <p className='text-white font-bold text-3xl'>
          {dropsProposals[5].dropsString} {t('drops')}
        </p>
      </div>
      <p className='text-white font-semibold mt-4'>
        {t('you-claimed')} {user?.t_amount}/2
      </p>
      <Button
        text={`${t('claim-for')} ${dropsProposals[5].tonPrice}`}
        styles=''
        icon='/img/ton-icon.svg'
        onClick={() => {
          if (parseInt(user?.t_amount || "0") >= MAX_CLAIM) {
            toastError("Can't claim more today")
            return
          }
          setBottomWalletPay({ proposalId: 5, type: 'TON' })
        }}
      />
      {user?.t_amount === '0' && (
        <Button
          text={`${t('claim')} 2 x ${dropsProposals[5].tonPrice}`}
          styles=''
          icon='/img/ton-icon.svg'
          onClick={() => {
            if (parseInt(user.t_amount) >= MAX_CLAIM) {
              toastError("Can't claim more today")
              return
            }
            setBottomWalletPay({ proposalId: 7, type: 'TON' })
          }}
        />
      )}
    </Panel>
  )
}
