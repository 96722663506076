import { lazy, Suspense, useEffect, useState } from "react";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { useUserStore } from "../../stores/userStore";
import server from "../../logic/server";
import { initializeAudio } from "../../helpers/audio";
import { usePageStore } from "../../stores/pageStore";
import { Toaster } from "react-hot-toast";
import { Common, View } from "../canvas/View";
import { Loader } from "./Loader";
import { DailyClaim } from "./DailyClaim";
import { Airdrop } from "./Airdrop";
import { RestorePanel } from "./RestorePanel";
import { BuyDropsPanel } from "./BuyDropsPanel";
import { Boost } from "./Leaderboard/Boost/Boost";
import { NeedToVerify } from "./Leaderboard/Pool/NeedToVerify";
import { NeedToSubscribe } from "./Leaderboard/Pool/NeedToSubscribe";
import { PoolSettings } from "./Leaderboard/Pool/Settings";
import { InviteFriendsPanel } from "./InviteFriendsPanel";
import { PoolNeedAdmin } from "./Leaderboard/Pool/PoolNeedAdmin";
import { News } from "./News";
import { BottomWallet } from "./BottomWallet";
import { PromotePool } from "./Leaderboard/Pool/PromotePool";
import { BottomRestPay } from "./BottomRestPay";
import { Translation } from "./Translation";
import { StartScreen } from "./StartScreen";
import { GameUI } from "./GameUI";
import { Outlet } from "react-router-dom";
import { TonAuth } from "../Auth/TonAuth";

// const Blocks = dynamic(() => import('@/components/canvas/Blocks').then((mod) => mod.Blocks), { ssr: false })
// const View = dynamic(() => import('@/components/canvas/View').then((mod) => mod.View), {
//   ssr: false,
//   loading: () => <div className='absolute top-0 left-0 flex h-full w-full bg-black'></div>,
// })
// const Common = dynamic(() => import('@/components/canvas/View').then((mod) => mod.Common), { ssr: false })

// preload images
const images = [
  "/img/bolt-icon.png",
  "/img/frens-icon.svg",
  "/img/box-icon.png",
  "/img/drop-icon.png",
  "/img/plus-icon.png",
  "/img/close.svg",
  "/img/restore-lightning.png",
  "/img/unmuted.svg",
  "/img/muted.svg",
  "/img/punk-icon.svg",
  "/img/ton-icon.svg",
  "/img/punkcity.png",
  "/img/ton-connect.png",
  "/img/wallet-icon.svg",
];
images.forEach((src) => {
  if (typeof window === "undefined") return;

  const img = new Image();
  img.src = src;
});

export default function Main() {
  const { user, isFromTelegram, isGameLoaded } = useUserStore();

  useEffect(() => {
    server.init();
    // enable()

    const onCick = () => {
      initializeAudio();
    };

    window.addEventListener("click", onCick);

    return () => {
      window.removeEventListener("click", onCick);
    };
  }, []);

  const [page, setPage] = useState("loader");

  const statePage = usePageStore((state) => state.page);
  const bottomWalletPay = usePageStore((state) => state.bottomWalletPay);

  useEffect(() => {
    if (!isGameLoaded || (isFromTelegram === true && user === undefined)) {
      setPage("loader");
      return;
    }

    if (user === undefined && isFromTelegram === false && page !== "auth") {
      setPage("auth");
      return;
    }

    if (page === "game") return;
    if (user && isGameLoaded) {
      setPage("start");
      getPool().then((poolId) => {
        if (poolId === "0") return;

        // if (poolId) router.push(`/pool/${poolId}`);
      });
      return;
    }
  }, [user, isGameLoaded, isFromTelegram]);

  return (
    <>
      <div className="absolute top-0 left-0 h-full w-full select-none overflow-hidden">
        <Toaster />
        {page === "loader" && <Loader />}
        {<TonAuth page={page} />}
        {statePage === "daily-claim" && <DailyClaim />}
        {statePage === "maindrop" && <Airdrop />}
        {statePage === "restore" && <RestorePanel />}
        {statePage === "buy-drops" && <BuyDropsPanel />}
        {statePage === "boost" && <Boost />}
        {statePage === "pool-need-verify" && <NeedToVerify />}
        {statePage === "pool-need-subscribe" && <NeedToSubscribe />}
        {statePage === "pool-settings" && <PoolSettings />}
        {statePage === "invite-friends" && <InviteFriendsPanel />}
        {statePage === "pool-need-admin" && <PoolNeedAdmin />}
        {statePage === "news" && <News />}
        {bottomWalletPay && <BottomWallet />}
        {statePage === "promote-pool" && <PromotePool />}
        <BottomRestPay />
        <Translation />
        {page === "start" && <StartScreen setPage={setPage} />}
        {page === "game" && <GameUI />}
        <div className="w-full h-full text-center select-none">
          <View className="flex h-full w-full flex-col items-center justify-center select-none">
            <Common user={user} />
          </View>
        </div>
      </div>
      <Outlet/>
    </>
  );
}

async function getPool() {
  if (typeof window === "undefined") return;

  const initData = await (window as any).Telegram?.WebApp?.initDataUnsafe;

  console.log("init", initData);
  const start_param = initData?.start_param;

  console.log("start", start_param);

  if (start_param) {
    // poolId-from-userId
    const poolId = start_param.split("-")[0];
    return poolId;
  }

  return null;
}
