import { User } from "../../stores/userStore"

const devURL = 'http://localhost:2993'
const prodURL = 'https://server.questioncube.xyz'

class Server {
  endpoint = process.env.NODE_ENV === 'development' ? devURL : prodURL

  isDev = process.env.NODE_ENV === 'development'

  token?: string

  lastTrueUserState?: User
  lastTrueEnergyUpdate?: string
  isBannedForLowEnergy?: boolean

  async get(url: string) {
    let newUrl = `${this.endpoint}${url}`

    const params = new URLSearchParams(newUrl)
    params.append('token', this.token || "")
    newUrl = `${newUrl}?${params.toString()}`

    const data = await fetch(newUrl)
      .then((res) => res.json())
      .catch((err) => console.log(err))

    return data
  }

  async post(url: string, data: any) {
    let newUrl = `${this.endpoint}${url}`

    const res = await fetch(newUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, token: this.token }),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err))

    return res
  }
}

export default Server
