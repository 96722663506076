import { create } from 'zustand'

export interface ScrollStatus {
  offset: number
  isLoading: boolean
  maxOffset?: number
}

interface PoolsStore {
  league: number

  pools?: Pool[]
  promotions?: Pool[]

  poolsStatus?: ScrollStatus

  claim?: number
  isClaimLoading?: boolean
  myPool?: SelectedPool
  selectedPool?: SelectedPool
  setPromotions: (promotions: Pool[]) => void
  setPoolsStatus: (status?: ScrollStatus) => void
  setClaim: (claim: number) => void
  setIsClaimLoading: (isLoading: boolean) => void
  setMyPool: (pool?: SelectedPool) => void
  setPools: (pools: Pool[], league: number) => void
  setSelectedPool: (pool?: SelectedPool) => void
  setLeague: (league: number) => void
  //   setPage: (n: string) => void
}

export const usePoolsStore = create<PoolsStore>((set) => ({
  league: 0,
  claim: 0,
  setPromotions: (promotions: Pool[]) => set({ promotions }),
  setPoolsStatus: (status?: ScrollStatus) => set({ poolsStatus: status }),
  setIsClaimLoading: (isLoading: boolean) => set({ isClaimLoading: isLoading }),
  setClaim: (claim: number) => set({ claim }),
  setMyPool: (myPool?: SelectedPool) => set({ myPool }),
  setLeague: (league: number) => set({ league, pools: undefined, poolsStatus: undefined, promotions: undefined }),
  setPools: (pools: Pool[], league: number) => set({ pools, league }),
  setSelectedPool: (selectedPool?: SelectedPool) => set({ selectedPool }),
}))

export interface Pool {
  id: number
  name: string
  invested: number
  hashrate: number
  totalMinedQuestions: number
  ownerId: string
  rank?: number
  costPerUser?: number
  promotionsLeft?: number
}

export interface Miner {
  id: number
  name: string
  minedQuestions: number
  poolInvested: number | null
  rank?: number
  username?: string
  claim?: number
}

export interface Payouts {
  utcTimestamp: string
  mined_questions: number
}

export interface PoolTimestamp {
  id: number
  timestamp: string
  pool_id: number
  hits: string
  mined_questions: number
}

export interface SelectedPool extends Pool {
  joinedAmount: number
  rank: number
  ownerClaim: string
  isPoolLoading: boolean
  users: Miner[]
  usersStatus?: ScrollStatus
  me?: Miner
  isMinersLoading: boolean
  stats?: PoolTimestamp[]
  claim?: number
  username: string
  needSubscribe: boolean
  wasIn?: boolean
}
