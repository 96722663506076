export function Loader() {
  return (
    <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black z-50'>
      <div className='flex flex-col items-center'>
        <h1 className='text-3xl font-bold text-white'>?????????????</h1>
        <h1 className='text-3xl font-bold text-white'>???Loading???</h1>
        <h1 className='text-3xl font-bold text-white'>?????????????</h1>
      </div>
    </div>
  )
}
