import { useEffect, useRef } from 'react'

export const containerStatus = {
  isFromButton: false,
}

export function Container(props: { gradient?: string; children: any; onScroll?: () => void }) {
  const ref = useRef<any>(null)

  return (
    <div
      ref={ref}
      className={`absolute left-0 h-full w-full select-none overflow-y-scroll z-20 flex justify-center bg-gradient-to-b ${props.gradient ? props.gradient : 'from-[#D9BFAB] to-[#AFB5D8]'} disable-scrollbar transition-all duration-100`}
      onScroll={() => {
        if (ref.current.scrollTop >= ref.current.scrollHeight - ref.current.clientHeight - 150) {
          if (props.onScroll) {
            props.onScroll()
          }
        }
      }}
    >
      <div className='flex flex-col items-center max-w-sm p-4 w-full h-full relative'>{props.children}</div>
    </div>
  )
}
