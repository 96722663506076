import { Link, useNavigate } from "react-router-dom";
import { usePoolsStore } from "../../../stores/poolsStore";
import { useUserStore } from "../../../stores/userStore";
import { FindOrCreateInput } from "./FindOrCreateInput";
import { LeaderboardCard } from "./LeaderboardCard";
import { useTranslation } from "i18nano";
import { useEffect, useState } from "react";
import server from "../../../logic/server";
import { getReadableNumber } from "../../../logic/utils/parsing";
import { inputShadow } from "../utils";
import { sTelegramUrl, storageUrl } from "../../../logic/game/music";
import { GradientButton } from "../Button";
import { shareInviteLink } from "../../../logic/utils/telegram";
import { getLeague, getPoolBoost } from "../../../logic/game/pools";
import { getLeagueUser, getUserBoost } from "../../../logic/game/users";

interface Props {
  isUsers?: boolean;
  league: number;
  setLeague: (league: number) => void;
  leagues: any;
  totalLeagues: number;
  getLeaderboard: () => void;
}

export function Leaderboard(props: Props) {
  const { isUsers, league, setLeague, leagues, totalLeagues, getLeaderboard } =
    props;

  const user = useUserStore((state) => state.user);
  const me = useUserStore((state) => state.me);
  const users = useUserStore((state) => state.users);
  const myPool = usePoolsStore((state) => state.myPool);
  const promotions = usePoolsStore((state) => state.promotions);
  const pools = usePoolsStore((state) => state.pools);
  const navigate = useNavigate();
  const t = useTranslation();

  const [totalInvited, setTotalInvited] = useState(0);

  useEffect(() => {
    getLeaderboard();
  }, [league]);

  useEffect(() => {
    server.pools.getClaimed();
    if (isUsers) {
      server.users.getMe();
    }
  }, []);

  useEffect(() => {
    if (!user) return;

    const total =
      parseInt(user.referral_default || "0") +
      parseInt(user.referral_premium || "0") +
      parseInt(user.new_referral_default || "0") +
      parseInt(user.new_referral_premium || "0");

    setTotalInvited(total);
  }, [user]);

  return (
    <>
      <h1
        className="text-xl text-white font-semibold mt-2"
        style={{
          textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
        }}
      >
        {isUsers ? "Solo League" : t("mining-pools")}
      </h1>
      <div className="flex flex-row w-full justify-between items-center mt-4 mb-3">
        <ArrowButton
          direction="left"
          disabled={league === 0}
          onClick={() => {
            setLeague(league - 1);
          }}
        />
        <div className="flex flex-col items-center">
          <div className="w-24 h-24 relative">
            <img
              src={leagues[league].texture}
              alt={leagues[league].texture}
              className="w-full h-full object-contain rounded-xl"
              style={{
                imageRendering: "pixelated",
              }}
            />

            <div
              className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-2xl font-bold shadow-lg"
              style={{
                textShadow: "1px 1px 2px black, 0 0 1em black, 0 0 0.2em black",
              }}
            >
              {[...Array(totalLeagues - leagues[league].id)].map((item) => "?")}
            </div>
          </div>
          <h3 className="text-grey-800 font-semibold text-2xl mt-2">
            {leagues[league].name}
          </h3>
          <p className="text-white font-semibold text-lg">
            {leagues[league].id === 0
              ? `< ${getReadableNumber(leagues[league + 1].fromMined, true)}`
              : `${t("from")} ${getReadableNumber(
                  leagues[league].fromMined,
                  true
                )}+`}
          </p>
        </div>
        <ArrowButton
          direction="right"
          disabled={league === totalLeagues - 1}
          onClick={() => {
            setLeague(league + 1);
          }}
        />
      </div>

      {!isUsers && promotions?.length ? (
        <div className="w-full h-max relative">
          <div
            className="flex flex-col gap-1.5 w-full mb-3 bg-[#4B4D7D1F] px-3 py-2 rounded-3xl"
            style={{
              boxShadow: inputShadow,
              filter: totalInvited >= 3 ? "" : "blur(5px)",
              WebkitFilter: totalInvited >= 3 ? "" : "blur(5px)",
            }}
          >
            <p className="text-white font-semibold text-md">
              {t("join-and-get")}
            </p>
            {promotions.map((promotion) => {
              return (
                <Link to={`/pool/${promotion.id}`} key={promotion.id}>
                  <LeaderboardCard
                    place={0}
                    title={promotion.name}
                    img={`${sTelegramUrl}/channels/${promotion.id}.jpg`}
                    type="cubes"
                    hashrate={promotion.hashrate}
                    value={promotion.totalMinedQuestions}
                    promotionsCost={promotion.costPerUser}
                    promotionsLeft={promotion.promotionsLeft}
                  />
                </Link>
              );
            })}
          </div>
          {totalInvited < 3 ? (
            <div
              className="absolute top-0 left-0 w-full h-full rounded-3xl flex flex-col justify-center items-center text-white font-bold text-lg text-center px-3 gap-3 bg-grey-600 bg-opacity-30"
              style={{
                marginTop: -10,
              }}
            >
              {t("invite-more")}
              <GradientButton
                text={`${t("invite-frens")} (${totalInvited}/3)`}
                onClick={() => shareInviteLink(user!.id, user?.locale || "en")}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {!isUsers &&
        myPool &&
        getLeague(myPool.totalMinedQuestions).id === league && (
          <div className="flex flex-col h-max w-full">
            <Link to={`/pool/${myPool.id}`}>
              <LeaderboardCard
                place={myPool.rank}
                title={myPool.name}
                img={`${sTelegramUrl}/channels/${myPool.id}.jpg`}
                type="cubes"
                value={myPool.totalMinedQuestions}
                boostLvl={getPoolBoost(myPool.invested).id + 1}
                hashrate={myPool.hashrate}
                approved={myPool.ownerId ? true : false}
                highlight={true}
              />
            </Link>

            <SplitLine />
          </div>
        )}

      {isUsers && me && getLeagueUser(me.mined_count).id === league && (
        <div className="flex flex-col h-max w-full">
          <LeaderboardCard
            place={me.rank || 0}
            title={me.name}
            img={`${sTelegramUrl}/users/${me.id}.jpg`}
            type="mined_count"
            value={me.mined_count}
            boostLvl={getUserBoost(me.invested).id + 1}
            highlight={true}
            noFuture={true}
          />

          <SplitLine />
        </div>
      )}
      {!isUsers && <FindOrCreateInput />}

      <LeaderboardListContainer>
        {(!isUsers && pools !== undefined) ||
        (isUsers && users !== undefined) ? (
          <>
            {!isUsers ? (
              <>
                {pools?.map((pool, i) => {
                  return (
                    <Link to={`/pool/${pool.id}`} key={i}>
                      <LeaderboardCard
                        key={i}
                        place={i}
                        title={pool.name}
                        approved={pool.ownerId ? true : false}
                        onClick={() => {
                          navigate(`/pool/${pool.id}`);
                        }}
                        boostLvl={getPoolBoost(pool.invested).id + 1}
                        hashrate={pool.hashrate}
                        img={`${storageUrl}${
                          server.server.isDev ? "/staging" : ""
                        }/telegram/channels/${pool.id}.jpg`}
                        type="cubes"
                        value={pool.totalMinedQuestions}
                      />
                    </Link>
                  );
                })}
                {pools?.length === 0 && (
                  <div className="flex w-full justify-center items-center mt-2 gap-2 font-bold text-[#eee]">
                    No pools in this league!
                  </div>
                )}
              </>
            ) : (
              <>
                {users?.map((user, i) => {
                  return (
                    <LeaderboardCard
                      key={i}
                      place={i}
                      title={user.name || "?"}
                      img={`${sTelegramUrl}/users/${user.id}.jpg`}
                      type="mined_count"
                      noFuture={true}
                      value={user.mined_count}
                      boostLvl={getUserBoost(user.invested).id + 1}
                      // hashrate={user.invested}
                    />
                  );
                })}
                {users?.length === 0 && (
                  <div className="flex w-full justify-center items-center mt-2 gap-2 font-bold text-[#eee]">
                    No miners in this league!
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex text-white w-full justify-center items-center mt-4 gap-2 font-bold">
            <p>Loading</p>
            <span className="loader" />
          </div>
        )}
      </LeaderboardListContainer>
    </>
  );
}

function ArrowButton(props: {
  direction: "left" | "right";
  disabled?: boolean;
  onClick?: () => void;
}) {
  const { direction, disabled, onClick } = props;

  return (
    <button
      className="w-10 h-full flex items-center justify-center"
      onClick={onClick}
      disabled={disabled}
    >
      <img
        src="/img/right-arrow.svg"
        alt="right-arrow"
        className={`w-7 h-7 object-contain ${
          direction === "left" ? "rotate-180" : ""
        }`}
        style={{
          opacity: disabled ? 0.2 : 1,
        }}
      />
    </button>
  );
}

export function LeaderboardListContainer(props: {
  children: React.ReactNode;
  pb?: string;
}) {
  return (
    <div className={`flex w-full h-max flex-col gap-1.5 pb-48`}>
      {props.children}
    </div>
  );
}
export function SplitLine() {
  return (
    <div className="w-full bg-[#9997B9] my-3 shrink-0" style={{ height: 1 }} />
  );
}
