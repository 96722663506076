export const storageUrl = 'https://cubes.fra1.cdn.digitaloceanspaces.com'
export const poolPreviewUrl = `${storageUrl}/img/friends-${2}.png`
export const userPreviewUrl = `${storageUrl}/img/preview.jpg`
export const sTelegramUrl = `${storageUrl}${process.env.NODE_ENV === 'development' ? '/staging' : ''}/telegram`

export const soundUrls: any = {
  // 7
  '7-crash': storageUrl + '/sounds/alien-crash.mp3',
  '7-hit': storageUrl + '/sounds/alien-hit.mp3',

  // 1
  '1-crash': storageUrl + '/sounds/bio-crash.mp3',
  '1-hit': storageUrl + '/sounds/bio-hit.mp3',

  // 4
  '4-crash': storageUrl + '/sounds/cyber-crash.mp3',
  '4-hit': storageUrl + '/sounds/cyber-hit.mp3',

  // 3
  '3-crash': storageUrl + '/sounds/diamond-crash.mp3',
  '3-hit': storageUrl + '/sounds/diamond-hit.mp3',

  // 6
  '6-crash': storageUrl + '/sounds/lava-crash.mp3',
  '6-hit': storageUrl + '/sounds/lava-hit.mp3',

  // 2
  '2-crash': storageUrl + '/sounds/lune-crash.mp3',
  '2-hit': storageUrl + '/sounds/lune-hit.mp3',

  // 8
  '8-crash': storageUrl + '/sounds/mystical-crash.mp3',
  '8-hit': storageUrl + '/sounds/mystical-hit.mp3',

  // 0
  '0-crash': storageUrl + '/sounds/purple-crash.mp3',
  '0-hit': storageUrl + '/sounds/purple-hit.mp3',

  // 5
  '5-crash': storageUrl + '/sounds/steklo-hit.mp3',
  '5-hit': storageUrl + '/sounds/steklo-hit.mp3',
}

export const soundBuffers: any = {}
