import { Address, beginCell, toNano } from '@ton/ton'

export const preparePunkMessage = (props: {
  destinationAddress: string
  ownerAddress: string
  jettonWalletAddress: string
  queryId?: number
  jettonAmount: number
  payload: string
}): {
  address: string
  amount: string
  payload: string
} => {
  const body = beginCell()
    .storeUint(0xf8a7ea5, 32) // jetton transfer op code
    .storeUint(props.queryId ?? 0, 64) // query_id:uint64
    .storeCoins(toNano(props.jettonAmount)) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
    .storeAddress(Address.parse(props.destinationAddress)) // destination:MsgAddress
    .storeAddress(Address.parse(props.ownerAddress)) // response_destination:MsgAddress
    .storeUint(0, 1) // custom_payload:(Maybe Cell ^Cell)
    .storeCoins(toNano('0')) // forward_ton_amount:(VarUInteger 16)
    .storeMaybeRef(beginCell().storeUint(0, 32).storeStringTail(props.payload).endCell())
    .endCell()

  return {
    address: props.jettonWalletAddress,
    amount: toNano('0.07').toString(),
    payload: body.toBoc().toString('base64'),
  }
}

export const prepareTonMessage = (props: {
  destinationAddress: string
  amount: number
  payload: string
}): {
  address: string
  amount: string
  payload: string
} => {
  const body = beginCell()
    .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
    .storeStringTail(props.payload) // write our text comment
    .endCell()
  return {
    address: props.destinationAddress,
    amount: toNano(props.amount).toString(),
    payload: body.toBoc().toString('base64'),
  }
}
