import { IconText } from './PoolInfo'
import { useEffect, useState } from 'react'
import { useTranslation } from 'i18nano'
import { usePoolsStore } from '../../../../stores/poolsStore'
import { useUserStore } from '../../../../stores/userStore'
import { usePageStore } from '../../../../stores/pageStore'
import { boxShadowDefault } from '../../utils'
import { getReadableNumber } from '../../../../logic/utils/parsing'
import { Button, GradientButton } from '../../Button'
import server from '../../../../logic/server'

interface Props {
  joined?: boolean
}

export function PoolOther({ joined }: Props) {
  const selected = usePoolsStore((state) => state.selectedPool)
  const user = useUserStore((state) => state.user)
  const [joining, setJoining] = useState(false)
  const setPage = usePageStore((state) => state.setPage)
  const t = useTranslation()

  return (
    <div
      className='bg-white bg-opacity-30 w-full p-4 rounded-2xl text-lg font-semibold flex flex-col gap-3 mb-4 shadow-lg'
      style={{
        boxShadow: boxShadowDefault,
      }}
    >
      <div className='grid grid-cols-2 gap-2'>
        <PoolCard>
          <img
            src='/img/drop-icon.png'
            alt='group'
            className='w-5 object-contain mt-1'
            style={{ imageRendering: 'pixelated' }}
          />
          <p>{getReadableNumber(selected!.invested || 0)}</p>
        </PoolCard>
        <PoolCard>
          <img
            src='/textures/texture-question.png'
            alt='group'
            className='w-5 h-5 object-contain rounded-sm'
            style={{ imageRendering: 'pixelated', marginTop: 3 }}
          />
          <p>{getReadableNumber(selected!.totalMinedQuestions)}</p>
        </PoolCard>
        <PoolCard>
          <p className='text-purple-text' style={{ marginBottom: -6 }}>
            24h Hashrate
          </p>
          <IconText
            icon='/textures/pickaxe-2.png'
            text={`~${getReadableNumber(selected!.hashrate)}/h`}
            textColor='black'
            textSize='lg'
            pixelated={true}
          />
        </PoolCard>
        <PoolCard>
          <p className='text-purple-text' style={{ marginBottom: -6 }}>
            Pool Fee
          </p>
          <p>2.5%</p>
        </PoolCard>
      </div>
      {(!selected || !user || !joined || !selected!.ownerId || selected.ownerId === user.id + '') &&
        (selected?.promotionsLeft && !joined && !selected?.wasIn ? (
          <GradientButton
            text={`Join and Get ${selected.costPerUser}`}
            icon='/img/drop-icon.png'
            onClick={() => {
              setJoining(true)
              server.pools.joinPool(selected.id).then(() => {
                setJoining(false)
              })
            }}
          />
        ) : (
          <Button
            isLoading={joining}
            text={joined ? `${t('claim-pool-rewards')} ${parseFloat(selected!.ownerClaim).toFixed(3)}` : t('join-pool')}
            textSize='15px'
            icon={joined ? '/textures/texture-question.png' : undefined}
            onClick={() => {
              if (!joined) {
                setJoining(true)
                server.pools.joinPool(selected!.id).then(() => {
                  setJoining(false)
                })
              } else {
                if (!selected!.ownerId) {
                  setPage('pool-need-verify')
                } else if (selected!.ownerId + '' === user!.id + '') {
                  server.pools.claimOwner()
                }
              }
            }}
          />
        ))}
    </div>
  )
}

function PoolCard(props: { children: React.ReactNode }) {
  return (
    <div className='w-full h-full bg-[#4B4D7D1F] bg-opacity-10 rounded-xl flex flex-col items-center justify-center py-1 px-2 gap-0.5 shadow-sm'>
      {props.children}
    </div>
  )
}
