export const TOTAL_LEAGUES = 6
export const POOL_LIMIT = 30
export const USER_LIMIT = 30
export const PROMOTION_MIN_USERS_AMOUNT = 10
export const PROMOTION_MIN_COST_PER_USER = 100

export const LEAGUES: any = {
  0: {
    id: 0,
    name: 'Antimatter',
    texture: '/textures/texture-A.png',
    fromMined: 0,
  },
  1: {
    id: 1,
    name: 'Chlorophyll',
    fromMined: 1e3,
    texture: '/textures/texture-B.png',
  },
  2: {
    id: 2,
    name: 'Cyber Metal',
    fromMined: 1e4,
    texture: '/textures/texture-C.png',
  },
  3: {
    id: 3,
    name: 'Lava',
    fromMined: 1e5,
    texture: '/textures/texture-G.png',
  },
  4: {
    id: 4,
    name: 'Meteorite',
    fromMined: 1e6,
    texture: '/textures/texture-E.png',
  },
  5: {
    id: 5,
    name: 'Moon Stone',
    fromMined: 1e7,
    texture: '/textures/texture-D.png',
  },
}

export const getLeague = (minedQuestions: number) => {
  let league = 0
  Object.keys(LEAGUES).forEach((key) => {
    if (minedQuestions >= LEAGUES[key].fromMined) {
      if (league < parseInt(key)) league = parseInt(key)
    }
  })

  return LEAGUES[league]
}

export const POOL_BOOSTS: any = {
  '-1': {
    id: -1,
    mul: 1.0,
    investments: 0,
  },
  '0': {
    id: 0,
    mul: 1.025,
    investments: 5 * 1e5,
  },
  '1': {
    id: 1,
    mul: 1.05,
    investments: 2.5 * 1e6,
  },
  '2': {
    id: 2,
    mul: 1.15,
    investments: 1 * 1e7,
  },
  '3': {
    id: 3,
    mul: 1.3,
    investments: 5 * 1e7,
  },
  '4': {
    id: 4,
    mul: 1.7,
    investments: 5 * 1e8,
  },
  '5': {
    id: 5,
    mul: 2.0,
    investments: 5 * 1e9,
  },
  '6': {
    id: 6,
    mul: 2.5,
    investments: 5 * 1e10,
  },
  '7': {
    id: 7,
    mul: 3.0,
    investments: 5 * 1e11,
  },
  '8': {
    id: 8,
    mul: 3.5,
    investments: 5 * 1e12,
  },
  '9': {
    id: 9,
    mul: 4.0,
    investments: 5 * 1e14,
  },
}

export function getPoolBoost(invested: number) {
  let boost = -1

  Object.keys(POOL_BOOSTS).forEach((key) => {
    if (invested >= POOL_BOOSTS[key].investments) {
      if (boost < parseInt(key)) boost = parseInt(key)
    }
  })

  return POOL_BOOSTS[boost]
}
