import { poolPreviewUrl, userPreviewUrl } from '../../../logic/game/music'
import { getReadableNumber } from '../../../logic/utils/parsing'
import { IconText } from './Pool/PoolInfo'

interface Props {
  place: number
  title: string
  img: string
  type: 'cubes' | 'mined_count'
  value: number
  boostLvl?: number
  hashrate?: number
  highlight?: boolean
  onClick?: () => void
  noFuture?: boolean
  leagueTexture?: string
  approved?: boolean
  poolInvested?: number | null
  promotionsCost?: number
  promotionsLeft?: number
}

export function LeaderboardCard(props: Props) {
  const {
    place,
    title,
    img,
    type,
    value,
    highlight,
    hashrate,
    boostLvl,
    noFuture,
    leagueTexture,
    approved,
    poolInvested,
    promotionsCost,
    promotionsLeft,
  } = props

  return (
    <div
      className={`relative flex flex-row w-full justify-between items-center bg-opacity-${highlight ? '80' : '30'} rounded-2xl px-0 shrink-0 ${!noFuture ? 'cursor-pointer' : ''}`}
      onClick={props.onClick}
      style={{
        height: 57,
        background: promotionsLeft
          ? 'linear-gradient(360deg, #275DF633 0%, #8B83F733 50%, #CBA5F933 100%), #FFFFFFcc'
          : `rgba(255, 255, 255, ${highlight ? 0.8 : 0.3})`,
      }}
    >
      <div className='flex flex-row items-center gap-2.5 pl-3'>
        {!promotionsLeft && (
          <>
            {leagueTexture ? (
              <img
                src={leagueTexture}
                className='w-5 h-5 object-contain rounded-sm'
                style={{ imageRendering: 'pixelated' }}
              />
            ) : place < 0 ? null : (
              <h3 className='text-grey-800 font-semibold text-2xl min-w-5'>
                {place === 0 && '🥇'}
                {place === 1 && '🥈'}
                {place === 2 && '🥉'}
                <div className='text-lg  text-center'>{place > 2 && place + 1}</div>
              </h3>
            )}
          </>
        )}
        <img
          src={img}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = hashrate !== undefined ? poolPreviewUrl : userPreviewUrl
          }}
          alt='texture-A'
          className='w-8 h-8 object-contain rounded-full'
        />
        <div className='flex flex-col items-left'>
          <div className='flex flex-row w-40 gap-1 items-center'>
            <h3 className='text-grey-800 font-semibold text-lg whitespace-nowrap overflow-hidden overflow-ellipsis'>
              {title}
            </h3>
            {approved && <img src='/img/check_circle.svg' alt='approved' className='w-4 h-4 object-contain' />}
          </div>
          <div className='flex flex-row gap-2 items-center shrink-0'>
            <IconText
              text={`${getReadableNumber(value)} ${type === 'mined_count' ? '' : ''}`}
              textColor='grey-800'
              icon={type === 'mined_count' ? `/textures/mined_count.png` : `/textures/texture-question.png`}
              iconSize={3.5}
              textWeight='semibold'
              textSize='sm'
              pixelated={true}
            />
            {hashrate !== undefined && (
              <>
                <Split />
                <IconText
                  text={`~${getReadableNumber(hashrate)}/h`}
                  textColor='grey-800'
                  icon='/textures/pickaxe-2.png'
                  iconSize={3.5}
                  textWeight='semibold'
                  textSize='sm'
                  pixelated={true}
                />
              </>
            )}
            {poolInvested !== undefined && (
              <>
                <Split />
                <IconText
                  text={`${getReadableNumber(poolInvested!)}`}
                  textColor='grey-800'
                  icon='/img/drop-icon.png'
                  iconSize={3.5}
                  textWeight='semibold'
                  textSize='sm'
                  pixelated={true}
                />
              </>
            )}
            {boostLvl !== undefined && (
              <>
                <Split />
                <p className='text-grey-800 font-semibold text-sm text-center' style={{ marginLeft: -2 }}>
                  ⚡️ {boostLvl}
                </p>
              </>
            )}
          </div>
          {/* <p className='text-grey-800 font-medium text-sm' style={{ marginTop: -3 }}>
          </p> */}
        </div>
      </div>
      {promotionsLeft ? (
        <div className='h-full w-28 bg-black rounded-2xl bg-gradient-to-r from-[#275DF6] via-[#8B83F7] to-[#CBA5F9] flex flex-row justify-between pl-2 items-center'>
          <div className='flex h-full flex-col text-white font-bold text-sm justify-center'>
            <p
              className='text-xs'
              style={{
                marginTop: 0,
              }}
            >
              Get
            </p>
            <div
              className='flex flex-row'
              style={{
                marginTop: -1,
              }}
            >
              <p>{promotionsCost}</p>
              <img src='/img/drop-icon.png' alt='drop-icon' className='w-4 h-4 object-contain ml-1' />
            </div>
            <div
              className='flex flex-row items-center'
              style={{
                marginTop: -1,
              }}
            >
              <img src='/img/group-white.svg' alt='group-icon' className='w-3 h-3 object-contain mr-0.5' />
              <p className='text-xs'>{promotionsLeft} left</p>
            </div>
          </div>
          <img
            src='/img/right-arrow.svg'
            alt='right-arrow'
            className='w-5 h-5 object-contain mr-3'
            style={{
              // invert
              opacity: noFuture ? 0 : 1,
              filter: `invert(0)`,
            }}
          />
        </div>
      ) : (
        <img
          src='/img/right-arrow.svg'
          alt='right-arrow'
          className='w-5 h-5 object-contain mr-3'
          style={{
            // invert
            opacity: noFuture ? 0 : 1,
            filter: `invert(${highlight ? 0.5 : 0})`,
          }}
        />
      )}
    </div>
  )
}

function Split() {
  return <div className='bg-[#9997B9]' style={{ height: 15, width: 1 }} />
}
