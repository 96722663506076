"use client";
import { TopPanel } from "./TopPanel";
import { BottomPanel } from "./BottomPanel";
import { Onboarding } from "./Onboarding/Onboarding";
import { usePageStore } from "../../stores/pageStore";

export function GameUI() {
  const onboarding = usePageStore((state) => state.onboardingPage);

  return !onboarding ? (
    <div>
      <BottomPanel />
      <TopPanel />
    </div>
  ) : (
    <Onboarding />
  );
}
